import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Radio,
  Input,
  message,
  Col,
  Row,
  Spin,
  Checkbox,
  Typography,
  Descriptions,
} from "antd";
import PropTypes, { InferProps } from "prop-types";
import { DocumentNode, useMutation } from "@apollo/client";
import {
  DATE_FORMAT,
  EVALUATION_STATE,
  MINICEX_REJECTION_REASONS,
  MINICEX_REJECTION_REASON_KEYS,
} from "../../../common/constants";
import { LicenseCardList } from "../../physician-license-card-list";
import { GroupBox } from "../../../common/group-box";
import Modal from "antd/lib/modal/Modal";
import {
  PhysicianResponseType,
  PhysicianLicenseRecordType,
  MiniCexAttestationType,
  PhysicianAttestationDesktopAttestDocument,
  PhysicianAttestationDesktopRejectDocument,
} from "../../../generated";
import dayjs from "dayjs";
import { DoubleRightOutlined } from "@ant-design/icons";
const { Text, Link, Paragraph, Title } = Typography;

const { TextArea } = Input;

const ComponentPropTypes = {
  encounterId: PropTypes.string.isRequired,
  dataVersion: PropTypes.number,
  refetchQuery: PropTypes.object.isRequired,
  updateScreenName: PropTypes.func.isRequired,
  isProfileCompleted: PropTypes.bool.isRequired,
};

export interface IProps {
  physician?: PhysicianResponseType;
  attestation?: MiniCexAttestationType;
  encounterId: string;
  dataVersion?: number;
  refetchQuery: DocumentNode;
  updateScreenName: (screenName: string) => void;
  isProfileCompleted: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const AttestationDesktop: FC<ComponentProps> = ({
  physician,
  attestation,
  encounterId,
  dataVersion,
  updateScreenName,
  refetchQuery,
  isProfileCompleted,
}) => {
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const [selectedCertificationRadio, setSelectedCertificationRadio] =
    useState<any>(
      attestation?.attestedAt &&
        attestation?.attestedAt.length > 0 &&
        isProfileCompleted
        ? "CERTIFY"
        : null
    );
  const [rejectReason, setRejectReason] = useState<string>("");
  const [otherTextAreaHidden, setOtherTextAreaHidden] = useState<boolean>(true);
  const [otherText, setOtherText] = useState<string>("");
  const [otherTextRequired, setOtherTextRequired] = useState<boolean>(false);
  const [identityChecked, setIdentityChecked] = useState<boolean>(false);
  const [
    hasApplicantEnrolledInClinicalRotation,
    setHasApplicantEnrolledInClinicalRotation,
  ] = useState<string>("");
  const [attestationErrorTextDisplay, setAttestationErrorTextDisplay] =
    useState<string>("none");
  const [identityCheckboxRequired, setIdentityCheckboxRequired] =
    useState<boolean>(false);

  const [MINICEX_ATTEST, { loading: acceptMiniCEXLoading }] = useMutation(
    PhysicianAttestationDesktopAttestDocument,
    {
      refetchQueries: [
        {
          query: refetchQuery as DocumentNode,
          variables: { encounterId: encounterId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [MINICEX_REJECT] = useMutation(
    PhysicianAttestationDesktopRejectDocument,
    {
      refetchQueries: [
        {
          query: refetchQuery as DocumentNode,
          variables: { encounterId: encounterId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const CERTIFICATION_OPTIONS = {
    CERTIFY: "CERTIFY",
    CANNOT_CERTIFY: "CANNOT_CERTIFY",
  };

  useEffect(() => {
    if (rejectReason === MINICEX_REJECTION_REASON_KEYS.OTHER) {
      setOtherTextAreaHidden(false);
      setOtherTextRequired(true);
    } else {
      setOtherTextAreaHidden(true);
      setOtherTextRequired(false);
      setOtherText("");
      form.resetFields(["otherTextbox"]);
    }
  }, [rejectReason]);

  useEffect(() => {
    setIdentityChecked(false);
    form.setFieldsValue({
      identityCheckbox: false,
    });

    if (selectedCertificationRadio === CERTIFICATION_OPTIONS.CERTIFY) {
      setIdentityCheckboxRequired(true);
      if (attestation?.hasApplicantEnrolledInClinicalRotation !== null) {
        setHasApplicantEnrolledInClinicalRotation(
          attestation?.hasApplicantEnrolledInClinicalRotation as string
        );
        //these fields must be set for the purpose of proper validation
        form.setFieldsValue({
          identityCheckbox: true,
          clinicalRotationRadio:
            attestation?.hasApplicantEnrolledInClinicalRotation,
        });
      }
    } else {
      setIdentityCheckboxRequired(false);
    }
  }, [selectedCertificationRadio]);

  const validateOtherTextbox = (rule: any, value: any) => {
    if (otherTextRequired) {
      var nonWhitespaceText = otherText.replace(/\s/g, "");
      if (nonWhitespaceText.length < 10) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  const getPhysicianName = (lastName: any, restOfName: any) => {
    if (!lastName) {
      return "<No name provided yet>";
    }
    if (!restOfName) {
      return lastName;
    }
    return `${lastName}, ${restOfName}`;
  };

  const PhysicianDetails = () => {
    return (
      <GroupBox title="Physician Evaluator Details:">
        {/* <div style={{ fontSize: "10px", lineHeight: "normal" }}>Name:</div>
        <div>
          {getPhysicianName(
            physician?.profile?.lastName,
            physician?.profile?.restOfName
          )}
        </div> */}

        <Descriptions
          className="ecfmg-small-descriptions"
          size="small"
          layout="vertical"
          column={screens.lg ? 5 : 2}
          style={{
            padding: "10px",
          }}
        >
          <Descriptions.Item label="Name">
            {getPhysicianName(
              physician?.profile?.lastName,
              physician?.profile?.restOfName
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Primary E-mail">
            {physician?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Date of Birth">
            {dayjs(physician?.profile?.dateOfBirth).format(DATE_FORMAT)}
          </Descriptions.Item>
        </Descriptions>
        <div
          style={{
            padding: "10px",
          }}
        >
          <div style={{ fontSize: "10px", lineHeight: "normal" }}>
            Licensure Information:
          </div>
          {physician?.profile?.licenses &&
          physician?.profile?.licenses?.length > 0 ? (
            <LicenseCardList
              licenses={
                physician?.profile?.licenses as PhysicianLicenseRecordType[]
              }
              isEditable={false}
            />
          ) : (
            <div>License not yet supplied</div>
          )}
        </div>
      </GroupBox>
    );
  };

  const AttestationText = () => {
    return (
      <div>
        <br />
        <Title level={5} style={{ fontWeight: 600 }}>
          Mini-CEX Attestation by Physician Evaluator
        </Title>
        <Paragraph>
          I have agreed to observe and evaluate the above-named applicant in a
          clinical encounter and provide this evaluation to the Educational
          Commission for Foreign Medical Graduates (ECFMG), a division of
          Intealth, using ECFMG’s Mini-Clinical Evaluation Exercise (Mini-CEX).
          I certify that:{" "}
        </Paragraph>
        <ul>
          <li>The information I have provided is true and correct.</li>
          <li>
            I currently hold a full, unrestricted license to practice medicine
            without supervision in the jurisdiction where this clinical
            encounter took place.
          </li>
          <li>
            I have held a full, unrestricted license to practice medicine
            without supervision for a minimum of five {"(5)"} years.
          </li>
          <li>
            I have reviewed the{" "}
            <Link
              target="_blank"
              href="https://www.intealth.org/privacy/"
              rel="noreferrer"
            >
              Intealth Privacy Notice.
            </Link>{" "}
            I accept the terms of the Intealth Privacy Notice and consent to the
            collection and processing of my personal information to assist ECFMG
            in making determinations of applicant eligibility for ECFMG
            Certification.
          </li>
          <li>
            I consent to participate in this process and for ECFMG to use my
            contact information for the purpose of contacting me about the
            Mini-CEX and this evaluation.
          </li>
          <li>
            I have not received and will not receive compensation to complete
            this evaluation from the above-named applicant.
          </li>
          <li>
            I have not received and will not receive compensation to complete
            this evaluation from any third party other than what is provided as
            my salary or wage from the institution where I am employed and where
            the clinical encounter took place.
          </li>
          <li>
            This applicant is not my relative. For purposes of this evaluation,
            “relative” is defined as a spouse, child, grandchild, parent,
            grandparent, sibling, uncle, aunt, nephew, niece, and/or cousin.
          </li>
          <li>
            I have directly observed this individual in a clinical encounter and
            am providing an accurate assessment of this individual’s clinical
            skills to ECFMG, as evaluated using ECFMG’s Mini-CEX for Pathway 6.
          </li>
          <li>
            The encounter was a real, in-person clinical encounter. I understand
            that telemedicine, virtual, and standardized patient encounters are
            not acceptable.
          </li>
          <li>
            The encounter took place in a formal clinical setting, such as an
            outpatient office, an urgent care facility, an emergency room, or a
            hospital.
          </li>
          <li>
            The encounter was primary care/general practice in nature and not
            subspecialized.
          </li>
          <li>
            The encounter focused on the diagnosis and treatment of acute and/or
            chronic illness(es) in which each of the four components of the
            Mini-CEX (Medical Interviewing Skills, Physical Examination Skills,
            Professionalism/Communication Skills, Clinical Reasoning &amp;
            Judgment) were assessed.
          </li>
          <li>
            I understand that the responses I provide via the Mini-CEX will be
            used to evaluate this individual’s application to meet the clinical
            and communication skills requirements for ECFMG Certification for
            the purpose of entering graduate medical education (GME) in the
            United States.
          </li>
          <li>
            ECFMG may contact me to confirm or clarify any information provided
            through this Mini-CEX evaluation.
          </li>
          <li>
            I have taken any and all steps necessary to ensure that the clinical
            encounter was conducted in compliance with all applicable licensure,
            regulatory, and/or legal requirements.
          </li>
        </ul>
      </div>
    );
  };

  const onCertificationRadioChange = (e: any) => {
    setAttestationErrorTextDisplay("none");
    setSelectedCertificationRadio(e);
  };

  const onIsEnrolledInClinicalsChange = (e: any) => {
    form.setFieldsValue({ clinicalRotationRadio: e });
    setHasApplicantEnrolledInClinicalRotation(e);
  };

  const RenderRadioOptions = () => {
    return (
      <>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            {isProfileCompleted && (
              <Radio
                value={CERTIFICATION_OPTIONS.CERTIFY}
                checked={
                  selectedCertificationRadio === CERTIFICATION_OPTIONS.CERTIFY
                }
                style={{
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  marginBottom: "-20px",
                }}
                onChange={(e: any) => {
                  setRejectReason("");
                  setOtherText("");
                  form.resetFields(["otherTextbox"]);
                  onCertificationRadioChange(e.target.value);
                }}
              >
                <Paragraph
                  style={{
                    whiteSpace: "normal",
                    paddingLeft: "25px",
                    marginTop: "-20px",
                  }}
                >
                  I certify that I have read, understand, and agree to the
                  statements in this Mini-CEX Attestation by Physician
                  Evaluator.
                </Paragraph>
              </Radio>
            )}
            {selectedCertificationRadio === CERTIFICATION_OPTIONS.CERTIFY && (
              <div
                style={{
                  paddingLeft: "25px",
                  paddingTop: screens.xs || screens.sm ? "20px" : "0px",
                }}
              >
                <Form.Item
                  name={"identityCheckbox"}
                  style={{ margin: "0 0 0 0" }}
                  rules={[
                    {
                      required: identityCheckboxRequired,
                      transform: (value) => value || undefined,
                      type: "boolean",
                      message: "Please attest applicant identity",
                    },
                  ]}
                  valuePropName={"checked"}
                >
                  <Checkbox
                    checked={identityChecked}
                    onChange={(e) => setIdentityChecked(e.target.checked)}
                  >
                    I attest that I am completing an evaluation of a clinical
                    encounter for the applicant listed above. This applicant is
                    either previously known to me or I have confirmed this
                    applicant’s identity by reviewing his/her valid photo
                    identification prior to the encounter.
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name={"clinicalRotationRadio"}
                  style={{ margin: "0px 0px 0px 23px" }}
                  rules={[
                    {
                      required: identityCheckboxRequired,
                      message:
                        "Please indicate clinical rotation status for applicant",
                    },
                  ]}
                >
                  Has the applicant enrolled in a clinical rotation with you or
                  your group now or in the past?{" "}
                  <Radio.Group
                    value={hasApplicantEnrolledInClinicalRotation}
                    onChange={(e: any) => {
                      onIsEnrolledInClinicalsChange(e.target.value);
                    }}
                  >
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Radio
              name="rejectRadio"
              value={CERTIFICATION_OPTIONS.CANNOT_CERTIFY}
              checked={
                selectedCertificationRadio ===
                CERTIFICATION_OPTIONS.CANNOT_CERTIFY
              }
              style={{ wordBreak: "break-word", whiteSpace: "normal" }}
              onChange={(e: any) => {
                onCertificationRadioChange(e.target.value);
              }}
            >
              <Paragraph
                style={{
                  whiteSpace: "normal",
                  paddingLeft: "25px",
                  marginTop: "-20px",
                }}
              >
                I am unable to certify the statements in this Mini-CEX
                Attestation by Physician Evaluator.
              </Paragraph>
            </Radio>
          </Col>
        </Row>
        <Text style={{ display: attestationErrorTextDisplay }} type="danger">
          Please select an option
        </Text>
        {selectedCertificationRadio ===
          CERTIFICATION_OPTIONS.CANNOT_CERTIFY && (
          <div style={{ paddingLeft: "25px" }}>
            <Paragraph style={{ color: "#333333" }} strong>
              Reason for Rejection:
            </Paragraph>
            <Radio.Group
              onChange={(e) => {
                form.resetFields(["otherTextbox"]);
                setOtherText("");
                setRejectReason(e.target.value);
              }}
              value={rejectReason}
            >
              {MINICEX_REJECTION_REASONS.map((reason) => (
                <Row key={reason.key}>
                  <Col span={24}>
                    <Radio value={reason.key}>
                      <div
                        style={{
                          whiteSpace: "normal",
                          paddingLeft: "25px",
                          marginTop: "-20px",
                        }}
                      >
                        {reason.value}
                      </div>
                    </Radio>
                  </Col>
                </Row>
              ))}
            </Radio.Group>
          </div>
        )}
      </>
    );
  };

  const formSubmission = async (formValues: any) => {
    console.log(formValues);
    if (selectedCertificationRadio === CERTIFICATION_OPTIONS.CERTIFY) {
      if (formValues.clinicalRotationRadio !== "") {
        await MINICEX_ATTEST({
          variables: {
            encounterId: encounterId,
            dataVersion: dataVersion!,
            input: {
              hasApplicantEnrolledInClinicalRotation:
                formValues.clinicalRotationRadio,
            },
          },
        })
          .then((data: any) => {
            //push to next component
            updateScreenName(EVALUATION_STATE.ENCOUNTER);
          })
          .catch((error: any) => {
            //only grab the first error
            const graphQLErrorReference =
              error?.graphQLErrors[0]?.extensions?.referenceId || "";
            message.error(
              `${error.toString()} - Reference ID : ${graphQLErrorReference}`
            );
          });
      }
    } else if (
      selectedCertificationRadio === CERTIFICATION_OPTIONS.CANNOT_CERTIFY
    ) {
      if (rejectReason !== "") {
        await MINICEX_REJECT({
          variables: {
            encounterId: encounterId,
            dataVersion: dataVersion!,
            rejectionReason: `_${rejectReason}`, // add "_" to differentiate btw reject from attestation and Review Request modal
            rejectionReasonComments: otherText,
          },
        })
          .then((data: any) => {
            navigate("/physician/dashboard");
          })
          .catch((error: any) => {
            const graphQLErrorReference =
              error?.graphQLErrors[0]?.extensions?.referenceId || "";
            message.error(
              `${error.toString()} - Reference ID : ${graphQLErrorReference}`
            );
          });
      } else {
        message.error("Please select a reason");
      }
    } else {
      setAttestationErrorTextDisplay("block");
    }
  };

  return (
    <>
      <Form form={form} onFinish={formSubmission}>
        <PhysicianDetails></PhysicianDetails>
        <AttestationText></AttestationText>
        <RenderRadioOptions></RenderRadioOptions>
        {rejectReason === "OTHER" ? <></> : null}
        <Form.Item
          hidden={otherTextAreaHidden}
          name="otherTextbox"
          label="Explanation:"
          initialValue={otherText}
          rules={[
            {
              validator: validateOtherTextbox,
              message:
                "Please enter a minimum of 10 characters in the Explanation field to proceed.",
            },
          ]}
        >
          <TextArea
            style={{ marginBottom: "3px" }}
            onChange={(e) => setOtherText(e.target.value)}
            value={otherText}
          ></TextArea>
        </Form.Item>
        <br />
        <br />
        <Button
          size={screens.lg ? "large" : "middle"}
          style={{ float: "left" }}
          onClick={() => {
            navigate("/physician/dashboard");
          }}
        >
          Return to Dashboard
        </Button>
        <Button
          size={screens.lg ? "large" : "middle"}
          style={{ float: "right" }}
          type="primary"
          htmlType="submit"
        >
          Continue <DoubleRightOutlined />
        </Button>
        <Modal
          footer={null}
          visible={acceptMiniCEXLoading}
          closable={false}
          style={{ textAlign: "center" }}
          width="200px"
          destroyOnClose
        >
          <Spin /> Processing...
        </Modal>
      </Form>
    </>
  );
};

const exportComponent = {
  Component: AttestationDesktop,
};

export default exportComponent;
