import { FC } from "react";
import { Helmet } from "react-helmet";
import { Typography } from "antd";
const { Title, Paragraph, Link, Text } = Typography;

const PhysicianInstructions: FC<any> = (props) => {
  const instructions2 = (
    <div>
      <Title level={3}>Instructions</Title>

      <div>
        <Paragraph>
          The 2025 Pathways for ECFMG Certification (2025 Pathways) are intended
          for IMGs who need to meet the clinical and communication skills
          requirements for ECFMG Certification. IMGs who are planning to
          participate in the 2025 National Resident Matching Program® (NRMP®)
          Main Residency Match® (The Match®) in March 2025 also need to meet
          these requirements.{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/"
          >
            Detailed information on the Pathways
          </Link>{" "}
          is available on the ECFMG website.
        </Paragraph>

        <Paragraph>
          You have been asked to complete an ECFMG Mini-Clinical Evaluation
          Exercise (Mini-CEX) evaluation as part of an individual’s application
          to Pathway 6. The Mini-CEX is a well-studied workplace-based
          assessment tool used to evaluate an individual’s clinical skills in a
          variety of clinical settings. Using ECFMG’s Mini-CEX, you will help
          assess an applicant’s clinical skills as demonstrated during a real,
          in-person clinical encounter.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          For detailed instructions on your role in Pathway 6, please visit the{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html"
          >
            Information for Physician Evaluators for Pathway 6
          </Link>{" "}
          page on the ECFMG website. Before you begin this process, you must
          confirm that you meet the{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#physician-evals"
          >
            Criteria for Acceptable Physician Evaluators
          </Link>{" "}
          and that you are able to observe applicants in encounters that meet
          the{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#clinical-encounters"
          >
            Criteria for Acceptable Clinical Encounters
          </Link>
          .
        </Paragraph>
      </div>
      <div>
        <Paragraph>
          For a brief overview and sample Mini-CEX form, which includes the
          clinical skills on which the applicant should be evaluated, please see
          the PDF version of the{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/minicex-p6.pdf"
          >
            {" "}
            Mini-CEX for Pathway 6
          </Link>
          . Please note that this sample version is for your reference only; it
          should not be used to complete the evaluation or be submitted to
          ECFMG. All Mini-CEX evaluations must be completed electronically
          through this portal.
        </Paragraph>
      </div>

      <div>
        <div>
          <Paragraph>
            <Text strong>Establish your Portal Profile</Text>. Establishing your
            profile includes confirming your name as it appears on your license
            to practice medicine; providing additional biographic information,
            including your date of birth; providing your USMLE/ECFMG ID if you
            have one; providing the name(s) of the authority(ies) with which you
            are currently licensed; and providing your current medical
            license/registration number(s).
          </Paragraph>
        </div>
        <div>
          <Paragraph>
            To serve as a physician evaluator for Pathway 6, you must, among
            other criteria:
          </Paragraph>
        </div>
        <div>
          <ul>
            <li>
              {" "}
              Currently hold a full, unrestricted license to practice medicine
              without supervision in the jurisdiction where the encounter will
              take place.
            </li>
            <li>
              {" "}
              Have held a full, unrestricted license to practice medicine
              without supervision for a minimum of five years.{" "}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <Paragraph>
          If you do not meet these criteria or if you do not wish to participate
          in the Mini-CEX evaluation process at this time, you may opt out of
          the Mini-CEX evaluation process from the Profile page. If you do meet
          these criteria, you will need to provide your current
          license/registration information to complete your profile. If your
          profile is incomplete, you will be unable to accept Mini-CEX
          evaluation requests from applicants and complete their evaluations.
        </Paragraph>
        <Paragraph>
          Click on the Profile tab to access the Profile page and establish your
          portal profile.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          <Text strong>Accept or reject Mini-CEX requests.</Text> Once you have
          established your profile, you will be able to accept or reject
          applicants’ Mini-CEX evaluation requests through the Requests
          Dashboard. You may serve as a physician evaluator for up to 10
          applicants for the 2025 Pathways season.
        </Paragraph>
      </div>

      <div>
        <div>
          <Paragraph>
            {" "}
            In addition to the criteria listed above, to serve as a physician
            evaluator for an applicant, you must:
          </Paragraph>
        </div>
        <div>
          <ul>
            <li>
              {" "}
              Have agreed to observe and evaluate the applicant during one or
              two distinct in-person clinical encounters and provide an accurate
              assessment of the applicant’s clinical skills as demonstrated
              during the encounter(s) to ECFMG through this portal.{" "}
            </li>
            <li>
              {" "}
              Have given the applicant approval to share your name and e-mail
              address with ECFMG.{" "}
            </li>
            <li>
              Not be a relative of the applicant. For purposes of this
              evaluation, “relative” is defined as a spouse, child, grandchild,
              parent, grandparent, sibling, uncle, aunt, nephew, niece, and/or
              cousin.{" "}
            </li>
            <li>
              Not be compensated by the applicant to perform the evaluation.
            </li>
            <li>
              Not be compensated by any third party to perform the evaluation
              other than what is provided as your salary or wage from the
              institution where you are employed and where the clinical
              encounter will take place.{" "}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <Paragraph>
          You will be required to attest that you meet these criteria.
        </Paragraph>

        <Paragraph>
          If you meet these criteria, you may accept the applicant’s request.{" "}
          <Text strong>
            Acceptance of the applicant’s request must be completed in this
            portal before the clinical encounter takes place.
          </Text>{" "}
          If the applicant has identified you to observe two encounters, each
          request must be accepted or rejected individually.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          If you do not meet these criteria, or if you do not wish to complete a
          Mini-CEX for the applicant, you may reject the applicant’s request.
          The applicant will be notified via e-mail that you have rejected the
          request. <b>Please note:</b> The applicant is able to identify only
          one evaluator for each clinical encounter; therefore, if you are
          unable to observe and evaluate the encounter, we kindly request that
          you reject the request as soon as possible to allow the applicant as
          much time as possible to identify another evaluator.
        </Paragraph>
      </div>

      <div>
        <ul>
          <li>
            <Paragraph>
              <Text strong>Expiration of requests:</Text> If you do not accept
              or reject an applicant’s request within 15 days of when the
              request was submitted, the request will be automatically rejected.
              If you accept an applicant’s request but do not complete the
              Mini-CEX within 15 days, the request will be automatically
              rejected. If an applicant’s request is automatically rejected, the
              applicant will be notified by e-mail that the request has been
              rejected.
            </Paragraph>
          </li>

          <li>
            <Paragraph>
              <Text strong>Withdrawal of requests:</Text> The applicant may
              withdraw the request before you accept or reject it. If the
              applicant withdraws the request, you will be notified by e-mail.
              Once you accept a request, the applicant may no longer withdraw
              the request, but you will still be able to reject it if you choose
              to do so.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <Text strong>Applicant limits:</Text> You may serve as a physician
              evaluator for up to 10 applicants for the 2025 Pathways season.
              Once you have received invitations from 10 applicants, additional
              applicants will not be able to select you as an evaluator. If the
              request(s) for an applicant are subsequently rejected, expired, or
              withdrawn, a new applicant will be able to send you requests, but
              you may have no more than 10 applicants with open or completed
              requests at one time.
            </Paragraph>
          </li>
        </ul>
      </div>

      <div>
        <Paragraph>
          Click on the Dashboard tab to access the Requests Dashboard and review
          applicant requests for evaluation.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          <Text strong>
            Identify an appropriate in-person clinical patient encounter in
            which to observe and evaluate the applicant’s clinical skills
          </Text>
          . See{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#clinical-encounters"
          >
            Criteria for Acceptable Clinical Encounters
          </Link>{" "}
          for Pathway 6 for complete information. You will be required to attest
          that the encounter you observed met these requirements when you
          complete the Mini-CEX.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          <Text strong>
            Observe the applicant during the clinical encounter
          </Text>
          . The applicant must perform a medical interview and an appropriate
          physical examination during the encounter. At the completion of the
          encounter, the applicant should summarize the patient's clinical
          findings in a brief (less than five minutes) presentation that
          includes an appropriate differential diagnosis and a plan for the next
          steps for diagnosis and treatment.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          <Text strong>
            Evaluate the applicant’s clinical skills as demonstrated during the
            encounter and complete a Mini-CEX evaluation for the applicant
            through this portal.
          </Text>{" "}
          After observing the clinical encounter and the applicant’s
          presentation, complete the Mini-CEX evaluation based on the
          interaction you observed.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          Before submitting your Mini-CEX evaluation of the applicant, you will
          be required to certify a number of statements, including specific
          statements indicating that you meet the{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#physician-evals"
          >
            {" "}
            Criteria for Acceptable Physician Evaluators
          </Link>{" "}
          and that the encounter met the{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#clinical-encounters"
          >
            Criteria for Acceptable Clinical Encounters
          </Link>
          . You will also be required to provide information about the
          encounter, including the date on which the encounter took place, the
          health care facility or hospital at which the encounter took place,
          the setting of the encounter, the patient’s age, and the patient’s
          gender.
        </Paragraph>
      </div>

      <div>
        <div>
          <Paragraph>
            {" "}
            The ECFMG Mini-CEX for Pathway 6 requires scoring of the applicant’s
            performance during the clinical encounter on each of four
            components:
          </Paragraph>
        </div>
        <div>
          <ul>
            <li>Medical Interviewing Skills </li>
            <li>Physical Examination Skills </li>
            <li>Professionalism/Communication Skills</li>
            <li>Clinical Reasoning &amp; Judgment</li>
          </ul>
        </div>
      </div>

      <div>
        <div>
          <Paragraph>
            For each component, you will be provided with descriptions of the
            clinical skills expected of an individual who has graduated from
            medical school. You will score the applicant on a scale of 1 to 9
            for each component, with 1 being the lowest score and 9 being the
            highest score. Select the appropriate score for the applicant{" "}
            <Text strong>
              based on the applicant's skill level for each component as
              observed by you during the encounter, not based on the applicant’s
              actual current training or experience level.
            </Text>{" "}
            Your score should reflect the applicant’s performance using the
            following anchors. Please award:{" "}
          </Paragraph>
        </div>
        <div>
          <ul>
            <li>
              A score of 1, 2, or 3 to an applicant who performs like a medical
              student who is just beginning clinical experiences
            </li>
            <li>
              A score of 4, 5, or 6 to an applicant who performs like an
              individual who has graduated from medical school
            </li>
            <li>
              A score of 7, 8, or 9 to an applicant who performs like an
              individual who is or has been in the practice of medicine
            </li>
          </ul>
        </div>
      </div>

      <div>
        <Paragraph>
          For each component, you also will be required to provide information
          about what you observed during the encounter that led to the score.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          Once you submit the Mini-CEX evaluation to ECFMG, the status of the
          request will appear as complete to the applicant in his/her
          application; however, the applicant will not have access to the
          evaluation. Please do not share your evaluation with the applicant.
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          Please note that it is the responsibility of applicants to ensure that
          their physician evaluators complete the Mini-CEX evaluations in
          support of the applications. An application cannot be approved until
          all completed Mini-CEX evaluations are received and accepted by ECFMG.{" "}
          <Text strong>
            For applicants who plan on participating in the 2025 National
            Resident Matching Program® (NRMP®) Main Residency Match® (The
            Match®), all Mini-CEX evaluations must be received by ECFMG no later
            than February 15, 2025. Any applicant for whom ECFMG does not
            receive all required Mini-CEX evaluations by this deadline may not
            meet the clinical skills requirement for ECFMG Certification in time
            to participate in the 2025 Match.
          </Text>
        </Paragraph>
      </div>

      <div>
        <Paragraph>
          Click on the Dashboard tab to access the Requests Dashboard and view
          your list of Mini-CEX requests.{" "}
        </Paragraph>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Physician Instructions</title>
      </Helmet>
      <div className="site-layout-content">{instructions2}</div>
    </>
  );
};

export default PhysicianInstructions;
