import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";
const { Title, Paragraph, Link, Text } = Typography;

const SchoolInstructions: FC<any> = (props) => {
  const navigate = useNavigate();
  const instructions = (
    <div>
      <Title level={3}>Overview</Title>
      <Paragraph>
        The 2025 Pathways for ECFMG Certification are intended for IMGs who are
        currently seeking to meet the clinical and communication skills
        requirements for ECFMG Certification. IMGs who are planning to
        participate in the 2025 National Resident Matching Program® (NRMP®) Main
        Residency Match® (The Match®) in March 2025 also need to meet these
        requirements. {" "}
        <Link
          href="https://www.ecfmg.org/certification-pathways/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Detailed information on the Pathways
        </Link>{" "}
        is available on the ECFMG website.
      </Paragraph>
      <p>
        {" "}
        <Link
          href="https://www.ecfmg.org/certification-pathways/pathways-3-5.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pathways 3, 4, and 5
        </Link>{" "}
        are for applicants who are students or recent graduates (graduation date
        must be on or after January 1, 2023) of a medical school that meets
        eligibility requirements established by ECFMG for Pathway 3, 4, or 5. To
        meet the requirements for Pathway 3, 4, or 5:
        <br />
        <ol>
          <li>
            The applicant’s clinical skills must first be evaluated by
            appropriate medical school faculty, and those evaluations must be
            reviewed by the medical school’s dean or a designee of the dean.
          </li>
          <li>
            An authorized official from the medical school must then attest to
            the applicant’s clinical skills. The attestation is completed
            through this portal.{" "}
          </li>
        </ol>
      </p>
      <Paragraph>
        Detailed instructions for completing these two steps are provided below.
      </Paragraph>

      <Title level={3}>Instructions</Title>
      <Title level={4}>
        1. Assessment of Three Clinical Patient Encounters by Faculty
      </Title>
      <Paragraph>
        The applicant’s clinical skills must be assessed by appropriate medical
        school faculty, and those evaluations must be reviewed by the medical
        school’s dean or a designee of the dean.
      </Paragraph>
      <Paragraph>
        For students who have not yet completed their clinical rotations, this
        should include three clinical patient encounters lasting 10 to 20
        minutes each. At least one of the encounters must be on the general
        medical hospital service. Each patient should be new to the applicant,
        and each encounter must be observed directly by a different faculty
        member who routinely evaluates medical students. The observed encounters
        must take place at the applicant’s medical school or affiliated clinical
        rotation site.
      </Paragraph>
      <Paragraph>
        The{" "}
        <Link
          href="https://www.ecfmg.org/certification-pathways/minicex.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mini-Clinical Evaluation Exercise (Mini-CEX)
        </Link>{" "}
        form for Pathways 3, 4, and 5 outlines the skills on which the applicant
        should be evaluated. To evaluate each encounter, the observing faculty
        member may use the Mini-CEX form or the institution’s own form, if that
        form uses the same clinical skills as those outlined on the Mini-CEX
        form. Each completed Mini-CEX form should be signed by both the
        applicant and the observing faculty member.
      </Paragraph>
      <Paragraph>
        For graduates and for students whose clinical rotations are complete,
        patient encounters that were evaluated previously are acceptable,
        provided they were assessed using the same clinical skills as those
        outlined on the Mini-CEX form.
      </Paragraph>
      <Paragraph>
        The medical school’s dean, or the dean’s designee, must review the
        completed Mini-CEX forms, or equivalent forms, and confirm that they
        indicate a satisfactory level of clinical skills. The Mini-CEX forms
        should not be submitted to ECFMG.
      </Paragraph>

      <Title level={4}>2. Attestation of Clinical Skills</Title>
      <Paragraph>
        Attestation of the applicant’s clinical skills must be completed only by
        an authorized medical school official following confirmation by the dean
        or the dean’s designee that the applicant’s completed Mini-CEX forms
        indicate a satisfactory level of the applicant’s clinical skills.
      </Paragraph>
      <p>
        To complete the Clinical Skills Attestation:
        <ol>
          <li>
            Select the applicant for whom you want to complete the Clinical
            Skills Attestation on the Attestation Dashboard screen, and select
            Review. Note that only the names of applicants who have submitted a
            Pathways application for which attestation by your medical school is
            required will appear on this screen.{" "}
          </li>
          <li>
            Review all the applicant’s information, including name, date of
            birth, and USMLE/ECFMG Identification Number to confirm it matches
            your medical school records.
          </li>
          <li>
            Review the Clinical Skills Attestation statements that you must
            certify.
          </li>
          <li>If you can certify all the statements, select Certify.</li>
          <li>
            If you cannot certify all the statements, select Cannot Certify. You
            will then be requested to provide ECFMG with the reason you are
            unable to certify the applicant’s Clinical Skills Attestation and
            submit your response to ECFMG.
          </li>
        </ol>
      </p>
      <Paragraph>
        <Text strong>
          For applicants who plan on participating in the 2025 National Resident
          Matching Program® (NRMP®) Main Residency Match® (The Match®), ECFMG
          must receive all Clinical Skills Attestations submitted through this
          portal by February 15, 2025. Any applicant for whom ECFMG does not
          receive an acceptable Clinical Skills Attestation by this deadline may
          not meet the clinical skills requirement for ECFMG Certification in
          time to participate in the 2025 Match.
        </Text>
      </Paragraph>
    </div>
  );

  const onContinueClick = () => {
    navigate(
      "/attestation/dashboard",
      { state: { menuItemSelected: "3" } } //Attestation Dashboard page is menu item #3
    );
  };

  return (
    <>
      <div className="site-layout-content">
        {instructions}

        <div style={{ textAlign: "right" }}>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              onContinueClick();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default SchoolInstructions;
