import { FC } from "react";
import { Form, Row, Col, Input, Button, Typography } from "antd";
import PropTypes, { InferProps } from "prop-types";
const { Text } = Typography;

const ComponentPropTypes = {
  onAddEmail: PropTypes.func,
  disableAddEmailButton: PropTypes.bool,
};

export interface IProps {
  __typename?: "";
  onAddEmail: (email: string) => void;
  disableAddEmailButton: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const AddEmailForm: FC<ComponentProps> = ({
  onAddEmail,
  disableAddEmailButton,
}) => {
  const [form] = Form.useForm();

  const handleAddEmail = () => {
    form.validateFields(["email"]).then(async (_) => {
      onAddEmail(form.getFieldValue("email"));
      form.resetFields();
    });
  };

  return (
    <Form form={form} layout="horizontal">
      <Row gutter={5} align="middle">
        <Col xs={24} sm={24} md={15} lg={17} xl={18}>
          <Form.Item
            name={"email"}
            label={<Text strong>Additional E-mail Address(es):</Text>}
            required={false}
            style={{ marginRight: "5px" }}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input placeholder="Enter secondary email"></Input>
          </Form.Item>
        </Col>
        <Col>
          <Button
            onClick={handleAddEmail}
            disabled={disableAddEmailButton}
            size={"small"}
            style={{
              marginTop: "5.5px",
              borderRadius: "8px",
            }}
          >
            Add E-mail
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddEmailForm;
