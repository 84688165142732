import { FC, useState, useEffect } from "react";
import { Grid, Tabs, Alert, Typography, Spin } from "antd";
import { useQuery, useMutation } from "@apollo/client/react";
import PhysicianDashboardActiveTable from "./physician-dashboard-active-table";
import PhysicianDashboardActiveCard from "./physician-dashboard-active-card";
import PhysicianClosedEncounterTable from "./physician-dashboard-closed-table";
import PhysicianClosedEncounterCard from "./physician-dashboard-closed-card";
import {
  PROFILE_STATUS,
  ACTIVE_MINICEX_STATUSES,
} from "../../common/constants";
import _ from "lodash";
import { Helmet } from "react-helmet";
import {
  PhysicianDashboardLayoutPhysicianPortalDocument,
  PhysicianDashboardLayoutMiniCexAcceptDocument,
  PhysicianDashboardLayoutMiniCexRejectDocument,
  MiniCexSummaryType,
  PhysicianResponseType,
} from "../../generated";

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const { useBreakpoint } = Grid;

export const ATTESTATION_STATES = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

const TABS = {
  ACTIVE: "Active Requests",
  CLOSED: "Closed Requests",
};

const PhysicianDashboardLayout: FC<any> = () => {
  const [attestationOutput, setAttestationOutput] = useState<string>(
    ATTESTATION_STATES.PENDING
  );
  const [activeTab, setActiveTab] = useState<string>(TABS.ACTIVE);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>("");
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
  const [warningAlertText, setWarningAlertText] = useState<string>("");
  const [showProfileNotCompletedText, setShowProfileNotCompletedText] =
    useState<boolean>(false);
  const {
    loading: getAttestationLoading,
    data: getAttestationDetailsData,
    refetch,
  } = useQuery(PhysicianDashboardLayoutPhysicianPortalDocument, {
    variables: { responseType: attestationOutput },
    fetchPolicy: "cache-and-network",
  });
  const [acceptMiniCEX, { loading: acceptMiniCEXLoading }] = useMutation(
    PhysicianDashboardLayoutMiniCexAcceptDocument
  );
  const [rejectMiniCEX, { loading: rejectMiniCEXLoading }] = useMutation(
    PhysicianDashboardLayoutMiniCexRejectDocument,
    {
      refetchQueries: [
        {
          query: PhysicianDashboardLayoutPhysicianPortalDocument,
          variables: { responseType: attestationOutput },
        },
      ],
    }
  );

  const screens = useBreakpoint();
  const [rows, setRows] = useState<MiniCexSummaryType[]>();

  useEffect(() => {
    getWarningMessage();
    if (getAttestationDetailsData) {
      //Is physician profile completed
      if (
        getAttestationDetailsData.physicianPortal?.physician?.minicex
          ?.profileStatus === PROFILE_STATUS.NOT_COMPLETED
      ) {
        setShowProfileNotCompletedText(true);
      }

      setRows(
        getAttestationDetailsData?.physicianPortal
          ?.minicexList as MiniCexSummaryType[]
      );
    }
  }, [getAttestationDetailsData]);

  const onTabChange = (e: any) => {
    setRows([]);
    setActiveTab(e);
    if (e === TABS.ACTIVE) {
      setAttestationOutput(ATTESTATION_STATES.PENDING);
      refetch({ responseType: ATTESTATION_STATES.PENDING }).then(
        (data: any) => {
          setRows(data?.data?.physicianPortal?.minicexList);
        }
      );
    } else if (e === TABS.CLOSED) {
      setAttestationOutput(ATTESTATION_STATES.COMPLETED);
      refetch({ responseType: ATTESTATION_STATES.COMPLETED }).then(
        (data: any) => {
          setRows(data?.data?.physicianPortal?.minicexList);
        }
      );
    }
  };

  const onReviewResponse = async (record: any, response: any, index: any) => {
    setShowAlert(false); //Reset alert

    if (response.acceptMiniCEX) {
      await acceptMiniCEX({
        variables: {
          encounterId: record.encounterId,
          dataVersion: record.dataVersion,
        },
      })
        .then((result) => {
          updateRowDataByIndex(
            result?.data?.PhysicianPortal_MiniCEX_accept?.summary,
            index
          );
        })
        .catch((err) => {
          const graphQLErrorReference =
            err?.graphQLErrors[0]?.extensions?.referenceId || "";
          setAlertText(
            `${err.toString()} - Reference ID : ${graphQLErrorReference}`
          );
          setShowAlert(true);
        });
    } else {
      await rejectMiniCEX({
        variables: {
          encounterId: record.encounterId,
          dataVersion: record.dataVersion,
          input: {
            rejectionReason: response.rejectionReason,
            rejectionReasonComments: response.rejectionReasonComments,
          },
        },
      })
        .then((result) => {
          updateRowDataByIndex(
            result?.data?.PhysicianPortal_MiniCEX_reject?.summary,
            index
          );
        })
        .catch((err) => {
          const graphQLErrorReference =
            err?.graphQLErrors[0]?.extensions?.referenceId || "";
          setAlertText(
            `${err.toString()} - Reference ID : ${graphQLErrorReference}`
          );
          setShowAlert(true);
        });
    }
  };

  const getActiveArea = () => (
    <>
      {screens.md ? (
        <PhysicianDashboardActiveTable
          minicexList={rows}
          onReviewResponse={(currentReocord: any, response: any, index: any) =>
            onReviewResponse(currentReocord, response, index)
          }
          physician={
            getAttestationDetailsData?.physicianPortal
              ?.physician as PhysicianResponseType
          }
          loading={getAttestationLoading}
          acceptMiniCEXLoading={acceptMiniCEXLoading}
          rejectMiniCEXLoading={rejectMiniCEXLoading}
        />
      ) : (
        <PhysicianDashboardActiveCard
          minicex={rows as MiniCexSummaryType[]}
          onReviewResponse={(currentReocord: any, response: any, index: any) =>
            onReviewResponse(currentReocord, response, index)
          }
          physician={
            getAttestationDetailsData?.physicianPortal
              ?.physician as PhysicianResponseType
          }
          loading={getAttestationLoading}
        />
      )}
    </>
  );

  const getInactiveArea = () => (
    <>
      {screens.md ? (
        <>
          <PhysicianClosedEncounterTable
            minicexList={rows}
            physician={
              getAttestationDetailsData?.physicianPortal
                ?.physician as PhysicianResponseType
            }
            loading={getAttestationLoading}
          />
        </>
      ) : (
        <>
          <PhysicianClosedEncounterCard
            minicexList={rows as MiniCexSummaryType[]}
            physician={
              getAttestationDetailsData?.physicianPortal
                ?.physician as PhysicianResponseType
            }
            loading={getAttestationLoading}
          />
        </>
      )}
    </>
  );

  const updateRowDataByIndex = (rowData: any, index: any) => {
    var testRows = rows?.slice();

    testRows?.splice(index, 1, {
      ...rowData,
    });
    testRows = _.filter(testRows, (row: any) => {
      let included = _.indexOf(ACTIVE_MINICEX_STATUSES, row.state) !== -1;
      return included;
    });

    setRows(testRows);
  };

  const applicantLimit = () => {
    let applicants =
      getAttestationDetailsData?.physicianPortal?.physician
        ?.applicantsEvaluatedByPhysician!;
    let maxApplicants =
      getAttestationDetailsData?.physicianPortal?.physician?.physicianConfig
        ?.config?.minicex?.maxApplicantsAllowedToBeEvaluatedByPhysician!;
    if (applicants?.length >= maxApplicants) {
      return true;
    }
  };

  const physicianBlocked = () => {
    if (
      getAttestationDetailsData?.physicianPortal?.physician?.minicex?.tags?.actionTags?.includes(
        "block"
      )
    ) {
      return true;
    }
  };

  const getWarningMessage = () => {
    if (physicianBlocked()) {
      setShowWarningAlert(true);
      setWarningAlertText(
        "Your account is currently restricted. You may respond to or complete any active requests, but no new requests from applicants will be sent to you. For assistance, please contact ECFMG at casemanager@ecfmg.org using the e-mail you used to log into this account."
      );
    } else if (applicantLimit()) {
      setShowWarningAlert(true);
      setWarningAlertText(
        "The number of applicants a physician evaluator may evaluate is limited to 10. You have received requests from 10 applicants in your account, so you have reached this limit. If you have active requests, you may respond to or complete those requests, but no new requests can be sent to you. If you reject all open requests for an applicant, a new applicant may request you as a physician evaluator."
      );
    } else {
      //reset it
      setShowWarningAlert(false);
      setWarningAlertText("");
    }
  };

  return (
    <>
      <Helmet>
        <title>Physician Dashboard</title>
      </Helmet>
      {/*Anything bigger than lg will also be part of lg*/}

      <div className="site-layout-background ">
        {showAlert && (
          <Alert message={alertText} type="error" closable showIcon></Alert>
        )}

        <Title level={3}>Requests Dashboard</Title>
        {activeTab === TABS.CLOSED ? (
          <Paragraph>
            Below are your closed requests. Closed requests are those that you
            have completed, rejected, or allowed to expire, or that have been
            withdrawn. To view the Mini-CEX evaluation for a request that you
            have completed, click on the View button for that request in the
            Action column.
          </Paragraph>
        ) : (
          <>
            <Paragraph>
              You must accept new requests prior to completing the in-person
              clinical encounters for those requests. The system will not allow
              you to enter an evaluation for an encounter that takes place prior
              to accepting the on-line request. Once you accept a request, you
              must complete the Mini-CEX evaluation within 15 days, or the
              system will automatically reject the request.
            </Paragraph>
            <Paragraph>
              <Text strong>
                You must accept new requests prior to completing the in-person
                clinical encounters for those requests. The system will not
                allow you to enter an evaluation for an encounter that takes
                place prior to accepting the on-line request.
              </Text>{" "}
              Once you accept a request, you must complete the Mini-CEX
              evaluation within {"15"} days or the system will automatically
              reject the request.
            </Paragraph>
            <Paragraph>
              To view closed requests, click on the Closed Requests tab.
            </Paragraph>
          </>
        )}

        {showProfileNotCompletedText && (
          <Paragraph
            style={{ backgroundColor: "blanchedalmond", padding: "10px" }}
          >
            Your profile is not complete. You may only review and reject
            requests until you complete your profile; you may not accept
            requests.{" "}
            <Text strong>
              Click on the Profile tab to access the Profile page and complete
              the necessary information.
            </Text>{" "}
            Once your profile is complete you will be able to accept requests
            and complete Mini-CEX evaluations.
          </Paragraph>
        )}
        {warningAlertText ? (
          <Alert type="warning" showIcon={true} message={warningAlertText} />
        ) : null}
        <Tabs
          activeKey={activeTab}
          onChange={(e: any) => {
            onTabChange(e);
          }}
        >
          <TabPane tab={TABS.ACTIVE} key={TABS.ACTIVE}>
            {getAttestationDetailsData?.physicianPortal?.physician ? (
              getActiveArea()
            ) : (
              <Spin size="small" />
            )}
          </TabPane>
          <TabPane tab={TABS.CLOSED} key={TABS.CLOSED}>
            {!!getAttestationDetailsData?.physicianPortal?.physician ? (
              getInactiveArea()
            ) : (
              <Spin size="small" />
            )}
          </TabPane>
        </Tabs>
        <Paragraph style={{ fontSize: "12px" }}>
          All times are calculated using Coordinated Universal Time (UTC).
        </Paragraph>
      </div>
    </>
  );
};

export default PhysicianDashboardLayout;
