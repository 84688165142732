import { FC, useState, useEffect } from "react";
import { Button, Row, Col, Result, message, Spin, Alert } from "antd";
import { useFeatureFlags } from "../feature-flag-react-lite";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react";
import moment from "moment";
import maintenanceJson from "../utils/maintenanceMessageHelper";
import { useMsal } from "../msal-react-lite";
import { AuthorityMaintenanceServerDateDocument } from "../../generated";

const Maintenance: FC<any> = (props: any) => {
  const [maintenanceMessage, setMaintenanceMessage] = useState<string>("");
  const [
    impendingMaintenanceMessage,
    setImpendingMaintenanceMessage,
  ] = useState<string>("");
  const [
    impendingMessageTimestamp,
    setImpendingMessageTimestamp,
  ] = useState<string>("");
  const [
    maintenanceStartTimestamp,
    setMaintenanceMessageTimestamp,
  ] = useState<string>("");
  const [
    maintenanceEndTimestamp,
    setMaintenanceEndTimestamp,
  ] = useState<string>("");
  const [maintenanceUpcoming, setMaintenanceUpcoming] = useState<string>(
    "false"
  );

  const [serverTime, setServerTime] = useState<any>(undefined);
  const [fetchServerDate, setFetchServerDate] = useState<boolean>(false);

  const [maintenanceCountdown, setMaintenanceCountdown] = useState<
    number | undefined
  >(undefined);

  const [isMaintenanceTimerSet, setIsMaintenanceTimerSet] = useState<boolean>(
    false
  );

  const { GetFeatureFlagByName, FeatureFlagList } = useFeatureFlags();

  var maintenanceTimer: any;

  useEffect(() => {
    getFeatureFlags();
  }, [FeatureFlagList]);

  useEffect(() => {
    if (maintenanceUpcoming === "true") {
      setFetchServerDate(true);
    }
  }, [maintenanceUpcoming]);

  useEffect(() => {
    if (serverTime && typeof maintenanceCountdown === "undefined") {
      if (moment(serverTime).isBefore(maintenanceStartTimestamp)) {
        const maintenanceDiff = moment(maintenanceStartTimestamp).diff(
          serverTime,
          "seconds"
        );
        //used for setinterval/settimeout so it needs to be in 1000s
        setMaintenanceCountdown(maintenanceDiff * 1000);
      }
    }
  }, [serverTime]);

  useEffect(() => {
    if (
      !maintenanceTimer &&
      !isMaintenanceTimerSet &&
      maintenanceCountdown &&
      maintenanceCountdown > 0
    ) {
      maintenanceTimer = setTimeout(maintenanceTimeout, maintenanceCountdown);
      setIsMaintenanceTimerSet(true);
    }
  }, [maintenanceCountdown]);

  const maintenanceTimeout = () => {
    setFetchServerDate(true);

    clearTimeout(maintenanceTimer);
  };

  const getFeatureFlags = () => {
    setMaintenanceMessage(
      GetFeatureFlagByName("MAINTENANCE_MSG_SYSTEM_AUTHORITY")
    );
    setImpendingMaintenanceMessage(
      GetFeatureFlagByName("MAINTENANCE_MSG_IMPENDING_AUTHORITY")
    );
    setImpendingMessageTimestamp(
      GetFeatureFlagByName("MAINTENANCE_MSG_IMPENDING_TIMESTAMP_AUTHORITY")
    );
    setMaintenanceMessageTimestamp(
      GetFeatureFlagByName("MAINTENANCE_MSG_START_TIMESTAMP_AUTHORITY")
    );
    setMaintenanceEndTimestamp(
      GetFeatureFlagByName("MAINTENANCE_MSG_END_TIMESTAMP_AUTHORITY")
    );
    setMaintenanceUpcoming(
      GetFeatureFlagByName("MAINTENANCE_UPCOMING_AUTHORITY")
    );
  };

  const GetServerTime = () => {
    const { loading, error, data } = useQuery(AuthorityMaintenanceServerDateDocument, {
      fetchPolicy: "no-cache",
    });

    if (loading) {
      return <div></div>;
    }
    if (error) {
      message.error(error);
      return <div></div>;
    }

    setFetchServerDate(false);
    setServerTime(moment(data?.serverDate));

    return <></>;
  };

  const MaintenanceMessage = () => {
    if (serverTime) {
      const maintenanceMessageResp: any = maintenanceJson(
        impendingMaintenanceMessage,
        maintenanceMessage,
        impendingMessageTimestamp,
        maintenanceStartTimestamp,
        maintenanceEndTimestamp,
        serverTime,
        maintenanceUpcoming
      );

      if (maintenanceMessageResp?.maintenanceType === "SYSTEM") {

        return (
          <>
            <Row>
              <Col span={24}>
                <Result status="warning" title={""}></Result>
              </Col>
            </Row>
            <div style={{ textAlign: "center" }}>
              <div
                style={{ maxWidth: "400px", display: "inline-block" }}
                dangerouslySetInnerHTML={{
                  __html: maintenanceMessageResp?.maintenanceMsg
                    ? maintenanceMessageResp.maintenanceMsg
                    : "",
                }}
              ></div>
            </div>
          </>
        );
      }
    }
    return <>{props.children}</>;
  };

  return (
    <div>
      {fetchServerDate ? <GetServerTime /> : null}
      {maintenanceUpcoming === "true" ? (
        <>
          <MaintenanceMessage />
        </>
      ) : (
        props.children
      )}
    </div>
  );
};

export default Maintenance;
