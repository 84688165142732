import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Select, Button, Table, Spin, Space, Tooltip, Typography } from "antd";
import { useQuery } from "@apollo/client/react";
import moment from "moment";
import { AuthorityAttestationDashboardGetAttestationRecordsDocument } from "../../generated";
import _ from "lodash";
import { downloadCSV } from "./download-attestation-csv";
import { DownloadOutlined, SearchOutlined, SolutionOutlined } from "@ant-design/icons";
const { Title, Paragraph, Text } = Typography;

const { Option } = Select;

const AttestationDashboardTable: FC<any> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [statusFilter, setStatusFilter] = useState<string>("All");

  const onReviewClick = (record: any) => {
    navigate(
      `${location.pathname}/detail/${record.usmleId}`,
      { state: { menuItemSelected: "" } } //No menu item for this route
    );
  };

  const columns = [
    {
      title: "USMLE/ECFMG ID",
      dataIndex: "usmleId",
      key: "usmleId",
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (text: any, record: any) => (
        <Text>{moment(record.dateOfBirth).format("MM/DD/YYYY")}</Text>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Rest of Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Date Requested",
      dataIndex: "requestTimestamp",
      key: "requestTimestamp",
      render: (text: any, record: any) => (
        <Text>{moment(record.requestTimestamp).format("MM/DD/YYYY")}</Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "attestationOutput",
      key: "attestationOutput",
      render: (text: any, record: any) => (
        <>
          {record.attestationOutput ? (
            <Text>
              {record?.attestationOutput === "Attested"
                ? "Certified"
                : record?.attestationOutput}
            </Text>
          ) : (
            <Text>New</Text>
          )}
        </>
      ),
    },
    {
      title: "Date Completed",
      dataIndex: "completeTimestamp",
      key: "completeTimestamp",
      render: (text: any, record: any) => (
        <>
          {record.completeTimestamp ? (
            <Text>{moment(record.completeTimestamp).format("MM/DD/YYYY")}</Text>
          ) : (
            <Text>N/A</Text>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <Button
          className="ant-btn-left-align"
          type="primary"
          onClick={() => {
            onReviewClick(record);
          }}
          icon={
            record.attestationOutput ? (
              <SearchOutlined />
            ) : (
              <SolutionOutlined />
            )
          }
          style={{width: '100px'}}
        >
          {record.attestationOutput ? "View" : "Review"}
        </Button>
      ),
    },
  ];

  function AuthorityDashboard() {
    const { loading, error, data } = useQuery(
      AuthorityAttestationDashboardGetAttestationRecordsDocument,
      {
        variables: { attestationOutput: statusFilter, usmleId: null },
      }
    );

    if (loading) {
      return (
        <Paragraph style={{ textAlign: "center", margin: "80px 40px" }}>
          <Spin size="large" />
          <Title level={2}>Loading...</Title>
        </Paragraph>
      );
    }
    if (error) {
      return <Paragraph type="danger">{error.message}</Paragraph>;
    }

    return (
      <>
        <Space direction="horizontal" size="large">
          <Paragraph>
            Filter by Status:{" "}
            <Select
              value={statusFilter}
              onChange={(event) => {
                onStatusChange(event);
              }}
              style={{ width: "100px" }}
            >
              <Option key="All" value="All">
                All
              </Option>
              <Option key="New" value="New">
                New
              </Option>
              <Option key="Certified" value="Attested">
                Certified
              </Option>
              <Option key="Rejected" value="Rejected">
                Rejected
              </Option>
            </Select>
          </Paragraph>

          <Paragraph>
            <Tooltip title="Download Result">
              <Button
                onClick={() =>
                  downloadCSV(
                    data?.authorityPortal?.attestationRecords,
                    statusFilter
                  )
                }
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>
          </Paragraph>
        </Space>
        <br />
        <Table
          dataSource={data?.authorityPortal?.attestationRecords as any}
          columns={columns}
        />
      </>
    );
  }

  const onStatusChange = (e: any) => {
    setStatusFilter(e);
  };

  return (
    <>
      <AuthorityDashboard></AuthorityDashboard>
    </>
  );
};

export default AttestationDashboardTable;
