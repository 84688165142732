import { FC, useState, useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";
import { UiPageConfigType, UiPortalConfigType } from "../../generated";
import { CertificationInstructionsV1 } from "./certification-instructions/certification-instructions-v1";
import { CertificationInstructionsV2 } from "./certification-instructions/certification-instructions-v2";
import { CertificationInstructionsV3 } from "./certification-instructions/certification-instructions-v3";

const COMPONENT_NAME = "certification-instructions";
const PAGE_NAME = "emswp-certification-attestation";

export interface CertificationInstructionsProps {
  versionConfig?: UiPortalConfigType;
}

export const CertificationInstructionsFactory: FC<CertificationInstructionsProps> =
  (props) => {
    const certificationInstructions = () => {
      if (props.versionConfig?.config) {
        //find the page that has the component we want
        const page = props?.versionConfig?.config?.pages?.find((page: any) => {
          return page?.pageName === PAGE_NAME;
        });

        if (page) {
          //find the component we want
          const component = page?.components?.find((component: any) => {
            return component?.componentName === COMPONENT_NAME;
          });
          if (component) {
            switch (component.version) {
              case 1:
                return <CertificationInstructionsV1 />;
              case 2:
                return <CertificationInstructionsV2 />;
              case 3:
              default:
                return <CertificationInstructionsV3 />;
            }
          } else {
            return <CertificationInstructionsV1 />;
          }
        } else {
          return <CertificationInstructionsV1 />;
        }
      } else {
        //This means that the certification being loaded up did not have a versionConfig before. Version Config for this component was created April 24, 2023.
        return <CertificationInstructionsV1 />;
      }
    };

    return <>{certificationInstructions()}</>;
  };
