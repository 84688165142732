import { FC, useState, useEffect } from "react";
import { Button, Row, Col, Result, message, Spin, Alert } from "antd";
import { useFeatureFlags } from "../feature-flag-react-lite";
import moment from "moment";
import maintenanceJson from "../utils/maintenanceMessageHelper";
import axios from 'axios';

const SESSION_KICKOUT_TIMER = process.env.REACT_APP_SESSION_KICKOUT ?? "0";

const ImpendingMaintenance: FC<any> = (props: any) => {
  const [maintenanceMessage, setMaintenanceMessage] = useState<string>("");
  const [
    impendingMaintenanceMessage,
    setImpendingMaintenanceMessage,
  ] = useState<string>("");
  const [
    impendingMessageTimestamp,
    setImpendingMessageTimestamp,
  ] = useState<string>("");
  const [
    maintenanceStartTimestamp,
    setMaintenanceMessageTimestamp,
  ] = useState<string>("");
  const [
    maintenanceEndTimestamp,
    setMaintenanceEndTimestamp,
  ] = useState<string>("");
  const [maintenanceUpcoming, setMaintenanceUpcoming] = useState<string>(
    "false"
  );

  const [serverTime, setServerTime] = useState<any>(undefined);

  const [
    isImpendingMaintenanceTimerSet,
    setIsImpendingMaintenanceTimerSet,
  ] = useState<boolean>(false);

  const [
    impendingMaintenanceCountdown,
    setImpendingMaintenanceCountdown,
  ] = useState<number | undefined>(undefined);

  const [showImpendingMessage, setShowImpendingMessage] = useState<boolean>(
    false
  );

  const { GetFeatureFlagByName, FeatureFlagList } = useFeatureFlags();

  var impendingMaintenanceTimer: any;

  useEffect(() => {
    if (typeof props.ShowImpendingMessageImmediately !== "undefined") {
      setShowImpendingMessage(props.ShowImpendingMessageImmediately);
    }
  }, []);

  useEffect(() => {
    getFeatureFlags();
  }, [FeatureFlagList]);

  useEffect(() => {
    if (maintenanceUpcoming === "true") {
      GetServerTime();
    }
  }, [maintenanceUpcoming]);

  useEffect(() => {
    if (serverTime && !showImpendingMessage && !impendingMaintenanceCountdown) {
      if (moment(serverTime).isBefore(maintenanceStartTimestamp)) {
        const impendingMaintenanceDiff =
          moment(maintenanceStartTimestamp).diff(serverTime, "seconds") -
          parseInt(SESSION_KICKOUT_TIMER);
        //used for setinterval/settimeout so it needs to be in 1000s
        setImpendingMaintenanceCountdown(impendingMaintenanceDiff * 1000);
      }
    }
  }, [serverTime]);

  useEffect(() => {
    if (
      !impendingMaintenanceTimer &&
      !isImpendingMaintenanceTimerSet &&
      impendingMaintenanceCountdown
    ) {
      impendingMaintenanceTimer = setTimeout(
        impendingMaintenanceTimeout,
        impendingMaintenanceCountdown
      );
      setIsImpendingMaintenanceTimerSet(true);
    }
  }, [impendingMaintenanceCountdown]);

  const impendingMaintenanceTimeout = () => {
    setShowImpendingMessage(true);
    clearTimeout(impendingMaintenanceTimer);
  };

  const getFeatureFlags = () => {
    setMaintenanceMessage(
      GetFeatureFlagByName("MAINTENANCE_MSG_SYSTEM_AUTHORITY")
    );
    setImpendingMaintenanceMessage(
      GetFeatureFlagByName("MAINTENANCE_MSG_IMPENDING_AUTHORITY")
    );
    setImpendingMessageTimestamp(
      GetFeatureFlagByName("MAINTENANCE_MSG_IMPENDING_TIMESTAMP_AUTHORITY")
    );
    setMaintenanceMessageTimestamp(
      GetFeatureFlagByName("MAINTENANCE_MSG_START_TIMESTAMP_AUTHORITY")
    );
    setMaintenanceEndTimestamp(
      GetFeatureFlagByName("MAINTENANCE_MSG_END_TIMESTAMP_AUTHORITY")
    );
    setMaintenanceUpcoming(
      GetFeatureFlagByName("MAINTENANCE_UPCOMING_AUTHORITY")
    );
  };

  const GetServerTime = () => {

    axios({
      url: process.env.REACT_APP_GRAPH_SERVER,
      method: 'post',
      data: {
        query:`
        query {serverDate}
        `
      }
    }).then((result: any) => {
      console.log("got some data ", result);
      const serverDate = result?.data?.data?.serverDate;
      setServerTime(moment(serverDate));
    })
    .catch((exception: any) => {
      console.log("exception: ", exception)
    });
  };

  const ImpendingMessage = () => {
    if (serverTime) {
      const maintenanceMessageResp: any = maintenanceJson(
        impendingMaintenanceMessage,
        maintenanceMessage,
        impendingMessageTimestamp,
        maintenanceStartTimestamp,
        maintenanceEndTimestamp,
        serverTime,
        maintenanceUpcoming
      );

      if (maintenanceMessageResp?.maintenanceType === "IMPENDING") {
        return (
          <>
            <Alert
              type="warning"
              message={maintenanceMessageResp?.maintenanceMsg}
            />           
          </>
        );
      }
    }
    return <></>;
  };

  return (
    <div>
      {maintenanceUpcoming === "true" && showImpendingMessage ? (
        <>
          <ImpendingMessage />
          {props.children}
        </>
      ) : (
        props.children
      )}
    </div>
  );
};

export default ImpendingMaintenance;
