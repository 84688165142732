import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Spin, Button, Row, Col, Typography, Grid } from 'antd';
import { useQuery } from '@apollo/client/react';
import ApplicantDetailsDesktop from './desktop/applicant-details-desktop';
import AttestationDesktop from './desktop/attestation-desktop';
import EncounterInformation from './desktop/encounter-information';
import MiniCEXEvaluation from './desktop/minicex-evaluation';
import MinicexAdditionalDetails from './desktop/minicex-additional-details';
import {
  EVALUATION_STATE,
  PROFILE_STATUS,
  PROGRESS_STATUS,
} from '../../common/constants';
import MiniCEXReviewWrapper from './desktop/minicex-review-wrapper';
import { MiniCEXSteps } from './mini-cex-steps';
import { Helmet } from 'react-helmet';
import {
  PhysicianEvaluationLayoutPhysicianPortalDocument,
  PhysicianResponseType,
  MiniCexAttestationType,
  MiniCexEncounterType,
  MiniCexEvaluationType,
  PhysicianLicenseRecordType,
  MiniCexSummaryType,
  PhysicianEvaluationLayoutPhysicianPortalQuery,
  PhysicianProfileType,
} from '../../generated';
const { Title, Paragraph, Link, Text } = Typography;

const EvaluationLayout: FC<any> = (props) => {
  const steps = [
    EVALUATION_STATE.ATTESTATION,
    EVALUATION_STATE.ENCOUNTER,
    EVALUATION_STATE.EVALUATION,
    EVALUATION_STATE.REVIEW,
  ];

  const { encounterId } = useParams<string>();

  const [currentScreen, setCurrentScreen] = useState<string>('');
  const [activeEvaluationPanels, setActiveEvaluationPanels] = useState<
    string[]
  >(['MEDICAL_INTERVIEWING']);
  const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>(true);
  const [dataVersion, setDataVersion] = useState<number | undefined>(undefined);
  const [miniCEXVersionNum, setMiniCEXVersionNum] = useState<number>(1);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { loading, error, data } =
    useQuery<PhysicianEvaluationLayoutPhysicianPortalQuery>(
      PhysicianEvaluationLayoutPhysicianPortalDocument,
      {
        variables: { encounterId: encounterId },
        fetchPolicy: 'network-only',
      }
    );

  useEffect(() => {
    var profileCompleted =
      data?.physicianPortal?.physician?.minicex?.profileStatus ===
      PROFILE_STATUS.COMPLETED;

    if (
      data?.physicianPortal?.minicex?.form?.progress &&
      currentScreen === ''
    ) {
      if (profileCompleted) {
        const progress = data.physicianPortal.minicex.form.progress;
        for (const state of progress) {
          if (state?.status === PROGRESS_STATUS.NOT_COMPLETED) {
            if (state.state === EVALUATION_STATE.ATTESTATION) {
              setCurrentScreen(EVALUATION_STATE.ATTESTATION);
              break;
            } else if (state.state === EVALUATION_STATE.ENCOUNTER) {
              setCurrentScreen(EVALUATION_STATE.ENCOUNTER);
              break;
            } else if (state.state === EVALUATION_STATE.EVALUATION) {
              setCurrentScreen(EVALUATION_STATE.EVALUATION);
              break;
            } else if (state.state === EVALUATION_STATE.ADDITIONAL_INFO) {
              setCurrentScreen(EVALUATION_STATE.ADDITIONAL_INFO);
              break;
            }
          } else if (
            (state?.status === PROGRESS_STATUS.COMPLETED &&
              state.state === EVALUATION_STATE.ADDITIONAL_INFO) ||
            (state?.state === EVALUATION_STATE.ADDITIONAL_INFO &&
              state.status === PROGRESS_STATUS.NOT_REQUIRED)
          ) {
            setCurrentScreen(EVALUATION_STATE.REVIEW);
            break;
          }
        }
      } else {
        setIsProfileCompleted(profileCompleted);
        setCurrentScreen(EVALUATION_STATE.ATTESTATION);
      }
    }
    const tempDataVersion = data?.physicianPortal?.minicex?.dataVersion;
    if (
      tempDataVersion !== dataVersion &&
      typeof tempDataVersion !== 'undefined' &&
      tempDataVersion !== null
    ) {
      setDataVersion(tempDataVersion);
    }
  }, [data]);

  if (loading) {
    return (
      <div
        style={{ textAlign: 'center', margin: '80px 40px' }}
        className="site-layout-background "
      >
        <Spin size="large" />
        <Title level={2}>Loading...</Title>
      </div>
    );
  }

  const updateScreenName = (screenName: string) => {
    setCurrentScreen(screenName);
  };

  const getCurrentStep = (screenName: string) => {
    if (
      screenName === EVALUATION_STATE.EVALUATION ||
      screenName === EVALUATION_STATE.ADDITIONAL_INFO
    ) {
      return EVALUATION_STATE.EVALUATION;
    }
    return screenName;
  };
  //Will be used to determine screen to show to user

  const attestationDesktopComponent = () => {
    return !!data?.physicianPortal?.physician ? (
      <AttestationDesktop.Component
        physician={data?.physicianPortal?.physician as PhysicianResponseType}
        attestation={
          data?.physicianPortal?.minicex?.form
            ?.attestation as MiniCexAttestationType
        }
        encounterId={encounterId as string}
        dataVersion={data?.physicianPortal?.minicex?.dataVersion!}
        updateScreenName={(screenName: string) => {
          updateScreenName(screenName);
        }}
        refetchQuery={PhysicianEvaluationLayoutPhysicianPortalDocument}
        isProfileCompleted={isProfileCompleted}
      />
    ) : null;
  };

  const encounterInformationComponent = () => {
    return !!data?.physicianPortal?.minicex?.form?.encounter &&
      !!data?.physicianPortal?.minicex?.dataVersion ? (
      <EncounterInformation.Component
        encounter={
          data.physicianPortal.minicex.form.encounter as MiniCexEncounterType
        }
        encounterId={encounterId as string}
        dataVersion={data.physicianPortal.minicex.dataVersion}
        updateScreenName={(screenName: string) => {
          updateScreenName(screenName);
        }}
        acceptedAt={data?.physicianPortal?.minicex?.summary?.acceptedAt}
        refetchQuery={PhysicianEvaluationLayoutPhysicianPortalDocument}
      ></EncounterInformation.Component>
    ) : null;
  };

  const miniCexEvaluationComponent = () => {
    return !!data?.physicianPortal?.minicex?.dataVersion &&
      !!data?.physicianPortal?.minicex?.form?.evaluation ? (
      <MiniCEXEvaluation.Component
        encounterId={encounterId as string}
        dataVersion={data.physicianPortal.minicex.dataVersion}
        evaluation={
          data.physicianPortal.minicex.form.evaluation as MiniCexEvaluationType
        }
        updateScreenName={(screenName: string) => {
          updateScreenName(screenName);
        }}
        refetchQuery={PhysicianEvaluationLayoutPhysicianPortalDocument}
        activePanels={activeEvaluationPanels}
        setActivePanels={(e: string[]) => setActiveEvaluationPanels(e)}
      />
    ) : null;
  };

  const miniCexAdditionalDetailsComponent = () => {
    return !!data?.physicianPortal?.minicex?.dataVersion &&
      !!data?.physicianPortal?.minicex?.form?.evaluation ? (
      <MinicexAdditionalDetails.Component
        encounterId={encounterId as string}
        dataVersion={data.physicianPortal.minicex.dataVersion}
        evaluation={
          data.physicianPortal.minicex.form?.evaluation as MiniCexEvaluationType
        }
        updateScreenName={(screenName: string) => {
          updateScreenName(screenName);
        }}
        refetchQuery={PhysicianEvaluationLayoutPhysicianPortalDocument}
      />
    ) : null;
  };

  const miniCexReviewComponent = () => {
    return !!data?.physicianPortal?.physician?.profile?.licenses &&
      !!dataVersion ? (
      <MiniCEXReviewWrapper
        key={encounterId}
        encounterId={encounterId as string}
        dataVersion={dataVersion}
        updateScreenName={(screenName: string) => {
          updateScreenName(screenName);
        }}
        refetchQuery={PhysicianEvaluationLayoutPhysicianPortalDocument}
        licenses={
          data.physicianPortal.physician.profile
            .licenses as PhysicianLicenseRecordType[]
        }
        showFaimerPreSurveyQuestions={
          data?.physicianPortal?.physician?.minicex
            ?.faimerSurveyCurrentPathwaySeason === null
        }
        miniCEXVersionNum={miniCEXVersionNum}
        physician={data?.physicianPortal?.physician as PhysicianResponseType}
      />
    ) : null;
  };

  const evaluationScreen = () => {
    return (
      <>
        <MiniCEXSteps
          currentStep={getCurrentStep(currentScreen)}
          steps={steps}
        />
        {currentScreen === EVALUATION_STATE.ATTESTATION ? (
          <>
            <Helmet>
              <title>Physician Attestation</title>
            </Helmet>
            <Paragraph>
              If any of your Physician Evaluator Details below are incorrect,
              please update your profile. Please note that you cannot update
              your name. If your name has changed, please contact ECFMG at{' '}
              <Link href="mailto:casemanager@ecfmg.org">
                casemanager@ecfmg.org
              </Link>
              .{' '}
            </Paragraph>
            {attestationDesktopComponent()}
          </>
        ) : null}

        {currentScreen === EVALUATION_STATE.ENCOUNTER ? (
          <>
            <Helmet>
              <title>Physician Encounter</title>
            </Helmet>
            {encounterInformationComponent()}
          </>
        ) : null}

        {currentScreen === EVALUATION_STATE.EVALUATION ? (
          <>
            <Helmet>
              <title>Physician Evaluation</title>
            </Helmet>
            <Title level={3}>Clinical Evaluation</Title>
            <Paragraph>
              Please evaluate the applicant’s clinical skills as demonstrated
              during the clinical encounter on each of the four skill components
              shown. Please score the applicant using the scale provided, with 1
              being the lowest score and 9 being the highest. Select the
              appropriate score for the applicant{' '}
              <Text strong>
                based on your observation of the applicant’s skills for each
                component, not based on the applicant’s actual current training
                or experience level.
              </Text>{' '}
              For each component, please also provide information about what 
              you observed during the encounter that led to the score. 
              <br />
            </Paragraph>
            <Text>
            Your scoring should reflect the applicant’s performance using the
              following anchors. Please award:
            </Text>
            <ul>
              <li>
                A score of 1, 2, or 3 to an applicant who performs like a
                medical student who is just beginning clinical experiences
              </li>
              <li>
                A score of 4, 5, or 6 to an applicant who performs like an
                individual who has graduated from medical school
              </li>
              <li>
                A score of 7, 8, or 9 to an applicant who performs like an
                individual who is or has been in the practice of medicine
              </li>
            </ul>
            {miniCexEvaluationComponent()}
          </>
        ) : null}

        {currentScreen === EVALUATION_STATE.ADDITIONAL_INFO ? (
          <>
            <Helmet>
              <title>Physician Additional Info</title>
            </Helmet>
            {miniCexAdditionalDetailsComponent()}
          </>
        ) : null}

        {currentScreen === EVALUATION_STATE.REVIEW && !!dataVersion ? (
          <>
            <Helmet>
              <title>Physician Review</title>
            </Helmet>
            <Paragraph>
              Please carefully review all the information that you entered for
              this Mini-CEX evaluation before submitting it to ECFMG, including
              your name and licensure information, all details of the clinical
              encounter, the scores you indicated for each of the applicant’s
              skill components, and the information you provided on what you 
              observed during the encounter that led to each
              score. If all the information is accurate, click Submit.{' '}
            </Paragraph>
            {miniCexReviewComponent()}
          </>
        ) : null}
      </>
    );
  };

  const viewDisplayed = () => {
    return (
      <>
        {!!data?.physicianPortal?.minicex?.summary ? (
          <ApplicantDetailsDesktop.Component
            summary={data.physicianPortal.minicex.summary as MiniCexSummaryType}
          ></ApplicantDetailsDesktop.Component>
        ) : null}
        <br />
        {evaluationScreen()}
      </>
    );
  };

  return (
    <div className="site-layout-content">
      {!isProfileCompleted && (
        <Paragraph
          style={{ backgroundColor: 'blanchedalmond', padding: '10px' }}
        >
          Your profile is not complete. You may only review and reject requests
          until you complete your profile.{' '}
          <Text strong>
            Click on the Profile tab to access the Profile page and complete the
            necessary information.
          </Text>{' '}
          Once your profile is complete you will be able to accept requests and
          complete Mini-CEX evaluations.
        </Paragraph>
      )}
      <Row>
        <Col flex="auto">
          <Title level={3}>
            ECFMG Mini-Clinical Evaluation Exercise (Mini-CEX) Evaluation{' '}
          </Title>
        </Col>
        <Col
          style={{ textAlign: 'right', marginBottom: '10px' }}
          xs={24}
          lg={6}
          md={6}
          sm={24}
        >
          <Button
            size={screens.lg ? 'large' : 'middle'}
            style={{ float: 'right' }}
            type="primary"
            onClick={() => {
              window.open(
                'https://www.ecfmg.org/certification-pathways/minicex-p6.pdf',
                "noopener",
                '_blank'
              );
            }}
          >
            View Sample Mini-CEX
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Paragraph>
            For a sample Mini-CEX form, see the PDF version of the{' '}
            <Link
              href="https://www.ecfmg.org/certification-pathways/minicex-p6.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Mini-CEX for Pathway 6
            </Link>
            . Please note that this sample version is for your reference only;
            it should not be used to complete the evaluation or be submitted to
            ECFMG.
          </Paragraph>
        </Col>
      </Row>

      {viewDisplayed()}
      <br />
    </div>
  );
};

export default EvaluationLayout;
