import { FC } from "react";
import { Grid, Typography } from "antd";
import AttestationDashboardTable from "./attestationDashboardTable";
import AttestationDashboardList from "./attestationDashboardList";
const { Paragraph, Text } = Typography;

const { useBreakpoint } = Grid;

const AttestationDashboardLayout: FC<any> = (props) => {
  const dashboardInstructions = (
    <Paragraph>
      Please complete the Clinical Skills Attestation for the applicant(s)
      listed below with a status of "New". For applicants planning to
      participate in the 2025 Match, ECFMG must receive all Clinical Skills
      Attestations submitted through this portal by{" "}
      <Text strong>February 15, 2025</Text>. Any applicant for whom ECFMG does
      not receive an acceptable Clinical Skills Attestation by this deadline may
      not meet ECFMG's clinical skills requirement in time to participate in the
      2025 Match.
    </Paragraph>
  );

  const screens = useBreakpoint();

  return (
    <>
      <Paragraph className="site-layout-content">
        {dashboardInstructions}
        <br />
        <br />
        {/*Anything bigger than lg will also be part of lg*/}
        {screens.lg ? (
          <AttestationDashboardTable />
        ) : (
          <AttestationDashboardList />
        )}
      </Paragraph>
    </>
  );
};

export default AttestationDashboardLayout;
