import { FC } from "react";
import { Row, Col, Divider, Grid, Descriptions, Typography } from "antd";
import {
  EvaluationCategory,
  DATE_FORMAT,
  getPhysicianName,
  convertDateFromTZTime,
  EncounterSettings,
  formatMonths,
} from "../common/constants";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import { LicenseCardList } from "./physician-license-card-list";
import {
  PhysicianLicenseRecordType,
  MiniCexResponseType,
  PhysicianProfileType,
  PhysicianResponseType,
} from "../generated";
import dayjs from "dayjs";

const { Text, Paragraph, Title } = Typography;

const { useBreakpoint } = Grid;

const ComponentPropTypes = {
  showApplicantDetails: PropTypes.bool,
  showLicensureInfo: PropTypes.bool,
};

export interface IProps {
  minicexSummary?: MiniCexResponseType;
  licenses?: PhysicianLicenseRecordType[];
  showApplicantDetails?: boolean;
  showLicensureInfo?: boolean;
  physician?: PhysicianResponseType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const ReadOnlyMiniCEXV3: FC<ComponentProps> = ({
  minicexSummary,
  showApplicantDetails,
  showLicensureInfo,
  licenses,
  physician,
}) => {
  const screens = useBreakpoint();

  const getAdditionalFeedbackArea = () => {
    if (
      minicexSummary &&
      minicexSummary?.form?.evaluation?.additionalInfoText
    ) {
      return (
        <>
          <Paragraph strong>Additional Feedback:</Paragraph>
          <div
            style={{ marginLeft: "1%", marginBottom: "4px", marginTop: "-9px" }}
          >
            <Paragraph style={{ marginTop: "-6px" }}>
              {minicexSummary.form.evaluation.additionalInfoText}
            </Paragraph>
          </div>
        </>
      );
    }
    return null;
  };

  const GetScoreCategory = (score: number) => {
    if (score <= 3) {
      return "Performs at the level of a medical school student beginning clinical training";
    }
    if (score <= 6) {
      return "Performs at the level of a medical school graduate";
    }
    return "Performs at the level of an experienced doctor / practicing physician";
  };

  const formatDate = (date: string | undefined) => {
    if (date !== undefined && date !== null) {
      return moment(date).utc().format(DATE_FORMAT);
    } else {
      return "";
    }
  };

  const ApplicantDetails = () => {
    if (showApplicantDetails) {
      if (
        !!minicexSummary?.summary?.applicantLastName &&
        !!minicexSummary?.summary?.applicantRestOfName &&
        !!minicexSummary?.summary?.usmleId &&
        !!minicexSummary?.summary?.encounterId &&
        !!minicexSummary?.summary?.createdAt &&
        !!minicexSummary?.summary?.acceptedAt
      ) {
        return (
          <>
            {screens.lg ? (
              <Descriptions
                className="ecfmg-small-descriptions"
                size="small"
                layout="vertical"
                column={5}
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.5px",
                  padding: "20px",
                }}
              >
                <Descriptions.Item label={"Applicant"}>
                  {minicexSummary?.summary?.applicantLastName},{" "}
                  {minicexSummary?.summary?.applicantRestOfName}{" "}
                </Descriptions.Item>
                <Descriptions.Item label="USMLE/ECFMG ID">
                  {minicexSummary?.summary?.usmleId}
                </Descriptions.Item>
                <Descriptions.Item label={"Encounter ID"}>
                  {minicexSummary?.summary?.encounterId}
                </Descriptions.Item>
                <Descriptions.Item label="Date Requested">
                  {formatDate(minicexSummary?.summary?.createdAt)}
                </Descriptions.Item>
                <Descriptions.Item label="Date Accepted">
                  {formatDate(minicexSummary?.summary?.acceptedAt)}
                </Descriptions.Item>
              </Descriptions>
            ) : (
              <Descriptions
                className="ecfmg-small-descriptions"
                size="small"
                layout="vertical"
                column={2}
                style={{
                  borderStyle: "solid",
                  borderWidth: "0.5px",
                  padding: "20px",
                }}
              >
                <Descriptions.Item label={"Applicant"}>
                  {minicexSummary?.summary?.applicantLastName},{" "}
                  {minicexSummary?.summary?.applicantRestOfName}{" "}
                </Descriptions.Item>
                <Descriptions.Item label="USMLE/ECFMG ID">
                  {minicexSummary?.summary?.usmleId}
                </Descriptions.Item>
                <Descriptions.Item label={"Encounter ID"}>
                  {minicexSummary?.summary?.encounterId}
                </Descriptions.Item>
                <Descriptions.Item label="Date Requested">
                  {formatDate(minicexSummary?.summary?.createdAt)}
                </Descriptions.Item>
                <Descriptions.Item label="Date Accepted">
                  {formatDate(minicexSummary?.summary?.acceptedAt)}
                </Descriptions.Item>
              </Descriptions>
            )}
          </>
        );
      } else {
        return (
          <Paragraph style={{ textAlign: "center" }}>
            Missing Applicant data
          </Paragraph>
        );
      }
    }
    return <></>;
  };

  const LicensureInfo = () => {
    if (showLicensureInfo) {
      return (
        <div style={{ padding: "10px" }}>
          {!!licenses ? (
            <>
              <Row>
                <Col span={12} style={{ fontSize: 10 }}>
                  Licensure Information:
                </Col>
              </Row>
              <LicenseCardList licenses={licenses} isEditable={false} />
            </>
          ) : (
            <Paragraph style={{ textAlign: "center" }}>
              Missing Licensure Information
            </Paragraph>
          )}
        </div>
      );
    }
    return <></>;
  };

  const PhysicianDetails = () => {
    if (!!minicexSummary?.summary?.physicianLastNameProvidedByPhysician) {
      return (
        <>
          {screens.lg ? (
            <>
              <div
                style={{
                  marginLeft: "1%",
                  marginBottom: "4px",
                  marginTop: "-9px",
                }}
              >
                <Row>
                  <Col style={{ fontSize: 10 }} span={6}>
                    Name:
                  </Col>
                  <Col style={{ fontSize: 10 }} offset={1} span={12}>
                    Primary E-mail Address:
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ fontSize: 14 }}>
                    {getPhysicianName(
                      minicexSummary?.summary
                        ?.physicianLastNameProvidedByPhysician,
                      minicexSummary?.summary
                        ?.physicianRestOfNameProvidedByPhysician
                    )}
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 14 }}>
                    {physician?.email}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: 10, marginTop: "5px" }} span={6}>
                    Date of Birth:
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ fontSize: 14 }}>
                    {dayjs(physician?.profile?.dateOfBirth).format(DATE_FORMAT)}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Descriptions
                className="ecfmg-small-descriptions"
                size="small"
                layout="vertical"
                column={2}
              >
                <Descriptions.Item label="Name">
                  {getPhysicianName(
                    minicexSummary?.summary
                      ?.physicianLastNameProvidedByPhysician,
                    minicexSummary?.summary
                      ?.physicianRestOfNameProvidedByPhysician
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Primary E-mail:">
                  {physician?.email}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth:">
                  {moment(physician?.profile?.dateOfBirth).format(DATE_FORMAT)}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
        </>
      );
    } else {
      return (
        <Paragraph style={{ textAlign: "center" }}>
          Missing Physician Name
        </Paragraph>
      );
    }
  };

  const EncounterDetails = () => {
    if (
      !!minicexSummary?.form?.encounter?.encounterDate &&
      !!minicexSummary?.form?.encounter?.encounterSetting &&
      !!minicexSummary?.form?.encounter?.facility?.name &&
      !!minicexSummary?.form?.encounter?.facility?.country?.name &&
      !!minicexSummary?.form?.encounter?.facility?.city &&
      typeof minicexSummary?.form?.encounter?.patient?.ageYear !== null &&
      typeof minicexSummary?.form?.encounter?.patient?.ageYear !==
        "undefined" &&
      !!minicexSummary?.form?.encounter?.patient?.ageMonth &&
      !!minicexSummary?.form?.encounter?.patient?.gender
    ) {
      return (
        <>
          {screens.lg ? (
            <>
              <div
                style={{
                  marginLeft: "1%",
                  marginBottom: "4px",
                  marginTop: "-9px",
                }}
              >
                <Row>
                  <Col span={6} style={{ fontSize: 10 }}>
                    Date of Encounter:
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 10 }}>
                    Clinical Setting:
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ fontSize: 14 }}>
                    {convertDateFromTZTime(
                      minicexSummary?.form?.encounter?.encounterDate ?? ""
                    )}
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 14 }}>
                    {
                      EncounterSettings.find(
                        (o) =>
                          o.key ===
                          minicexSummary?.form?.encounter?.encounterSetting
                      )?.value
                    }
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Descriptions
                layout="vertical"
                className="ecfmg-small-descriptions"
                size="small"
                column={2}
              >
                <Descriptions.Item label="Date of Encounter">
                  {convertDateFromTZTime(
                    minicexSummary?.form?.encounter?.encounterDate ?? ""
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Clinical Setting">
                  {
                    EncounterSettings.find(
                      (o) =>
                        o.key ===
                        minicexSummary?.form?.encounter?.encounterSetting
                    )?.value
                  }
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          <Title level={5} style={{ marginTop: "10px", fontWeight: 600 }}>
            Facility/Hospital at which encounter took place:{" "}
          </Title>
          {screens.lg ? (
            <>
              <div
                style={{
                  marginLeft: "1%",
                  marginBottom: "4px",
                  marginTop: "-9px",
                }}
              >
                <Row>
                  <Col span={6} style={{ fontSize: 10 }}>
                    Name:
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 10 }}>
                    State / Province:
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ fontSize: 14 }}>
                    {minicexSummary?.form?.encounter?.facility?.name}{" "}
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 14 }}>
                    {minicexSummary?.form?.encounter?.facility?.state}
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ fontSize: 10 }}>
                    Country:
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 10 }}>
                    City:
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ fontSize: 14 }}>
                    {minicexSummary?.form?.encounter?.facility?.country?.name}
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 14 }}>
                    {minicexSummary?.form?.encounter?.facility?.city}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Descriptions
                layout="vertical"
                className="ecfmg-small-descriptions"
                size="small"
                column={2}
              >
                <Descriptions.Item label="Name">
                  {minicexSummary?.form?.encounter?.facility?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  {minicexSummary?.form?.encounter?.facility?.country?.name}
                </Descriptions.Item>
                <Descriptions.Item label="State / Province">
                  {minicexSummary?.form?.encounter?.facility?.state}
                </Descriptions.Item>
                <Descriptions.Item label="City">
                  {minicexSummary?.form?.encounter?.facility?.city}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          <Title level={5} style={{ marginTop: "10px", fontWeight: 600 }}>
            Patient:
          </Title>
          {screens.lg ? (
            <>
              <div
                style={{
                  marginLeft: "1%",
                  marginBottom: "4px",
                  marginTop: "-9px",
                }}
              >
                <Row>
                  <Col span={6} style={{ fontSize: 14, lineHeight: "10px" }}>
                    Age:
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 10 }}>
                    Gender:
                  </Col>
                </Row>
                <Row>
                  <Col span={2} style={{ fontSize: 10 }}>
                    Years:
                  </Col>
                  <Col span={2} style={{ fontSize: 10 }}>
                    Months:
                  </Col>
                  <Col
                    offset={3}
                    span={6}
                    style={{
                      fontSize: 14,
                      lineHeight: "10px",
                      paddingTop: "7px",
                    }}
                  >
                    {minicexSummary?.form?.encounter?.patient?.gender}
                  </Col>
                </Row>
                <Row>
                  <Col span={2} style={{ fontSize: 14 }}>
                    {minicexSummary?.form?.encounter?.patient?.ageYear}{" "}
                  </Col>
                  <Col span={2} style={{ fontSize: 14 }}>
                    {formatMonths(
                      minicexSummary?.form?.encounter?.patient?.ageMonth
                    )}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Descriptions
                layout="vertical"
                className="ecfmg-small-descriptions"
                size="small"
                column={12}
              >
                <Descriptions.Item
                  span={6}
                  label={<Text style={{ fontSize: "14px" }}>Age</Text>}
                >
                  <Descriptions
                    layout="vertical"
                    className="ecfmg-small-descriptions"
                    size="small"
                    column={12}
                  >
                    <Descriptions.Item span={6} label="Years">
                      {minicexSummary?.form?.encounter?.patient?.ageYear}
                    </Descriptions.Item>
                    <Descriptions.Item span={6} label="Months">
                      {formatMonths(
                        minicexSummary?.form?.encounter?.patient?.ageMonth
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Descriptions.Item>
                <Descriptions.Item span={6} label="Gender">
                  {minicexSummary?.form?.encounter?.patient?.gender}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
        </>
      );
    } else {
      return (
        <Paragraph style={{ textAlign: "center" }}>
          Missing Encounter Data
        </Paragraph>
      );
    }
  };

  const MiniCEXResponses = () => {
    if (!!minicexSummary?.form?.evaluation?.categories) {
      return (
        <>
          {minicexSummary?.form?.evaluation?.categories?.map((cate) => (
            <div key={cate?.name}>
              <Paragraph style={{ marginTop: "10px", color: "#333333" }} strong>
                {EvaluationCategory[cate?.name!]}:
              </Paragraph>
              {screens.lg ? (
                <div
                  style={{
                    marginLeft: "1%",
                    marginBottom: "4px",
                    marginTop: "-9px",
                  }}
                >
                  <Row>
                    <Col span={6} style={{ fontSize: 10 }}>
                      Score:
                    </Col>
                    <Col offset={1} span={12} style={{ fontSize: 10 }}>
                      Level:
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6} style={{ fontSize: 14 }}>
                      {cate?.score}
                    </Col>
                    <Col offset={1} span={12} style={{ fontSize: 14 }}>
                      {GetScoreCategory(cate?.score as number)}
                    </Col>
                  </Row>

                  {cate?.reasonText ? (
                    <>
                      <Paragraph style={{ fontSize: 10 }}>
                        Observations made during the clinical encounter that
                        led to the score:
                      </Paragraph>
                      <Paragraph style={{ marginTop: "-9px" }}>
                        {cate.reasonText}
                      </Paragraph>
                    </>
                  ) : null}
                </div>
              ) : (
                <>
                  <Descriptions
                    layout="vertical"
                    className="ecfmg-small-descriptions"
                    size="small"
                    column={2}
                  >
                    <Descriptions.Item label="Score">
                      {cate?.score}
                    </Descriptions.Item>
                    <Descriptions.Item label="Level">
                      {GetScoreCategory(cate?.score as number)}
                    </Descriptions.Item>
                  </Descriptions>
                  {cate?.reasonText ? (
                    <>
                      <Paragraph style={{ fontSize: 10 }}>
                        Observations made during the clinical encounter that
                        led to the score:
                      </Paragraph>
                      <Paragraph style={{ marginTop: "-9px" }}>
                        {cate.reasonText}
                      </Paragraph>
                    </>
                  ) : null}
                </>
              )}
            </div>
          ))}
        </>
      );
    } else {
      return (
        <Paragraph style={{ textAlign: "center" }}>
          Missing Mini-CEX Data
        </Paragraph>
      );
    }
  };

  const DateStamps = () => {
    if (!!minicexSummary?.summary?.acceptedAt) {
      return (
        <>
          {screens.lg ? (
            <>
              <div
                style={{
                  marginLeft: "1%",
                  marginBottom: "4px",
                  marginTop: "-9px",
                }}
              >
                <Row>
                  <Col span={6} style={{ fontSize: 10 }}>
                    Request Accepted On
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 10 }}>
                    {minicexSummary?.summary?.completedAt
                      ? "Request Completed On"
                      : ""}
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ fontSize: 14 }}>
                    {formatDate(minicexSummary?.summary?.acceptedAt)}
                  </Col>
                  <Col offset={1} span={12} style={{ fontSize: 14 }}>
                    {minicexSummary?.summary?.completedAt
                      ? formatDate(minicexSummary?.summary.completedAt)
                      : ""}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Descriptions
                layout="vertical"
                className="ecfmg-small-descriptions"
                size="small"
                column={2}
              >
                <Descriptions.Item label="Request Accepted On">
                  {formatDate(minicexSummary?.summary?.acceptedAt)}
                </Descriptions.Item>
                {minicexSummary?.summary?.completedAt ? (
                  <Descriptions.Item label="Request Completed On">
                    {formatDate(minicexSummary?.summary.completedAt)}
                  </Descriptions.Item>
                ) : null}
              </Descriptions>
            </>
          )}
        </>
      );
    } else {
      return (
        <Paragraph style={{ textAlign: "center" }}>
          Missing Date Accepted
        </Paragraph>
      );
    }
  };

  const MiniCEXView = (
    <>
      {minicexSummary ? (
        <>
          <ApplicantDetails />
          <Divider orientation="left">
            <Text strong>Physician Details</Text>
          </Divider>
          <PhysicianDetails />
          <LicensureInfo />
          <Divider orientation="left">
            <Text strong>Encounter</Text>
          </Divider>
          <EncounterDetails />
          <Divider orientation="left">
            <Text strong>Mini-CEX Responses</Text>
          </Divider>
          <MiniCEXResponses />
          {getAdditionalFeedbackArea()}
          <Divider orientation="left" />
          <DateStamps />
        </>
      ) : null}
    </>
  );

  return <>{MiniCEXView}</>;
};

ReadOnlyMiniCEXV3.defaultProps = {
  showApplicantDetails: true,
  showLicensureInfo: false,
};

export default ReadOnlyMiniCEXV3;
