import { List } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import _ from 'lodash';
import PropTypes, { InferProps } from 'prop-types';
import { FC, useEffect, useState } from 'react';
import completedStepImage from './images/completed-step.png';
import currentStepImage from './images/current-step.png';
import uncompletedStepImage from './images/uncompleted-step.png';

const ComponentPropTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.string.isRequired,
};

export interface IProps {
  __typename?: 'MiniCEXSteps';
  /** List of steps */
  steps: string[];
  /** Current step in progress */
  currentStep: string;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const MiniCEXSteps: FC<ComponentProps> = ({ currentStep, steps }) => {
  const screens = useBreakpoint();
  const [stepTextFontSize, setStepTextFontSize] = useState<any>('');

  useEffect(() => {
    if (screens.xl) {
      setStepTextFontSize('100%');
    } else if (screens.lg) {
      setStepTextFontSize('95%');
    } else if (screens.sm) {
      setStepTextFontSize('90%');
    } else if (screens.xs) {
      setStepTextFontSize('54%');
    }
  }, [screens]);

  const getBackgroundImage = (currentItem: any) => {
    if (currentItem === currentStep) {
      return currentStepImage;
    }
    const indexOfCurrentStep = _.indexOf(steps, currentStep);
    const indexOfCurrentItem = _.indexOf(steps, currentItem);
    if (indexOfCurrentItem < indexOfCurrentStep) {
      return completedStepImage;
    }
    return uncompletedStepImage;
  };

  return (
    <>
      <List
        grid={{
          gutter: 16,
          xs: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        dataSource={steps}
        renderItem={(item) => (
          <List.Item>
            <div
              style={{
                paddingLeft: '5px',
                textAlign: 'center',
                backgroundImage: `url(${getBackgroundImage(item)})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '3rem',
                margin: 'auto',
                lineHeight: '3rem',
                fontSize: stepTextFontSize,
                color: item === currentStep ? 'white' : 'black',
              }}
            >
              {item}
            </div>
          </List.Item>
        )}
      />
    </>
  );
};
