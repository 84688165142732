import React, { FC, Suspense } from "react";
import PropTypes, { InferProps } from "prop-types";
import {
  PhysicianLicenseRecordType,
  MiniCexResponseType,
  UiPageConfigType,
  PhysicianProfileType,
  PhysicianResponseType,
} from "../generated";
import ReadOnlyMiniCEXV2 from "./read-only-miniCEX-V2";
import ReadOnlyMiniCEXV3 from "./read-only-miniCEX-V3";

const PAGE_NAME = "minicex-summary";
const MINICEX_COMPONENT_NAME = "minicex-summary-review";

export interface IProps {
  minicexSummary?: MiniCexResponseType;
  licenses?: PhysicianLicenseRecordType[];
  showApplicantDetails?: boolean;
  showLicensureInfo?: boolean;
  physician?: PhysicianResponseType;
}

export type ComponentProps = InferProps<IProps>;

const ReadOnlyMiniCEXFactory: FC<ComponentProps> = ({
  minicexSummary,
  showApplicantDetails,
  showLicensureInfo,
  licenses,
  physician,
}) => {
  const ReadOnlyMiniCEX = React.lazy(
    () => import("./Physician-ReadOnlyMiniCEX")
  );

  const renderVersion = () => {
    const uiConfig = minicexSummary?.uiConfig;
    var miniCEXVersionNum = 0;

    uiConfig?.config?.pages?.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME) {
        page.components?.map((component) => {
          if (component?.componentName === MINICEX_COMPONENT_NAME) {
            miniCEXVersionNum = component?.version || 0;
          }
        });
      }
    });

    switch (miniCEXVersionNum) {
      case 1: {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ReadOnlyMiniCEX
              minicexSummary={minicexSummary}
              showApplicantDetails={showApplicantDetails}
              licenses={licenses}
              showLicensureInfo={showLicensureInfo}
            />
          </Suspense>
        );
      }
      case 2: {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ReadOnlyMiniCEXV2
              minicexSummary={minicexSummary}
              showApplicantDetails={showApplicantDetails}
              licenses={licenses}
              showLicensureInfo={showLicensureInfo}
              physician={physician}
            />
          </Suspense>
        );
      }
      case 3: {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ReadOnlyMiniCEXV3
              minicexSummary={minicexSummary}
              showApplicantDetails={showApplicantDetails}
              licenses={licenses}
              showLicensureInfo={showLicensureInfo}
              physician={physician}
            />
          </Suspense>
        );
      }
      default: {
        return <div></div>;
      }
    }
  };

  return renderVersion();
};

export default ReadOnlyMiniCEXFactory;
