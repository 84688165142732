import React, { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Input,
  Descriptions,
  DatePicker,
  Form,
  Select,
  message,
  Grid,
  Spin,
  Modal,
  Typography,
  Space,
} from "antd";
import PropTypes, { InferProps } from "prop-types";
import { DocumentNode, useMutation, useQuery } from "@apollo/client";
import {
  EVALUATION_STATE,
  DATE_FORMAT,
  convertDateFromTZTime,
  confirmNavigatingAway,
  MENU_KEYS,
} from "../../../common/constants";
import moment from "moment";
import {
  PhysicianEncounterInformationCountryDocument,
  PhysicianEncounterInformationSetEncounterDocument,
  PhysicianEncounterInformationEncounterFieldsFragmentDoc,
  MiniCexEncounterType,
  MiniCexSummaryType,
} from "../../../generated";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text, Paragraph, Title } = Typography;

const FORM_FIELD_NAMES = {
  dateOfEncounter: "dateOfEncounter",
  setting: "setting",
  facilityName: "facilityName",
  country: "country",
  state: "state",
  city: "city",
  ageYear: "ageYear",
  ageMonth: "ageMonth",
  gender: "gender",
};

const EncounterDetailsFragment = {
  EncounterDetails: PhysicianEncounterInformationEncounterFieldsFragmentDoc,
};

const ComponentPropTypes = {
  encounterId: PropTypes.string.isRequired,
  dataVersion: PropTypes.number,
  refetchQuery: PropTypes.object.isRequired,
  updateScreenName: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "EncounterInformation";
  encounter?: MiniCexEncounterType;
  acceptedAt?: MiniCexSummaryType["acceptedAt"];
  encounterId: string;
  dataVersion?: number;
  refetchQuery: DocumentNode;
  updateScreenName: (screenName: string) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const EncounterInformation: FC<ComponentProps> = ({
  encounter,
  encounterId,
  dataVersion,
  refetchQuery,
  acceptedAt,
  updateScreenName,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [country, setCountry] = useState<string>(
    encounter?.facility?.country?.name ?? ""
  );

  const [MINICEX_SET_ENCOUNTER, { loading: setMiniCEXLoading }] = useMutation(
    PhysicianEncounterInformationSetEncounterDocument,
    {
      refetchQueries: [
        {
          query: refetchQuery as DocumentNode,
          variables: { encounterId: encounterId },
        },
      ],
      awaitRefetchQueries: false,
    }
  );

  const {
    loading: getCountryListLoading,
    error,
    data,
  } = useQuery(PhysicianEncounterInformationCountryDocument);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const formSubmission = async (form: any) => {
    await MINICEX_SET_ENCOUNTER({
      variables: {
        encounterId: encounterId,
        dataVersion: dataVersion!,
        isComplete: form.isSubmit,
        encounterDate: form.dateOfEncounter,
        encounterSetting: form.setting,
        facilityName: form.facilityName,
        city: form.city,
        state: form.state,
        countryName: country,
        countryRef: form.country,
        patientAgeYear: parseInt(form.ageYear),
        patientAgeMonth: form.ageMonths,
        patientGender: form.gender,
      },
    })
      .then(() => {
        setIsFormDirty(false);
        location.state = {
          menuItemSelected: MENU_KEYS.DASHBOARD,
          isMiniCEXFormDirty: false,
        };
        if (form.isSubmit) {
          //push to next component
          updateScreenName(EVALUATION_STATE.EVALUATION);
        } else {
          message.success("Saved successfully");
        }
      })
      .catch((error: any) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const onCountryChange = (countryId: string) => {
    const countryName = data?.country?.find(
      (c: any) => c._id === countryId
    )?.countryName;
    setCountry(countryName ?? "");
  };

  const disabledDates = (current: any) => {
    return (
      current > moment().endOf("day") ||
      current < moment(acceptedAt).startOf("day")
    );
  };

  const onFieldsChangeHandler = () => {
    let date_ = convertDateFromTZTime(
      form.getFieldValue(FORM_FIELD_NAMES.dateOfEncounter)
    );
    let setting_ = form.getFieldValue(FORM_FIELD_NAMES.setting);
    let facName_ = form.getFieldValue(FORM_FIELD_NAMES.facilityName);
    let couRef_ = form.getFieldValue(FORM_FIELD_NAMES.country);
    let state_ = form.getFieldValue(FORM_FIELD_NAMES.state);
    let city_ = form.getFieldValue(FORM_FIELD_NAMES.city);
    let ageYear_ = form.getFieldValue(FORM_FIELD_NAMES.ageYear) ?? 0;
    let ageMonth_ = form.getFieldValue(FORM_FIELD_NAMES.ageMonth) ?? "NA";
    let gender_ = form.getFieldValue(FORM_FIELD_NAMES.gender);

    let saveEncDate = encounter?.encounterDate ?? undefined;
    saveEncDate = convertDateFromTZTime(saveEncDate);
    let savedSetting = encounter?.encounterSetting;
    let savedFacName = encounter?.facility?.name;
    let savedConRef = encounter?.facility?.country?.ref;
    let savedFacState = encounter?.facility?.state;
    let savedFacCity = encounter?.facility?.city;
    let savedAgeYear = encounter?.patient?.ageYear ?? 0;
    let savedAgeMonth = encounter?.patient?.ageMonth ?? "NA";
    let saveGender = encounter?.patient?.gender;
    if (
      saveEncDate !== date_ ||
      savedSetting !== setting_ ||
      savedFacName !== facName_ ||
      savedConRef !== couRef_ ||
      savedFacState !== state_ ||
      savedFacCity !== city_ ||
      savedAgeYear.toString() !== ageYear_.toString() ||
      savedAgeMonth !== ageMonth_ ||
      saveGender !== gender_
    ) {
      setIsFormDirty(true);
      location.state = {
        menuItemSelected: MENU_KEYS.DASHBOARD,
        isMiniCEXFormDirty: true,
      };
    } else {
      setIsFormDirty(false);
      location.state = {
        menuItemSelected: MENU_KEYS.DASHBOARD,
        isMiniCEXFormDirty: false,
      };
    }
  };

  const PreviousButton = () => {
    return (
      <Button
        size={screens.lg ? "large" : "middle"}
        style={{ float: "left" }}
        onClick={() => {
          if (isFormDirty) {
            confirmNavigatingAway(() => {
              setIsFormDirty(false);
              updateScreenName(EVALUATION_STATE.ATTESTATION);
            });
          } else {
            updateScreenName(EVALUATION_STATE.ATTESTATION);
          }
        }}
      >
        <DoubleLeftOutlined />
        Previous
      </Button>
    );
  };

  const ReturnToDashboardButton = () => {
    const buttonLargeStyle: React.CSSProperties = {
      float: "left",
      // marginLeft: "25px",
    };
    const buttonMobileStyle: React.CSSProperties = {
      width: "100%",
    };
    return (
      <>
        <Button
          size={screens.lg ? "large" : "middle"}
          style={screens.lg ? buttonLargeStyle : buttonMobileStyle}
          onClick={() => {
            if (isFormDirty) {
              confirmNavigatingAway(() => {
                setIsFormDirty(false);
                navigate("/physician/dashboard", {
                  state: {
                    menuItemSelected: MENU_KEYS.DASHBOARD,
                    isMiniCEXFormDirty: false,
                  },
                });
              });
            } else {
              navigate("/physician/dashboard", {
                state: {
                  menuItemSelected: MENU_KEYS.DASHBOARD,
                  isMiniCEXFormDirty: false,
                },
              });
            }
          }}
        >
          Return to Dashboard
        </Button>
      </>
    );
  };

  const onSaveClick = async () => {
    form.setFieldsValue({ isSubmit: false });
    await formSubmission(form.getFieldsValue());
  }

  if (getCountryListLoading) {
    return <Spin></Spin>;
  }
  return (
    <>
      <Form
        form={form}
        onFinish={(values: any) => formSubmission(values)}
        onFieldsChange={() => onFieldsChangeHandler()}
      >
        {/* <Prompt when={isFormDirty} message={() => MiniCEXFormNavigateAwayConfirmMessage} /> */}
        <Paragraph style={{ width: screens.lg ? "40%" : "100%" }}>
          Select the date on which the encounter took place based on Coordinated
          Universal Time (UTC). <b>Please note:</b> The encounter must have taken place after
          you accepted the request through this portal to complete it. You will
          be unable to select a date for the encounter prior to the date you
          accepted the request. All dates are calculated based on UTC.
        </Paragraph>
        <Descriptions
          className="ecfmg-small-descriptions"
          size="small"
          layout="vertical"
          column={screens.lg ? 2 : 1}
        >
          <Descriptions.Item label="Date of Encounter">
            <Form.Item
              name="dateOfEncounter"
              rules={[
                {
                  required: true,
                  message: "Please select the date of the encounter",
                },
              ]}
              initialValue={
                encounter?.encounterDate
                  ? moment(encounter.encounterDate)
                  : null
              }
            >
              <DatePicker
                format={DATE_FORMAT}
                disabledDate={(e: any) => disabledDates(e)}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Clinical Setting">
            <Form.Item
              name="setting"
              rules={[{ required: true, message: "Please select a setting" }]}
              initialValue={encounter?.encounterSetting}
            >
              <Select
                placeholder={"Select a setting"}
                style={{ width: "200px" }}
              >
                <Option value="OUTPATIENT">Outpatient</Option>
                <Option value="IN-PATIENT">Inpatient</Option>
                <Option value="EMERGENCY">Emergency Department</Option>
                <Option value="OTHER">Other</Option>
              </Select>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <div style={{ display: screens.lg ? "flex" : "inline" }}>
          <div>
            <Title level={5} style={{ fontWeight: 600 }}>
              Facility / Hospital at which encounter took place:
            </Title>
            <Descriptions
              className="ecfmg-small-descriptions"
              size="small"
              layout="vertical"
              column={1}
            >
              <Descriptions.Item label="Name">
                <Form.Item
                  name="facilityName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the facility name",
                    },
                  ]}
                  initialValue={encounter?.facility?.name}
                >
                  <Input style={{ width: "300px" }} />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="Country">
                <Form.Item
                  name="country"
                  rules={[
                    { required: true, message: "Please select a country" },
                  ]}
                  initialValue={encounter?.facility?.country?.ref}
                >
                  {!!data?.country ? (
                    <Select
                      placeholder={"Select a country"}
                      onChange={(e: any) => {
                        onCountryChange(e);
                      }}
                      style={{ width: "300px" }}
                      loading={getCountryListLoading}
                      disabled={getCountryListLoading}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB: any) =>
                        optionA.children
                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            optionB.children.toString().toLowerCase()
                          )
                      }
                    >
                      {data?.country?.map((countryData: any) => (
                        <Option key={countryData._id} value={countryData._id}>
                          {countryData.countryName}
                        </Option>
                      ))}
                    </Select>
                  ) : null}
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="State / Province">
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: country === "USA" || country === "CANADA",
                      message: "Please enter a state",
                    },
                  ]}
                  initialValue={encounter?.facility?.state}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="City">
                <Form.Item
                  name="city"
                  rules={[{ required: true, message: "Please enter a city" }]}
                  initialValue={encounter?.facility?.city}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div>
            <Title level={5} style={{ fontWeight: 600 }}>
              Patient:{" "}
            </Title>

            <Text strong>Age</Text>
            <Descriptions
              className="ecfmg-small-descriptions"
              size="small"
              layout="vertical"
              column={4}
            >
              <Descriptions.Item label="Years">
                <Form.Item
                  name="ageYear"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the patient's age in years",
                    },
                    {
                      pattern: new RegExp("^[0-9]*$"),
                      message: "Please enter a valid age",
                    },
                  ]}
                  initialValue={encounter?.patient?.ageYear}
                >
                  <Input />
                </Form.Item>
              </Descriptions.Item>

              <Descriptions.Item label="Months">
                <Form.Item
                  name="ageMonths"
                  rules={[
                    {
                      required: parseInt(form.getFieldValue("ageYear")) === 0,
                      message: "Please select an age in months",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, _value) {
                        const ageYear = parseInt(form.getFieldValue("ageYear"));
                        if (ageYear !== 0) {
                          return Promise.resolve();
                        }
                        if (
                          ageYear === 0 &&
                          getFieldValue("ageMonths") !== "NA"
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please select an age in months")
                        );
                      },
                    }),
                  ]}
                  initialValue={encounter?.patient?.ageMonth || "NA"}
                >
                  <Select style={{ width: 150 }}>
                    <Option value="NA">N/A</Option>
                    <Option value="less-than-1-month">Less than 1 month</Option>
                    <Option value="1-month">1 month</Option>
                    <Option value="2-months">2 months</Option>
                    <Option value="3-months">3 months</Option>
                    <Option value="4-months">4 months</Option>
                    <Option value="5-months">5 months</Option>
                    <Option value="6-months">6 months</Option>
                    <Option value="7-months">7 months</Option>
                    <Option value="8-months">8 months</Option>
                    <Option value="9-months">9 months</Option>
                    <Option value="10-months">10 months</Option>
                    <Option value="11-months">11 months</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>

            <Descriptions
              className="ecfmg-small-descriptions"
              size="small"
              layout="vertical"
              column={1}
            >
              <Descriptions.Item label="Gender">
                <Form.Item
                  name="gender"
                  rules={[
                    { required: true, message: "Please select a gender" },
                  ]}
                  initialValue={encounter?.patient?.gender}
                >
                  <Select
                    placeholder={"Select a gender"}
                    style={{
                      width: screens.lg ? 350 : 300,
                      overflow: "ellipsis",
                      whiteSpace: "pre-wrap",
                      height: "auto",
                    }}
                  >
                    <Option value="Female">Female</Option>
                    <Option value="Male">Male</Option>
                    <Option value="Transgender">Transgender</Option>
                    <Option
                      value="Does not identify as female, male, or transgender"
                      style={{ whiteSpace: "pre-wrap", height: "auto" }}
                    >
                      Does not identify as female, male, or transgender
                    </Option>
                    <Option value="Prefer not to specify">
                      Prefer not to specify
                    </Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <br />
        {screens.lg ? (
          <>
            <Space>
              <PreviousButton />
              <ReturnToDashboardButton />
            </Space>
            <Space style={{ float: "right" }} align={"start"}>
              <Form.Item name="isSubmit"></Form.Item>
              <Button
                size="large"
                onClick={onSaveClick}
              >
                Save
              </Button>

              <Button
                size="large"
                type="primary"
                onClick={() => {
                  form.setFieldsValue({ isSubmit: true });
                  form.submit();
                }}
              >
                Continue <DoubleRightOutlined />
              </Button>
            </Space>
          </>
        ) : (
          <>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div style={{ alignItems: "justify" }}>
                <Space
                  align="start"
                  style={{ justifyContent: "space-between", width: "100%" }}
                >
                  <PreviousButton />

                  <Form.Item name="isSubmit"></Form.Item>
                  <Button
                    onClick={onSaveClick}
                  >
                    Save
                  </Button>

                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldsValue({ isSubmit: true });
                      form.submit();
                    }}
                  >
                    Continue <DoubleRightOutlined />
                  </Button>
                </Space>
              </div>

              <ReturnToDashboardButton />
            </Space>
          </>
        )}
        <Modal
          footer={null}
          visible={setMiniCEXLoading}
          closable={false}
          style={{ textAlign: "center" }}
          width="200px"
          destroyOnClose
        >
          <Spin /> Processing...
        </Modal>
      </Form>
    </>
  );
};

export default {
  Component: EncounterInformation,
  Fragment: EncounterDetailsFragment,
};
