import { FC, useState, useEffect } from "react";
import { Button, Table, Modal, Grid, Col, Row, Typography } from "antd";
import {
  FormOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";

import {
  PHYSICIAN_ALLOWED_ACTIONS,
  CEX_STATUS,
  DATE_FORMAT,
  ACTIVE_MINICEX_STATUSES,
} from "../../common/constants";
import { PhysicianAttestation } from "./physician-attestation";
import { MiniCexSummaryType, PhysicianResponseType } from "../../generated";

const { Text, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const ComponentPropTypes = {
  onReviewResponse: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  acceptMiniCEXLoading: PropTypes.bool.isRequired,
  rejectMiniCEXLoading: PropTypes.bool.isRequired,
};

export interface IProps {
  physician?: PhysicianResponseType;
  minicexList?: MiniCexSummaryType[];
  onReviewResponse: (record: any, response: any, index: any) => void;
  loading: boolean;
  acceptMiniCEXLoading: boolean;
  rejectMiniCEXLoading: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const getStatus = (obj: any, key: any) => {
  return obj[key];
};

const PhysicianActiveEncounterTable: FC<ComponentProps> = ({
  physician,
  minicexList,
  onReviewResponse,
  loading,
  acceptMiniCEXLoading,
  rejectMiniCEXLoading,
}) => {
  const screens = useBreakpoint();
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [stateModel, setstateModel] = useState<any>();
  const [showAttestationModal, setShowAttestationModal] =
    useState<boolean>(false);
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // /currentRecord?.stateModel
    var filteredStateModel = currentRecord?.stateModel.filter(
      (item: { state: string }) => item.state !== ACTIVE_MINICEX_STATUSES[0]
    ); //ACTIVE_MINICEX_STATUSES[0] = MINICEX_CREATED_PHYSICIAN_INVITE_PENDING
    setstateModel(filteredStateModel);
  }, [currentRecord]);

  const onReviewClick = (record: any, index: any) => {
    setCurrentRecord(record);
    setCurrentIndex(index);
    setShowAttestationModal(true);
  };

  const closeAttestationModal = () => {
    setShowAttestationModal(false);
  };

  const handleOnReviewResponse = (record: any, response: any) => {
    onReviewResponse(record, response, currentIndex);
    setShowAttestationModal(false);
  };

  const onOpenEvaluation = (record: any) => {
    navigate(
      `/physician/dashboard/minicex/${record?.encounterId}`,
      { state: { menuItemSelected: "" } } //No menu item for this route
    );
  };

  const setModalWidth = () => {
    if (screens.lg) {
      return "60vw";
    } else {
      return "90vw";
    }
  };

  const renderButtons = (record: any, index: any) => {
    if (record.allowedActions.includes(PHYSICIAN_ALLOWED_ACTIONS.REVIEW)) {
      return (
        <Button
          type="primary"
          onClick={() => onReviewClick(record, index)}
          icon={<SolutionOutlined />}
          className="ant-btn-left-align"
        >
          Review Request
        </Button>
      );
    } else if (record.allowedActions.includes(PHYSICIAN_ALLOWED_ACTIONS.VIEW)) {
      return (
        <Button className="ant-btn-left-align" icon={<SearchOutlined />}>
          View
        </Button>
      );
    } else if (record.allowedActions.includes(PHYSICIAN_ALLOWED_ACTIONS.OPEN)) {
      return (
        <Button
          type="primary"
          className="ant-btn-left-align"
          onClick={() => {
            onOpenEvaluation(record);
          }}
          icon={<FormOutlined />}
        >
          Open Mini-CEX
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const columns = [
    {
      title: " Encounter ID",
      dataIndex: "encounterId",
      key: "encounterId",
    },
    {
      title: "USMLE/ECFMG ID",
      dataIndex: "usmleId",
      key: "usmleId",
    },
    {
      title: "Applicant Last Name",
      dataIndex: "applicantLastName",
      key: "applicantLastName",
    },
    {
      title: "Applicant Rest of Name",
      dataIndex: "applicantRestOfName",
      key: "applicantRestOfName",
    },

    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (status: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <Row wrap={false}>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <InfoCircleOutlined
                  style={{ fontSize: "18px" }}
                  onClick={() => {
                    setCurrentRecord(record);
                    setShowEncounterHistoryModal(true);
                  }}
                />
              </Col>
              <Col offset={1}>{getStatus(CEX_STATUS, record.state)}</Col>
            </Row>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      title: "Date Requested",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any, record: any) => (
        <div>
          {record.createdAt ? (
            <Text>{moment(record.createdAt).utc().format(DATE_FORMAT)}</Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Action Due",
      dataIndex: "expiresAt",
      key: "expiresAt",
      render: (expiresAt: any, record: any) => (
        <div>
          {record.expiresAt ? (
            <Text>
              {moment(record.expiresAt).utc().format(DATE_FORMAT) + " 23:59"}
            </Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "allowedActions",
      key: "allowedActions",
      render: (allowedActions: any, record: any, index: any) =>
        renderButtons(record, index),
    },
  ];

  const historyColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <Text>{getStatus(CEX_STATUS, record.state) + " "}</Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any, record: any) => (
        <div>
          {record.timestamp !== undefined ? (
            <Text>
              {moment(record.timestamp)
                .utc()
                .format(`${DATE_FORMAT}, h:mm:ss a`)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={minicexList as any}
        size="middle"
        loading={loading}
      ></Table>
      <Modal
        title="Review Request"
        visible={showAttestationModal}
        width={setModalWidth()}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <PhysicianAttestation
          currentRecord={currentRecord}
          currentIndex={currentIndex}
          closeAttestationModal={() => closeAttestationModal()}
          onReviewResponse={(record: any, response: any) =>
            handleOnReviewResponse(record, response)
          }
          profileStatus={physician?.minicex?.profileStatus as string}
          acceptMiniCEXLoading={acceptMiniCEXLoading}
          rejectMiniCEXLoading={rejectMiniCEXLoading}
        ></PhysicianAttestation>
      </Modal>
      <Modal
        title="Status History"
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button
            size="large"
            key="close"
            onClick={() => setShowEncounterHistoryModal(false)}
          >
            Close
          </Button>,
        ]}
        destroyOnClose
      >
        <div>
          Applicant:{" "}
          <Text
            strong
          >{`${currentRecord?.applicantLastName}, ${currentRecord?.applicantRestOfName}`}</Text>
          <br />
          Encounter ID: <Text strong>{currentRecord?.encounterId}</Text>
          <br />
          <br />
          <Table columns={historyColumns} dataSource={stateModel}></Table>
          <Paragraph>
            All times are calculated using Coordinated Universal Time (UTC).
          </Paragraph>
        </div>
      </Modal>
    </>
  );
};
export default PhysicianActiveEncounterTable;
