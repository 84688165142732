import {
  MsalProviderPopupConfig,
  MsalProviderRedirectConfig,
} from "../components/msal-react-lite";
import * as msal from "@azure/msal-browser";
import { ConfigType, MsalProviderConfigMap } from "../components/msal-react-lite/msal-provider";

var clientIdEMSWP = process.env.REACT_APP_CLIENTID ?? "missing-client-id";
var tenantAuthorityEMSWP = process.env.REACT_APP_AUTHORITY_EMSWP ?? "missing-tenant-id";
var tenantAuthorityPhysician = process.env.REACT_APP_AUTHORITY_PHYSICIAN ?? "missing-tenant-id";
var knownAuthorities = process.env.REACT_APP_KNOWN_AUTHORITIES ?? "missing-known-authorities";
var redirectUri =
  process.env.REACT_APP_AAD_REDIRECT_URI ?? "missing-redirect-uri";
var scopes = process.env.REACT_APP_AUTH_SCOPE ?? "missing-scopes";

//login.windows-ppe.net
//login.windows.net/

const commonAuthority = `https://login.microsoftonline.com/common`; //allows for anyone to register not just AAD accounts

// eslint-disable-next-line @typescript-eslint/no-unused-vars
//const tenantAuthority = `https://login.microsoftonline.com/${tenantAuthority}`; // allows ONLY for Other AAD accounts to register

const appAuthorityEMSWP = tenantAuthorityEMSWP; //to allow any user to sign up must choose commonAuthority
const appAuthorityPhysician = tenantAuthorityPhysician; 

// eslint-disable-next-line @typescript-eslint/no-unused-vars
var msalProviderPopupConfigEMSWP: MsalProviderPopupConfig = {
  type: ConfigType.Popup,
  msalConfig: {
    auth: {
      clientId: clientIdEMSWP,
      authority: appAuthorityEMSWP,
      knownAuthorities: [knownAuthorities],
      redirectUri: redirectUri,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  },
  silentRequestConfig: {
    scopes: [clientIdEMSWP, scopes],
    redirectUri: redirectUri
    
  },
  endSessionRequestConfig: {},
  loginRequestConfig : {
    scopes: [clientIdEMSWP, scopes],
    redirectUri: redirectUri
  },
};

var msalProviderPopupConfigPhysician: MsalProviderPopupConfig = {
  type: ConfigType.Popup,
  msalConfig: {
    auth: {
      clientId: clientIdEMSWP,
      authority: appAuthorityPhysician,
      knownAuthorities: [knownAuthorities],
      redirectUri: redirectUri,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  },
  silentRequestConfig: {
    scopes: [clientIdEMSWP, scopes],
    redirectUri: redirectUri
    
  },
  endSessionRequestConfig: {},
  loginRequestConfig : {
    scopes: [clientIdEMSWP, scopes],
    redirectUri: redirectUri
  },
};

var msalProviderRedirectConfig: MsalProviderRedirectConfig = {
  type: ConfigType.Redirect,
  msalConfig: {
    auth: {
      clientId: clientIdEMSWP,
      authority: commonAuthority,
      redirectUri: redirectUri,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  },
  silentRequestConfig: {
    scopes: [clientIdEMSWP, scopes],
  },
  endSessionRequestConfig: {},
  redirectRequestConfig: {
    scopes: [clientIdEMSWP, scopes],
  },
};

var msalProviderConfig: MsalProviderConfigMap = {
  type: ConfigType.Map,
  config: new Map<string, MsalProviderPopupConfig | MsalProviderRedirectConfig>(
    [
      ['EMSWPLogin', msalProviderPopupConfigEMSWP],
      ['PhysicianLogin', msalProviderPopupConfigPhysician]
    ]
  )
} 
//= msalProviderPopupConfig; //when using Facebook Login - cannot use pop-up, login UI doesn't render correctly.

export default msalProviderConfig;
