import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import moment from "moment";

export const getPhysicianName = (
  lastName: string | undefined | null,
  restOfName?: string | undefined | null
) => {
  if (!lastName && !restOfName) {
    return "<No name provided>";
  } else if (lastName && !restOfName) {
    return lastName;
  } else {
    return `${lastName}, ${restOfName}`;
  }
};

export const convertDate = (epochDate: string | number) => {
  if (epochDate !== undefined && epochDate !== null) {
    return moment(epochDate).utc().format(DATE_FORMAT);
  }
  return "";
};

export const convertDateFromTZTime = (tzTime: string | undefined) => {
  if (tzTime) {
    return moment(tzTime, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      .utc()
      .format("DD-MMM-YYYY");
  }

  return "";
};

export const formatMonths = (months: string) => {
  const monthDict: { [key: string]: string } = {
    NA: "N/A",
    "less-than-1-month": "Less than 1",
    "1-month": "1",
    "2-months": "2",
    "3-months": "3",
    "4-months": "4",
    "5-months": "5",
    "6-months": "6",
    "7-months": "7",
    "8-months": "8",
    "9-months": "9",
    "10-months": "10",
    "11-months": "11",
  };
  return monthDict[months] || "N/A";
};

export const LOGIN_TYPE = {
  EMSWPLogin: "EMSWPLogin",
  PhysicianLogin: "PhysicianLogin",
};

export const DATE_FORMAT = "DD-MMM-YYYY";

export const PHYSICIAN_ALLOWED_ACTIONS = {
  VIEW: "VIEW",
  REVIEW: "REVIEW",
  OPEN: "OPEN",
};

export const EvaluationCategory: Record<string, string> = {
  MEDICAL_INTERVIEWING: "Medical Interviewing Skills",
  PHYSICAL_EXAMINATION: "Physical Examination Skills",
  COMMUNICATION_PROFESSIONALISM: "Professionalism / Communication Skills",
  CLINICAL_REASONING: "Clinical Reasoning & Judgment",
};

export const MINICEX_REJECTION_REASONS = [
  {
    key: "INCORRECT_PHYSICIAN_NAME",
    value: "I am not the physician evaluator named here.",
  },
  { key: "RELATIVE", value: "I am a relative of this applicant." },
  {
    key: "PHYSICIAN_DOES_NOT_MEET_CRITERIA",
    value: `I do not meet the Criteria for Acceptable Physician Evaluators as outlined on the ECFMG website. `,
  },
  {
    key: "ENCOUNTER_DOES_NOT_MEET_CRITERIA",
    value: `I am unable to observe and evaluate this applicant in an encounter that meets the Criteria for Acceptable Clinical Encounters as outlined on the ECFMG website.`,
  },
  { key: "OTHER", value: "Other" },
];

export const MINICEX_REJECTION_REASON_KEYS = {
  INCORRECT_PHYSICIAN_NAME: "INCORRECT_PHYSICIAN_NAME",
  RELATIVE: "RELATIVE",
  PHYSICIAN_DOES_NOT_MEET_CRITERIA: "PHYSICIAN_DOES_NOT_MEET_CRITERIA",
  ENCOUNTER_DOES_NOT_MEET_CRITERIA: "ENCOUNTER_DOES_NOT_MEET_CRITERIA",
  OTHER: "OTHER",
};

export const EVALUATION_STATE = {
  ENCOUNTER: "ENCOUNTER",
  ATTESTATION: "ATTESTATION",
  EVALUATION: "EVALUATION",
  ADDITIONAL_INFO: "ADDITIONAL_INFO",
  REVIEW: "REVIEW",
};

export const MINICEX_QUESTION_NAMES_V1 = {
  MEDICAL_INTERVIEWING: "MEDICAL_INTERVIEWING",
  PHYSICAL_EXAMINATION: "PHYSICAL_EXAMINATION",
  COMMUNICATION_PROFESSIONALISM: "COMMUNICATION_PROFESSIONALISM",
  CLINICAL_REASONING: "CLINICAL_REASONING",
};

export const PROFILE_STATUS = {
  NOT_COMPLETED: "NOT_COMPLETED",
  COMPLETED: "COMPLETED",
};

export const SURVEY_QUESTION_CODES = {
  the_orientation_materials_were: "1001",
  the_mini_CEX_form_was_easy: "1002",
  i_was_able_to_identify: "1003",
  ecfmg_technology_worked: "1004",
  it_was_easy_to_schedule: "1005",
  how_many_minutes_did_it_take: "1006",
  additional_feedback_text: "1007",
  feedback_on_their_performance: "1101",
  technology_in_the_patient_room: "1102",
  conducted_in_english: "1103",
};

export const SURVEY_QUESTION_TEXT = {
  the_orientation_materials_were:
    "1.	The orientation materials (website instructions, portal instructions, and sample Mini-CEX evaluation) were useful.",
  the_mini_CEX_form_was_easy: "2.	The Mini-CEX was easy to use.",
  i_was_able_to_identify:
    "3.	I was easily able to identify an available patient.",
  ecfmg_technology_worked: "4.	ECFMG’s technology for this portal worked well.",
  it_was_easy_to_schedule:
    "5.	It was easy to schedule my patient and the applicant.",
  how_many_minutes_did_it_take:
    "6. How many minutes did it take to complete and submit the Mini-CEX evaluation through this portal?",
  additional_feedback_text: "7. Do you have any additional feedback?",
  feedback_on_their_performance:
    "1. Did you give the applicant feedback on his/her performance as part of the Mini-CEX process?",
  technology_in_the_patient_room:
    "2. Did you have access to a smart phone, tablet, computer, or other internet-based technology in the patient room during this encounter?",
  conducted_in_english: "3. Was the clinical encounter conducted in English?",
};

export const FAIMER_QUESTION_CODES = {
  does_the_mini_CEX_allow_you_to: "1001",
  prior_to_completing_the_ECFMG_mini_CEX: "1002",
};

export const FAIMER_QUESTION_DESCRIPTIONS = {
  question1:
    "Does ECFMG’s Mini-CEX format allow you to accurately assess a candidate’s clinical skills?",
  question2:
    "Prior to completing the ECMFG Mini-CEX, had you previously completed a Mini-CEX or similar clinical evaluation?",
};

export const CEX_STATUS = {
  MINICEX_CREATED_PHYSICIAN_INVITE_PENDING: "[n/a]",
  MINICEX_REJECTED_PHYSICIAN_INVITE_FAILED: "[n/a]",
  MINICEX_CREATED_PHYSICIAN_INVITE_SENT: "New",
  MINICEX_CREATED_PHYSICIAN_REGISTERED: "New",
  MINICEX_REJECTED_BY_PHYSICIAN: "Rejected",
  MINICEX_ACCEPTED: "In progress",
  MINICEX_COMPLETED: "Mini-CEX complete",
  MINICEX_EXPIRED: "Expired",
  MINICEX_WITHDRAWN: "Withdrawn by applicant",
  MINICEX_REJECTED_BY_SYSTEM: "Withdrawn",
  MINICEX_REJECTED_BY_STAFF: "Withdrawn",
  MINICEX_CANCELED_BY_STAFF: "Cancelled",
};

export const ACTIVE_MINICEX_STATUSES = [
  "MINICEX_CREATED_PHYSICIAN_INVITE_PENDING",
  "MINICEX_CREATED_PHYSICIAN_INVITE_SENT",
  "MINICEX_CREATED_PHYSICIAN_REGISTERED",
  "MINICEX_ACCEPTED",
];

// these won't have View button
export const WITHDRAW_STATUSES = [
  "MINICEX_WITHDRAWN",
  "MINICEX_REJECTED_BY_SYSTEM",
  "MINICEX_REJECTED_BY_STAFF",
];

export const OPT_OUT_REASON_MAPPING = [
  {
    key: "DO_NOT_MEET_CRITERIA",
    value: ` I do not meet the Criteria for Acceptable Physician Evaluators as outlined on
  the ECFMG website.`,
  },
  {
    key: "UNABLE_TO_OBSERVE",
    value: `I am unable to observe and evaluate applicants in encounters that meet the
  Criteria for Acceptable Clinical Encounters as outlined on the ECFMG website.`,
  },
  {
    key: "NOT_INTERESTED",
    value: `I am not interested in completing Mini-CEX evaluations for individuals
  applying to Pathway 6 to meet the clinical and communication skills
  requirements for ECFMG Certification.`,
  },
  { key: "OTHER", value: `Other` },
];

export enum OPT_OUT_REASON {
  DO_NOT_MEET_CRITERIA = "DO_NOT_MEET_CRITERIA",
  UNABLE_TO_OBSERVE = "UNABLE_TO_OBSERVE",
  NOT_INTERESTED = "NOT_INTERESTED",
  OTHER = "OTHER",
}

export const ACCOUNT_STATUS = {
  INVITED: "INVITED",
  REGISTERED: "REGISTERED",
  OPTED_OUT: "OPTED_OUT",
};

export const MiniCEXFormNavigateAwayConfirmMessage =
  "Your unsaved changes will be lost. Are you sure?";

export const confirmNavigatingAway = (onConfirm: () => void) => {
  Modal.confirm({
    title: "Unsaved Changes",
    // icon: <ExclamationCircleOutlined />,
    icon: null,
    content: MiniCEXFormNavigateAwayConfirmMessage,
    okText: "Confirm",
    cancelText: "Cancel",
    okButtonProps: { size: "large" },
    cancelButtonProps: { size: "large" },
    onOk: onConfirm,
  });
};

export const EncounterSettings = [
  { key: "IN-PATIENT", value: "Inpatient" },
  { key: "OUTPATIENT", value: "Outpatient" },
  { key: "EMERGENCY", value: "Emergency Department" },
  { key: "OTHER", value: "Other" },
];

export const MENU_KEYS = {
  LOGOUT: "Logout",
  INSTRUCTIONS: "Instructions",
  DASHBOARD: "Dashboard",
  PROFILE: "Profile",
};

export const PROGRESS_STATUS = {
  NOT_COMPLETED: "NOT_COMPLETED",
  COMPLETED: "COMPLETED",
  NOT_REQUIRED: "NOT_REQUIRED",
};

export const SECONDARY_EMAIL_MAX_COUNT = 10;

export const MEDICAL_SPECIALTIES = [
  "Allergy and Immunology",
  "Anatomic/Clinical Pathology",
  "Anesthesiology",
  "Cardiovascular Disease",
  "Child and Adolescent Psychiatry",
  "Critical Care Medicine",
  "Dermatology",
  "Emergency Medicine",
  "Endocrinology, Diabetes, and Metabolism",
  "Family Medicine",
  "Gastroenterology",
  "General Surgery",
  "Geriatric Medicine",
  "Hematology and Oncology",
  "Infectious Disease",
  "Internal Medicine",
  "Internal Medicine/ Pediatrics",
  "Interventional Cardiology",
  "Neonatal-Perinatal Medicine",
  "Nephrology",
  "Neurological Surgery",
  "Neurology",
  "Neuroradiology",
  "Obstetrics and Gynecology",
  "Ophthalmology",
  "Orthopedic Surgery",
  "Otolaryngology",
  "Pain Medicine and Pain Management",
  "Pediatric Anesthesiology",
  "Pediatric Cardiology",
  "Pediatric Critical Care Medicine",
  "Pediatric Hematology/Oncology",
  "Pediatrics",
  "Physical Medicine and Rehabilitation",
  "Plastic Surgery",
  "Preventive Medicine",
  "Psychiatry",
  "Pulmonary Disease",
  "Radiation Oncology",
  "Radiology and Diagnostic Radiology",
  "Rheumatology",
  "Sports Medicine",
  "Thoracic Surgery",
  "Urology",
  "Vascular and Interventional Radiology",
  "Vascular Surgery",
];
