import { FC, useState, useEffect } from "react";
import { Button, Table, Modal, Col, Row, Typography } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import ReadOnlyMiniCEXFactory from "../read-only-miniCEX-factory";
import {
  PHYSICIAN_ALLOWED_ACTIONS,
  CEX_STATUS,
  DATE_FORMAT,
  WITHDRAW_STATUSES,
  ACTIVE_MINICEX_STATUSES,
} from "../../common/constants";
import _ from "lodash";
import {
  PhysicianDashboardClosedTablePhysicianPortalDocument,
  MiniCexResponseType,
  MiniCexSummaryType,
  PhysicianResponseType,
} from "../../generated";
import MiniCEXPDFFactory from "../miniCEX-pdf-factory";
import ReviewRejectedMiniCEXFactory from "../physician-review-rejected-mini-CEX-factory";


const { Text, Paragraph } = Typography;

const ComponentPropTypes = {
  loading: PropTypes.bool.isRequired,
};

export interface IProps {
  __typename?: "PhysicianClosedEncounterTable";
  physician?: PhysicianResponseType;
  minicexList?: MiniCexSummaryType[];
  loading: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const PhysicianClosedEncounterTable: FC<ComponentProps> = ({
  minicexList,
  physician,
  loading,
}) => {
  const [loadingInitalData, setLoadingInitalData] = useState(loading);
  const [isReadOnlyMiniCEXVisible, setIsReadOnlyMiniCEXVisible] =
    useState(false);
  const [
    gqlGetMiniCEX,
    {
      called: getMiniCEXCalled,
      loading: getMiniCEXLoading,
      data: getMiniCEXData,
    },
  ] = useLazyQuery(PhysicianDashboardClosedTablePhysicianPortalDocument, {
    fetchPolicy: "network-only",
  });

  const [isViewReadOnlyMiniCEXClicked, setIsViewReadOnlyMiniCEXClicked] =
    useState(false);

  const [miniCEX, setMiniCEX] =
    useState<MiniCexResponseType | undefined>(undefined);
  const [stateModel, setstateModel] = useState<any>();
  const [isEncounterHistoryModalVisible, setIsEncounterHistoryModalVisible] =
    useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<any>();

  const [
    gqlGetMiniCEXForRejection,
    {
      called: getMiniCEXForRejectionCalled,
      loading: getMiniCEXForRejectionLoading,
      data: getMiniCEXForRejectionData,
    },
  ] = useLazyQuery(PhysicianDashboardClosedTablePhysicianPortalDocument, {
    fetchPolicy: "network-only",
  });
  const [isViewRejectedMiniCEXClicked, setIsViewRejectedMiniCEXClicked] =
    useState(false);
  const [isRejectionReviewVisible, setIsRejectionReviewVisible] =
    useState(false);
  const [miniCEXForRejection, setMiniCEXForRejection] =
    useState<MiniCexResponseType | undefined>(undefined);

  useEffect(() => {
    setLoadingInitalData(loading);
  }, [loading]);

  useEffect(() => {
    // /currentRecord?.stateModel
    var filteredStateModel = currentRecord?.stateModel.filter(
      (item: { state: string }) => item.state !== ACTIVE_MINICEX_STATUSES[0]
    ); //ACTIVE_MINICEX_STATUSES[0] = MINICEX_CREATED_PHYSICIAN_INVITE_PENDING
    setstateModel(filteredStateModel);
  }, [currentRecord]);

  useEffect(() => {
    handleViewReadOnlyMiniCEXClick();
  }, [getMiniCEXData]);

  useEffect(() => {
    handleViewReadOnlyMiniCEXClick();
  }, [isViewReadOnlyMiniCEXClicked]);

  const handleViewReadOnlyMiniCEXClick = () => {
    if (getMiniCEXData) {
      setMiniCEX(
        getMiniCEXData?.physicianPortal?.minicex as MiniCexResponseType
      );

      setIsReadOnlyMiniCEXVisible(true);
      setIsViewReadOnlyMiniCEXClicked(false);
    }
  };

  const viewReadOnlyMiniCEX = async (encounterId: string) => {
    gqlGetMiniCEX({ variables: { encounterId: encounterId } });
    setIsViewReadOnlyMiniCEXClicked(true);
  };

  // handle view rejection mini cex
  useEffect(() => {
    handleViewRejectionReviewClick();
  }, [isViewRejectedMiniCEXClicked]);

  useEffect(() => {
    handleViewRejectionReviewClick();
  }, [getMiniCEXForRejectionData]);

  const handleViewRejectionReviewClick = () => {
    if (getMiniCEXForRejectionData) {
      setMiniCEXForRejection(
        getMiniCEXForRejectionData?.physicianPortal
          ?.minicex as MiniCexResponseType
      );
      setIsRejectionReviewVisible(true);
      setIsViewRejectedMiniCEXClicked(false);
    }
  };

  const viewRejectionReview = async (encounterId: string) => {
    gqlGetMiniCEXForRejection({ variables: { encounterId: encounterId } });
    setIsViewRejectedMiniCEXClicked(true);
  };

  const columns = [
    {
      title: "Encounter ID",
      dataIndex: "encounterId",
      key: "encounterId",
    },
    {
      title: "USMLE/ECFMG ID",
      dataIndex: "usmleId",
      key: "usmleId",
    },
    {
      title: "Applicant Last Name",
      dataIndex: "applicantLastName",
      key: "applicantLastName",
    },
    {
      title: "Applicant Rest of Name",
      dataIndex: "applicantRestOfName",
      key: "applicantRestOfName",
    },

    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <Row wrap={false}>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <InfoCircleOutlined
                  style={{ fontSize: "18px" }}
                  onClick={() => {
                    setCurrentRecord(record);
                    setIsEncounterHistoryModalVisible(true);
                  }}
                />
              </Col>
              <Col offset={1}>{getStatus(CEX_STATUS, record.state)}</Col>
            </Row>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      title: "Date Requested",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any, record: any) => (
        <div>
          {record.createdAt ? (
            <Text>{moment(record.createdAt).utc().format(DATE_FORMAT)}</Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Last Action",
      dataIndex: "completedAt",
      key: "completedAt",
      render: (completedAt: any, record: any) => (
        <div>
          {record.completedAt ? (
            <Text>{moment(record.completedAt).utc().format(DATE_FORMAT)}</Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "allowedActions",
      key: "allowedActions",
      render: (allowedActions: any, record: any, index: any) =>
        renderButtons(record),
    },
  ];

  const historyColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <Text>{getStatus(CEX_STATUS, record.state) + " "}</Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any, record: any) => (
        <div>
          {record.timestamp !== undefined ? (
            <Text>
              {moment(record.timestamp)
                .utc()
                .format(`${DATE_FORMAT}, h:mm:ss a`)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
  ];

  const renderButtons = (record: any) => {
    if (
      record.allowedActions.includes(PHYSICIAN_ALLOWED_ACTIONS.VIEW) &&
      _.indexOf(WITHDRAW_STATUSES, record.state) === -1
    ) {
      return (
        <Button
          type="primary"
          style={{ width: "80px" }}
          className="ant-btn-left-align"
          onClick={async () => {
            if (record.rejectionReason) {
              let tempSeletedMiniCEX = _.find(minicexList, {
                encounterId: record.encounterId,
              });
              await viewRejectionReview(record.encounterId);
            } else {
              await viewReadOnlyMiniCEX(record.encounterId);
            }
          }}
          // loading={getMiniCEXSummaryLoading}
          icon={<SearchOutlined />}
        >
          View
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={minicexList as any}
        size="middle"
        loading={
          loadingInitalData ||
          (getMiniCEXLoading && getMiniCEXCalled) ||
          (getMiniCEXForRejectionLoading && getMiniCEXForRejectionCalled)
        }
      ></Table>
      <Modal
        destroyOnClose
        closable={false}
        title="Review Completed Mini-CEX"
        visible={isReadOnlyMiniCEXVisible}
        width={"70%"}
        onCancel={() => setIsReadOnlyMiniCEXVisible(false)}
        footer={[
          <Button
            size="large"
            onClick={() => setIsReadOnlyMiniCEXVisible(false)}
          >
            Close
          </Button>,
          miniCEX ? (
            <>
              <Button type="primary" size="large">
                <MiniCEXPDFFactory miniCEX={miniCEX} physician={physician} />
              </Button>
            </>
          ) : null,
        ]}
      >
        <ReadOnlyMiniCEXFactory
          physician={physician}
          minicexSummary={miniCEX as MiniCexResponseType}
        />
      </Modal>
      <Modal
        title="Status History"
        visible={isEncounterHistoryModalVisible}
        closable={false}
        footer={[
          <Button
            size="large"
            key="close"
            onClick={() => setIsEncounterHistoryModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div>
          Applicant:{" "}
          <Text
            strong
          >{`${currentRecord?.applicantLastName}, ${currentRecord?.applicantRestOfName}`}</Text>
          <br />
          Encounter ID: <Text strong>{currentRecord?.encounterId}</Text>
          <br />
          <br />
          <Table columns={historyColumns} dataSource={stateModel}></Table>
          <Paragraph>
            All times are calculated using Coordinated Universal Time (UTC).
          </Paragraph>
        </div>
      </Modal>

      <Modal
        title="Review Rejected Mini-CEX"
        visible={isRejectionReviewVisible}
        width={"70%"}
        onCancel={() => {
          setIsRejectionReviewVisible(false);
        }}
        destroyOnClose
        footer={[
          <Button
            size="large"
            onClick={() => setIsRejectionReviewVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <ReviewRejectedMiniCEXFactory
          miniCEX={miniCEXForRejection}
          physician={physician}
        />
      </Modal>
    </>
  );
};
export default PhysicianClosedEncounterTable;
