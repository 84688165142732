import { FC } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Card, List, Popconfirm, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { PhysicianLicenseRecordType } from "../generated";

const { Text, Paragraph } = Typography;

const ComponentPropTypes = {
  onListChangedCallback: PropTypes.func,
  isEditable: PropTypes.bool,
};

export interface IProps {
  licenses: PhysicianLicenseRecordType[];
  onListChangedCallback?: (newLicenses: PhysicianLicenseRecordType[]) => void;
  isEditable?: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const LicenseCardList: FC<ComponentProps> = (props) => {
  const onDeleteLicense = (
    physicianRegAuthorityRef: any,
    licenseNumber: any
  ) => {
    const newLicenses = props.licenses.slice();
    const i = newLicenses.findIndex(
      (l) =>
        l.physicianRegAuthorityRef === physicianRegAuthorityRef &&
        l.licenseNumber === licenseNumber
    );
    newLicenses.splice(i, 1);
    props.onListChangedCallback!(newLicenses);
  };
  return (
    <>
      <List
        grid={{
          gutter: 16,
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 6,
        }}
        dataSource={props.licenses}
        renderItem={(item) => (
          <List.Item>
            <Card style={{ border: "solid 1px black" }}>
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() =>
                  onDeleteLicense(
                    item.physicianRegAuthorityRef,
                    item.licenseNumber
                  )
                }
              >
                <CloseCircleOutlined
                  style={{
                    display: props.isEditable ? "inherit" : "none",
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    color: "#D11D5C",
                  }}
                />
              </Popconfirm>

              <div style={{ fontSize: "0.7rem" }}>
                <Text italic>Authority:</Text>
              </div>
              <div>
                <Text strong>{item.orgName}</Text>
              </div>
              <div style={{ fontSize: "0.7rem" }}>
                <Text italic>License/Registration Number:</Text>
              </div>
              <div>
                <Text strong>{item.licenseNumber}</Text>
              </div>
            </Card>
          </List.Item>
        )}
      />
    </>
  );
};

LicenseCardList.defaultProps = {
  licenses: [],
  onListChangedCallback: () => {
    // default function
  },
  isEditable: true,
};
