import { FC, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Form,
  Checkbox,
  Modal,
  Spin,
  message,
  Empty,
  DatePicker,
  Typography,
} from "antd";
import useForm from "antd/lib/form/hooks/useForm";
import { GroupBox } from "../common/group-box";
import { LicenseCardList } from "./physician-license-card-list";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import AddEmailForm from "./secondary-email-list/add-email-form";
import EmailList from "./secondary-email-list/email-list";
import { Helmet } from "react-helmet";
import {
  DATE_FORMAT,
  SECONDARY_EMAIL_MAX_COUNT,
  MEDICAL_SPECIALTIES,
} from "../common/constants";
import {
  PhysicianProfileManagementCountryListDocument,
  PhysicianProfileManagementPhysicianRegAuthorityDocument,
  PhysicianProfileManagementPhysicianPortalDocument,
  PhysicianProfileManagementSetProfileDocument,
  CountryResponseType,
  RegulatoryAuthorityResponseType,
  PhysicianLicenseRecordType,
  PhysicianResponseType,
  PhysicianLicenseInput,
} from "../generated";
import dayjs from "dayjs"; //Use this instead of moment

const { Option } = Select;
const { Title, Paragraph, Text, Link } = Typography;

let FORM_FIELDS_NAMES = {
  lastName: "lastName",
  restOfName: "restOfName",
  authorityCountry: "authorityCountry",
  authority: "authority",
  licenseNumber: "licenseNumber",
  attestation: "attestation",
  certification: "certification",
  dateOfBirth: "dateOfBirth",
  usmleId: "usmleId",
  isHoldingFacultyAppointment: "isHoldingFacultyAppointment",
  medicalSpecialty: "medicalSpecialty",
};

const ProfileManagement: FC<any> = (props) => {
  // states
  const [dataVersion, setDataVersion] = useState<number>(-1);
  const [countries, setCountries] = useState<CountryResponseType[]>();
  const [authorities, setAuthorities] = useState<
    RegulatoryAuthorityResponseType[]
  >([]);
  const [licenses, setLicenses] = useState<PhysicianLicenseRecordType[]>([]);
  const [email, setEmail] = useState("");
  const [newAuthorityCountryCode, setNewAuthorityCountryCode] = useState("");
  const [newCountryName, setNewCountryName] = useState("");
  const [newAuthorityRef, setNewAuthorityRef] =
    useState<number | undefined>(undefined);
  const [lastName, setLastName] = useState<string>("");
  const [restOfName, setRestOfName] = useState<string>("");
  const [newLicenseNumber, setNewLicenseNumber] = useState("");
  const [attested, setAttested] = useState(false);
  const [certified, setCertified] = useState(false);
  const [hasOnlyOneName, setHasOnlyOneName] = useState(false);
  const [usmleId, setUsmleId] = useState("");
  const [secondaryEmails, setSecondaryEmails] = useState<string[]>([]);
  const [secondaryEmailMaxCount] = useState(SECONDARY_EMAIL_MAX_COUNT);

  const [form] = useForm();
  const [physicianDetails, setPhysicianDetails] =
    useState<PhysicianResponseType | undefined>(undefined);
  const [profileJustUpdated, setProfileJustUpdated] = useState(false);
  const screens = useBreakpoint();

  // graphql
  const {
    data: physicianDetailsData,
    loading: physicianDetailsLoading,
    error: physicianDetailsError,
  } = useQuery(PhysicianProfileManagementPhysicianPortalDocument);

  const [
    gqlGetCountryList,
    { data: countryListData, loading: countryListLoading },
  ] = useLazyQuery(PhysicianProfileManagementCountryListDocument);

  const [
    gqlGetAuthorityList,
    {
      called: getAuthorityListCalled,
      loading: getAuthorityListLoading,
      data: getAuthorityListData,
    },
  ] = useLazyQuery(PhysicianProfileManagementPhysicianRegAuthorityDocument);

  const [gqlUpdateProfile, { loading: updateProfileLoading }] = useMutation(
    PhysicianProfileManagementSetProfileDocument,
    {
      refetchQueries: [
        { query: PhysicianProfileManagementPhysicianPortalDocument },
      ],
    }
  );

  const FACULTY_APPOINTMENT = ["Yes", "No"];

  // observers
  useEffect(() => {
    if (countryListData) {
      setCountries(countryListData?.country as CountryResponseType[]);
    }
    return () => {
      setCountries([]);
    };
  }, [countryListData]);

  useEffect(() => {
    if (physicianDetailsData) {
      let gqlPhysicianDetailsData = JSON.parse(
        JSON.stringify(physicianDetailsData?.physicianPortal?.physician)
      ) as PhysicianResponseType;
      setPhysicianDetails(gqlPhysicianDetailsData);
      updateUI(gqlPhysicianDetailsData);
      gqlGetCountryList();
    }
  }, [physicianDetailsData]);

  useEffect(() => {
    if (getAuthorityListData) {
      setAuthorities(
        getAuthorityListData.physicianRegAuthority as unknown as RegulatoryAuthorityResponseType[]
      );
    }
    return () => {
      setAuthorities([]);
    };
  }, [getAuthorityListData]);

  const updateUI = (details: PhysicianResponseType) => {
    console.log(details);
    if (details?.profile?.lastName && !details.profile.restOfName) {
      setHasOnlyOneName(true);
    } else {
      setHasOnlyOneName(false);
    }
    setLastName(details?.profile?.lastName ?? "");
    setRestOfName(details?.profile?.restOfName ?? "");
    form.setFieldsValue({
      [FORM_FIELDS_NAMES.medicalSpecialty]:
        details?.profile?.medicalSpecialty ?? "",
    });
    // form.setFieldsValue({
    //   [FORM_FIELDS_NAMES.dateOfBirth]: details?.profile?.dateOfBirth ?? "",
    // });
    setUsmleId(details?.profile?.usmleId ?? "");
    form.setFieldsValue({
      [FORM_FIELDS_NAMES.isHoldingFacultyAppointment]:
        details.profile?.isHoldingFacultyAppointment ?? "",
    });
    setSecondaryEmails((details.profile?.secondaryEmails as string[]) ?? []);

    setEmail(details?.email ?? "");
    setDataVersion(details?.dataVersion!);

    if (details?.profile?.licenses) {
      setLicenses(details.profile.licenses as PhysicianLicenseRecordType[]);
    }
  };

  if (physicianDetailsLoading) {
    return (
      <div
        style={{ textAlign: "center", margin: "80px 40px" }}
        className="site-layout-background "
      >
        <Spin size="large" />
        <Title level={2}>Loading...</Title>
      </div>
    );
  }

  if (physicianDetailsError) {
    return (
      <div
        style={{ textAlign: "center", margin: "80px 40px" }}
        className="site-layout-background "
      >
        <Title level={2}>Error while getting data</Title>
      </div>
    );
  }

  const onHasOnlyOneNameChanged = (e: any) => {
    setHasOnlyOneName(e.target.checked);

    if (e.target.checked) {
      if (form) {
        form.resetFields([FORM_FIELDS_NAMES.restOfName]);
      }
    }
  };

  const hasFullName = () => {
    if (
      physicianDetails?.profile?.lastName &&
      physicianDetails?.profile.restOfName
    ) {
      return true;
    }
    return false;
  };

  const getPersonalInfoArea = () => {
    const lastNameArea = () => {
      if (hasFullName()) {
        return (
          <>
            <div>
              <Text style={{ color: "#333333" }} strong>
                Name:
              </Text>
            </div>
            <div>{`${lastName}, ${restOfName}`}</div>
          </>
        );
      }
      if (physicianDetails?.profile?.lastName) {
        return (
          <>
            <div>
              <Text style={{ color: "#333333" }} strong>
                Name:
              </Text>
            </div>
            <div>{lastName}</div>
          </>
        );
      }

      return (
        <>
          <Form.Item
            name={FORM_FIELDS_NAMES.lastName}
            rules={[
              {
                required: true,
                message: "Please enter your last name",
              },
            ]}
            label={<Text strong>Last Name:</Text>}
            style={{ maxWidth: "200px" }}
            initialValue={lastName}
          >
            <Input value={lastName} onChange={(e) => onLastnameChanged(e)} />
          </Form.Item>
        </>
      );
    };

    const onAddEmail = (email: string) => {
      if (email === undefined || email.trim() !== "") {
        setSecondaryEmails((secondaryEmails) => [...secondaryEmails, email]);
      }
    };

    const onRemoveEmail = (email: string) => {
      setSecondaryEmails((secondaryEmails) =>
        secondaryEmails.filter((e) => e !== email)
      );
    };

    const restOfNameArea = physicianDetails?.profile?.restOfName ? (
      <></>
    ) : (
      <>
        <Form.Item
          name={FORM_FIELDS_NAMES.restOfName}
          rules={[
            {
              required: !hasOnlyOneName,
              message: "Please enter your first name",
            },
          ]}
          label={<Text strong>Rest of Name:</Text>}
          style={{
            display: hasOnlyOneName ? "none" : "block",
            maxWidth: "200px",
          }}
        >
          <Input value={restOfName} onChange={(e) => onRestOfNameChanged(e)} />
        </Form.Item>
      </>
    );
    return (
      <>
        <div
          style={{
            display: physicianDetails?.profile?.lastName ? "none" : "block",
          }}
        >
          <Checkbox
            checked={hasOnlyOneName}
            onChange={(e) => onHasOnlyOneNameChanged(e)}
          >
            <Text>My name consists of one name only.</Text>
          </Checkbox>
        </div>
        <br></br>

        <Row>
          <Col
            xs={24}
            sm={24}
            md={{ span: 5, order: 1 }}
            lg={{ span: 5, order: 1 }}
            xl={{ span: 5, order: 1 }}
            xxl={{ span: 5, order: 1 }}
          >
            {lastNameArea()}
          </Col>
          <Col
            xs={{ span: 24, order: 3 }}
            sm={{ span: 24, order: 3 }}
            md={{ span: 5, order: 2 }}
            lg={{ span: 5, order: 2 }}
            xl={{ span: 5, order: 2 }}
            xxl={{ span: 5, order: 2 }}
          >
            <div>
              <Text style={{ color: "#333333" }} strong>
                Primary E-mail Address:
              </Text>
            </div>
            <div
              style={{
                paddingTop: !physicianDetails?.profile?.lastName ? "10px" : "0",
              }}
            >
              {email}
            </div>
          </Col>
          <Col
            xs={{ span: 24, order: 4 }}
            sm={{ span: 24, order: 4 }}
            md={{ span: 4, order: 3 }}
            lg={{ span: 4, order: 3 }}
            xl={{ span: 4, order: 3 }}
            xxl={{ span: 4, order: 3 }}
          >
            {physicianDetails?.profile?.dateOfBirth ? (
              <div>
                <div>
                  <Text style={{ color: "#333333" }} strong>
                    Date of Birth:
                  </Text>
                </div>
                {dayjs(physicianDetails?.profile?.dateOfBirth).format(
                  DATE_FORMAT
                )}
              </div>
            ) : (
              <Form.Item
                name={FORM_FIELDS_NAMES.dateOfBirth}
                initialValue={physicianDetails?.profile?.dateOfBirth}
                label={<Text strong>Date of Birth:</Text>}
                rules={[
                  {
                    required: true,
                    message: "Please enter your date of birth",
                  },
                ]}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  disabledDate={(currentDate: any) => {
                    return currentDate && currentDate.valueOf() > Date.now();
                  }}
                ></DatePicker>
              </Form.Item>
            )}
          </Col>
          <Col
            xs={{ span: 24, order: 5 }}
            sm={{ span: 24, order: 5 }}
            md={{ span: 6, order: 4 }}
            lg={{ span: 6, order: 4 }}
            xl={{ span: 6, order: 4 }}
            xxl={{ span: 6, order: 4 }}
          >
            <Form.Item
              name={FORM_FIELDS_NAMES.medicalSpecialty}
              label={<Text strong>Medical Specialty:</Text>}
            >
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                style={{
                  minWidth: "100px",
                  maxWidth: "260px",
                  width: "initial",
                }}
              >
                {MEDICAL_SPECIALTIES.map((specialty: string) => (
                  <Option value={specialty}>{specialty}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24, order: 5 }}
            sm={{ span: 24, order: 5 }}
            md={{ span: 4, order: 5 }}
            lg={{ span: 4, order: 5 }}
            xl={{ span: 4, order: 5 }}
            xxl={{ span: 4, order: 5 }}
          >
            <Form.Item label={<Text strong>USMLE/ECFMG ID:</Text>}>
              <Input
                value={usmleId}
                defaultValue={usmleId}
                maxLength={8}
                onChange={(e) => onIdChange(e.target.value)}
                style={{ maxWidth: "200px" }}
              ></Input>
            </Form.Item>
          </Col>
          <Col
            style={{ display: hasOnlyOneName ? "none" : "block" }}
            xs={24}
            sm={24}
            md={{ span: 8, order: 6 }}
            lg={{ span: 8, order: 6 }}
            xl={{ span: 8, order: 6 }}
            xxl={{ span: 8, order: 6 }}
          >
            {restOfNameArea}
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
            xxl={{ span: 12, order: 1 }}
          >
            <Form.Item
              name={FORM_FIELDS_NAMES.isHoldingFacultyAppointment}
              label={
                <div>
                  <Text strong>
                    Do you hold a faculty appointment at a medical school?
                    &nbsp;&nbsp;
                  </Text>
                </div>
              }
              rules={[
                {
                  required:
                    physicianDetailsData?.physicianPortal?.physician?.profile
                      ?.isHoldingFacultyAppointment === null
                      ? true
                      : false,
                  message:
                    "Please indicate if you currently hold a faculty appointment",
                },
              ]}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Select style={{ width: "75px" }}>
                {FACULTY_APPOINTMENT.map((option: string) => (
                  <Option value={option}>{option}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 9, order: 1 }}
            xl={{ span: 9, order: 1 }}
            xxl={{ span: 9, order: 1 }}
          >
            <AddEmailForm
              onAddEmail={onAddEmail}
              disableAddEmailButton={
                secondaryEmails.length >= secondaryEmailMaxCount
              }
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 6, order: 1 }}
            xxl={{ span: 6, order: 1 }}
          >
            {secondaryEmails.length > 0 ? (
              <EmailList
                secondaryEmails={secondaryEmails as string[]}
                onRemoveEmail={onRemoveEmail}
              />
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
      </>
    );
  };
  const onLastnameChanged = (e: any) => {
    setLastName(e.target.value);
  };
  const onRestOfNameChanged = (e: any) => {
    setRestOfName(e.target.value);
  };

  const onLicensesChanged = (newLicenses: PhysicianLicenseRecordType[]) => {
    setProfileJustUpdated(false);

    setLicenses(newLicenses);
  };

  const onProfileSaved = (formValues: any) => {
    // clear error message on country and licence number
    if (newAuthorityCountryCode) {
      if (newAuthorityRef === undefined) {
        form.resetFields([FORM_FIELDS_NAMES.authority]);
      }
      if (!newLicenseNumber) {
        form.resetFields([FORM_FIELDS_NAMES.licenseNumber]);
      }
    }

    form
      .validateFields([
        FORM_FIELDS_NAMES.lastName,
        FORM_FIELDS_NAMES.restOfName,
        FORM_FIELDS_NAMES.attestation,
        FORM_FIELDS_NAMES.certification,
        FORM_FIELDS_NAMES.dateOfBirth,
        FORM_FIELDS_NAMES.isHoldingFacultyAppointment,
      ])
      .then(async (_) => {
        //remove __typename field
        let inputLicenses = licenses.slice();
        inputLicenses.forEach((li: any) => delete li.__typename);
        setProfileJustUpdated(false);
        await gqlUpdateProfile({
          variables: {
            dataVersion: dataVersion,
            input: {
              restOfName: restOfName,
              lastName: lastName,
              medicalSpecialty: formValues.medicalSpecialty,
              dateOfBirth: formValues.dateOfBirth
                ? dayjs(formValues.dateOfBirth).format("YYYY-MM-DD")
                : physicianDetailsData?.physicianPortal?.physician?.profile
                    ?.dateOfBirth,
              usmleId: usmleId,
              isHoldingFacultyAppointment:
                formValues.isHoldingFacultyAppointment,
              secondaryEmails: secondaryEmails,
              licenses: inputLicenses as PhysicianLicenseInput[],
            },
          },
        })
          .then((data: any) => {
            if (data?.data?.PhysicianPortal_Mgmt_setProfile) {
              setDataVersion(
                data?.data?.PhysicianPortal_Mgmt_setProfile?.dataVersion!
              );
              updateUI(data?.data?.PhysicianPortal_Mgmt_setProfile);
              setPhysicianDetails(data?.data?.PhysicianPortal_Mgmt_setProfile);
              setProfileJustUpdated(true);
              message.success("Profile saved successfully!");
              clearLicenseInfoInputs();
            } else {
              message.error("Error updating profile.");
            }
          })
          .catch((error) => {
            const graphQLErrorReference =
              error?.graphQLErrors[0]?.extensions?.referenceId || "";
            message.error(
              `${error.toString()} - Reference ID : ${graphQLErrorReference}`
            );
          });
      })
      .catch(() => {
        message.error("Required field(s) missing");
      });
  };

  const getLicenseListArea = () => {
    if (licenses && licenses.length > 0) {
      return (
        <LicenseCardList
          licenses={licenses}
          onListChangedCallback={onLicensesChanged}
          isEditable={true}
        />
      );
    }
    if (profileJustUpdated) {
      return (
        <Paragraph
          style={{
            backgroundColor: "blanchedalmond",
            padding: "5px",
          }}
        >
          Your profile is not complete. Please enter all the required
          information. If you do not meet the criteria for a physician
          evaluator, you may opt out of the process using the link below.
        </Paragraph>
      );
    }
  };

  const onAuthorityCountryChanged = async (e: any) => {
    form.resetFields([FORM_FIELDS_NAMES.authority]);
    setNewAuthorityCountryCode(e);
    const countryName = countries?.find((c) => c._id === e)?.countryName;
    setNewCountryName(countryName ?? "");
    gqlGetAuthorityList({ variables: { input: { countryCode: e } } });
  };

  const onAuthorityChanged = (e: any) => {
    setNewAuthorityRef(e);
  };

  const onLicenseNumberChanged = (e: any) => {
    setNewLicenseNumber(e.target.value);
  };

  const clearLicenseInfoInputs = () => {
    setNewAuthorityCountryCode("");
    setNewAuthorityRef(undefined);
    setNewLicenseNumber("");
    form.resetFields([
      FORM_FIELDS_NAMES.authorityCountry,
      FORM_FIELDS_NAMES.authority,
      FORM_FIELDS_NAMES.licenseNumber,
    ]);
  };

  const onLicenseAdded = () => {
    form
      .validateFields([
        FORM_FIELDS_NAMES.authority,
        FORM_FIELDS_NAMES.licenseNumber,
      ])
      .then((_: any) => {
        if (licenses && licenses.length > 0) {
          const license = licenses.find(
            (l: PhysicianLicenseRecordType) =>
              l.licenseNumber === newLicenseNumber &&
              l.physicianRegAuthorityRef === newAuthorityRef
          );
          if (license) {
            Modal.error({
              title: "License Exists",
              content: "You have already added this license.",
            });
            return;
          }
        }

        const authName = authorities.find(
          (li) => li._id === newAuthorityRef
        )!.orgName;

        let newLicense = {
          physicianRegAuthorityRef: newAuthorityRef!,
          orgName: authName,
          countryName: newCountryName,
          licenseNumber: newLicenseNumber,
        };
        setLicenses([...licenses.slice(), newLicense]);
        clearLicenseInfoInputs();
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const onCertificationChecked = (e: any) => {
    setCertified(e.target.checked);
  };

  const onAttestationChecked = (e: any) => {
    setAttested(e.target.checked);
  };

  const onIdChange = (e: any) => {
    const numericOnlyId = e.replace(/\D/g, "").trim();
    setUsmleId(numericOnlyId);
  };

  return (
    <>
      <Helmet>
        <title>Profile Management</title>
      </Helmet>
      <div style={{ width: "100%" }} className="site-layout-background">
        <Form
          form={form}
          name="profile"
          // onFinish={onProfileSaved}
          layout="vertical"
          onFinishFailed={(errorInfo) => {
            console.log(errorInfo);
            let optionalFieldNames = [
              FORM_FIELDS_NAMES.authority,
              FORM_FIELDS_NAMES.licenseNumber,
              FORM_FIELDS_NAMES.authorityCountry,
            ];
            let requiredFieldErrors = errorInfo.errorFields.filter(
              (e) =>
                e.errors.length > 0 &&
                !optionalFieldNames.includes(e.name.toString())
            );

            // clear errors of optionalFields
            setNewAuthorityRef(undefined);
            setNewLicenseNumber("");

            form.resetFields([
              FORM_FIELDS_NAMES.authority,
              FORM_FIELDS_NAMES.licenseNumber,
              FORM_FIELDS_NAMES.authorityCountry,
            ]);
            if (requiredFieldErrors.length === 0) {
              onProfileSaved(errorInfo.values);
            }
          }}
        >
          <Title level={3}>Profile</Title>
          <br />
          {/* Evaluator Information */}
          <GroupBox title="Evaluator Information">
            <div style={{ marginTop: screens.xs ? "initial" : "10px" }}>
              <Paragraph>
                To create your profile, please:
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Confirm that the primary e-mail address listed is the e-mail
                    address you wish to use to access this portal. You may have
                    only one account in the portal, and all e-mail
                    communications regarding your participation in the Pathway 6
                    evaluation process will be sent to the primary e-mail
                    address. The primary e-mail address cannot be edited. If
                    this is not the address you wish to use, please{" "}
                    <Link
                      onClick={() => {
                        document.getElementById("optOut")?.scrollIntoView();
                      }}
                    >
                      opt out
                    </Link>{" "}
                    using the link below and have the applicant(s) send a new
                    request using the correct e-mail address.
                  </li>
                  <li>
                    Provide your name as it appears on your license/registration
                    to practice medicine. If your name consists of one name
                    only, check the box below to indicate this, and enter your
                    name in the Last Name field. Do not enter any characters in
                    the Rest of Name field.
                  </li>
                  <li>Provide your date of birth.</li>
                  <li>Provide your USMLE/ECFMG ID, if you have one.</li>
                  <li>
                    Indicate whether you hold a faculty appointment at a medical
                    school.
                  </li>
                  <li>
                    Enter any additional e-mail addresses you wish to share with
                    ECFMG, but please note that you must use the primary e-mail
                    address to access this portal.
                  </li>
                </ul>
                Before clicking Save below, please confirm that all information
                is correct. Once you establish your profile, you cannot edit
                your name or date of birth. If you require an update to your
                information, please contact ECFMG at{" "}
                <Link href="mailto:casemanager@ecfmg.org">
                  casemanager@ecfmg.org
                </Link>{" "}
                for assistance.
              </Paragraph>

              {getPersonalInfoArea()}
              <br />
            </div>
          </GroupBox>

          <br />
          {/* Licensure Information */}
          <GroupBox title="Licensure Information">
            <div style={{ marginTop: screens.xs ? "initial" : "10px" }}>
              <Paragraph>
                To serve as a physician evaluator, you must currently hold a
                full, unrestricted license to practice medicine without
                supervision, and you must have held a full, unrestricted license
                to practice medicine without supervision for a minimum of five
                years. If you do not meet these criteria, you are ineligible to
                serve as a physician evaluator, and you may{" "}
                <Link
                  onClick={() => {
                    document.getElementById("optOut")?.scrollIntoView();
                  }}
                >
                  opt out
                </Link>{" "}
                of this process using the link below. If you do meet these
                criteria, please add the authority with which you are currently
                licensed and your license/registration number below. You may add
                additional licenses if you currently hold more than one license.
              </Paragraph>
              <Paragraph>
                <Text strong>Note:</Text> The clinical encounters you observe
                for the purpose of evaluating applicants to Pathway 6 must take
                place in the jurisdiction(s) where you are licensed.
              </Paragraph>
              <Row align="middle">
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 13 }}
                  lg={{ span: 10 }}
                >
                  <Text style={{ color: "#333333" }} strong>
                    Country:
                  </Text>
                  <Form.Item
                    name={FORM_FIELDS_NAMES.authorityCountry}
                    rules={[
                      {
                        required: true,
                        message: "Please select a country",
                      },
                    ]}
                  >
                    {!!countries ? (
                      <Select
                        showSearch
                        placeholder="Select a country"
                        style={{
                          minWidth: "150px",
                          maxWidth: "360px",
                          width: "initial",
                          fontSize: screens.xs ? "0.7rem" : "14px",
                        }}
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option?.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA: any, optionB: any) =>
                          optionA.children
                            .toString()
                            .toLowerCase()
                            .localeCompare(
                              optionB.children.toString().toLowerCase()
                            )
                        }
                        value={newAuthorityCountryCode}
                        onChange={(e: any) => onAuthorityCountryChanged(e)}
                        loading={countryListLoading}
                        disabled={countryListLoading}
                        dropdownMatchSelectWidth={false}
                      >
                        {countries.map((country: CountryResponseType) => (
                          <Option key={country._id!} value={country._id!}>
                            {country.countryName}
                          </Option>
                        ))}
                      </Select>
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{ display: newAuthorityCountryCode ? "block" : "none" }}
              >
                <Row align="middle">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 13 }}
                    lg={{ span: 10 }}
                  >
                    <Text style={{ color: "#333333" }} strong>
                      Authority with which you are currently licensed:
                    </Text>
                    <Form.Item
                      name={FORM_FIELDS_NAMES.authority}
                      rules={[
                        {
                          required: true,
                          message: "Please select an authority",
                        },
                      ]}
                    >
                      {!!authorities ? (
                        <Select
                          showSearch
                          placeholder={
                            authorities.length > 0
                              ? "Select an option"
                              : "No authority found"
                          }
                          style={{ minWidth: "150px" }}
                          optionFilterProp="children"
                          filterOption={(input: any, option: any) =>
                            option?.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA: any, optionB: any) =>
                            optionA.children
                              .toString()
                              .toLowerCase()
                              .localeCompare(
                                optionB.children.toString().toLowerCase()
                              )
                          }
                          value={newAuthorityRef}
                          onChange={(e: any) => onAuthorityChanged(e)}
                          notFoundContent={
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description={<div>No authority found</div>}
                            />
                          }
                          loading={
                            getAuthorityListCalled && getAuthorityListLoading
                          }
                        >
                          {authorities.map(
                            (auth: RegulatoryAuthorityResponseType) => (
                              <Option key={auth._id!} value={auth._id!}>
                                {auth.orgName}
                              </Option>
                            )
                          )}
                        </Select>
                      ) : null}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 10, offset: 1 }}
                    lg={{ span: 7, offset: 1 }}
                  >
                    <Text style={{ color: "#333333" }} strong>
                      License/Registration Number:
                    </Text>
                    <Form.Item
                      name={FORM_FIELDS_NAMES.licenseNumber}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a license number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter license number"
                        value={newLicenseNumber}
                        onChange={(e) => onLicenseNumberChanged(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    lg={{ offset: 1, span: 2 }}
                    xl={{ span: 2 }}
                  >
                    <Button
                      // type="primary"
                      onClick={(e) => onLicenseAdded()}
                      style={{ width: "100%" }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: "15px" }}>{getLicenseListArea()}</div>
            </div>
          </GroupBox>
          <br />

          <Form.Item
            name={FORM_FIELDS_NAMES.certification}
            rules={[
              {
                required: true,
                transform: (value) => value || undefined,
                type: "boolean",
                message: "Please certify",
              },
            ]}
            valuePropName="checked"
            initialValue={certified}
          >
            <Checkbox
              checked={certified}
              onChange={(e) => onCertificationChecked(e)}
            >
              By checking this box, I certify that I have reviewed the{" "}
              <Link
                href="https://www.intealth.org/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                Intealth Privacy Notice
              </Link>
              . I accept the terms of the Intealth Privacy Notice and consent to
              the collection and processing of my personal information to assist
              ECFMG, a division of Intealth, in making determinations of
              applicant eligibility for ECFMG Certification.
            </Checkbox>
          </Form.Item>

          <Form.Item
            name={FORM_FIELDS_NAMES.attestation}
            rules={[
              {
                required: true,
                transform: (value) => value || undefined,
                type: "boolean",
                message: "Please attest",
              },
            ]}
            valuePropName="checked"
            initialValue={attested}
          >
            <Checkbox
              checked={attested}
              onChange={(e) => onAttestationChecked(e)}
            >
              By checking this box, I certify that the information about me is
              true and correct to the best of my knowledge. I consent to
              participate in this Mini-CEX evaluation process and for ECFMG to
              use my contact information for the purpose of contacting me about
              this process.
            </Checkbox>
          </Form.Item>

          <Row justify="end">
            <Col>
              <Form.Item name={"saveButton"}>
                <Button
                  size="large"
                  type="primary"
                  // htmlType="submit"
                  onClick={() => onProfileSaved(form.getFieldsValue())}
                  loading={updateProfileLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <br />
          {/* Opt-out */}
          <div id="optOut">
            <GroupBox title="Opt Out">
              <div style={{ marginTop: screens.xs ? "initial" : "10px" }}>
                <Text>
                  If you do not wish to serve as a physician evaluator for
                  applicants to Pathway 6, or if you do not meet the{" "}
                  <Link
                    target="_bank"
                    href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#physician-evals"
                    rel="noreferrer"
                  >
                    {" "}
                    Criteria for Acceptable Physician Evaluators
                  </Link>{" "}
                  , you can <Link href="opt-out">opt out</Link> of this process.
                </Text>
              </div>
            </GroupBox>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ProfileManagement;
