import { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  List,
  Spin,
  Card,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { useQuery } from "@apollo/client/react";
import moment from "moment";
import { AuthorityAttestationDashboardGetAttestationRecordsDocument } from "../../generated";
import { downloadCSV } from "./download-attestation-csv";
import {
  DownloadOutlined,
  SearchOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const { Title, Paragraph, Text } = Typography;

const AttestationDashboardList: FC<any> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState<string>("All");

  const onReviewClick = (record: any) => {
    navigate(
      `${location.pathname}/detail/${record.usmleId}`,
      { state: { menuItemSelected: "" } } //No menu item for this route
    );
  };

  function AuthorityDashboard() {
    const { loading, error, data } = useQuery(
      AuthorityAttestationDashboardGetAttestationRecordsDocument,
      {
        variables: { attestationOutput: statusFilter, usmleId: null },
      }
    );

    if (loading) {
      return (
        <Paragraph style={{ textAlign: "center", margin: "80px 40px" }}>
          <Spin size="large" />
          <Title level={2}>Loading...</Title>
        </Paragraph>
      );
    }
    if (error) {
      return <Paragraph type="danger">{error.message}</Paragraph>;
    }

    const listData: any = [];

    data?.authorityPortal?.attestationRecords?.map((record: any) => {
      const status = record?.attestationOutput
        ? record?.attestationOutput === "Attested"
          ? "Certified"
          : record?.attestationOutput
        : "New";

      listData.push({
        title: `${record.firstName} ${record.lastName} - ${record.usmleId}`,
        dateOfBirth: `${moment(record.dateOfBirth).format("MM/DD/YYYY")}`,
        dateRequested: `${moment(record.requestTimestamp).format(
          "MM/DD/YYYY"
        )}`,
        status: status,
        dateCompleted: record.completeTimestamp
          ? moment(record.completeTimestamp).format("MM/DD/YYYY")
          : `N/A`,
        usmleId: `${record.usmleId}`,
      });
    });

    return (
      <>
        <Space>
          <Paragraph>
            Filter by Status:{" "}
            <Select
              value={statusFilter}
              onChange={(event) => {
                onStatusChange(event);
              }}
              style={{ width: "100px" }}
            >
              <Option key="All" value="All">
                All
              </Option>
              <Option key="New" value="New">
                New
              </Option>
              <Option key="Certified" value="Attested">
                Certified
              </Option>
              <Option key="Rejected" value="Rejected">
                Rejected
              </Option>
            </Select>
          </Paragraph>
          <Paragraph>
            <Tooltip title="Download Result">
              <Button
                onClick={() =>
                  downloadCSV(
                    data?.authorityPortal?.attestationRecords,
                    statusFilter
                  )
                }
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>
          </Paragraph>
        </Space>
        <br />
        <List
          dataSource={listData}
          itemLayout="vertical"
          pagination={{ pageSize: 10 }}
          renderItem={(item: any) => (
            <List.Item key={item.title}>
              <Card title={item.title}>
                <Paragraph>
                  Status:<Text strong>{item?.status}</Text>
                </Paragraph>
                <Paragraph>
                  Date of Birth: <Text strong>{item?.dateOfBirth}</Text>
                </Paragraph>
                <Paragraph>
                  Date Requested: <Text strong>{item?.dateRequested}</Text>
                </Paragraph>
                <Paragraph>
                  Date Completed: <Text strong>{item?.dateCompleted}</Text>
                </Paragraph>
                <br />
                <Button
                  type="primary"
                  onClick={() => {
                    onReviewClick(item);
                  }}
                  style={{width: '100px'}}
                  icon={
                    item.status !== "New" ? (
                      <SearchOutlined />
                    ) : (
                      <SolutionOutlined />
                    )
                  }
                >
                  {item.status !== "New" ? "View" : "Review"}
                </Button>
              </Card>
            </List.Item>
          )}
        />
      </>
    );
  }

  const onStatusChange = (e: any) => {
    setStatusFilter(e);
  };

  return (
    <>
      <AuthorityDashboard></AuthorityDashboard>
    </>
  );
};

export default AttestationDashboardList;
