import React, { FC, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Grid, Row, Col, Drawer } from "antd";
import { useMsal } from "../components/msal-react-lite";
import { LOGIN_TYPE } from "../common/constants";
import ECFMGLogo from "../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;
const { useBreakpoint } = Grid;

const loginType = LOGIN_TYPE.EMSWPLogin; //You you reach this page you logged in as an EMSWP school

type stateType = {
  menuItemSelected: string;
};

const AuthorityHeaderContent: FC<any> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as stateType;
  const [selectedMenu, setSelectedMenu] = useState("2");
  const { logout } = useMsal();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const screens = useBreakpoint();

  useEffect(() => {
    setSelectedMenu(state?.menuItemSelected); //Highlight the correct menu item on change
  }, [state?.menuItemSelected]);

  useEffect(() => {
    if (location.pathname.includes("instructions")) {
      setSelectedMenu("2");
      return;
    }
    if (location.pathname.includes("dashboard")) {
      setSelectedMenu("3");
    }
  }, [location]);

  const menuItemSelected = async (e: any) => {
    setDrawerVisible(false);
    switch (e.key) {
      case "1":
        setSelectedMenu(e.key);
        await logout(loginType);
        navigate(`/`);
        break;
      case "2":
        navigate(`/attestation/instructions`, {
          state: { menuItemSelected: e.key },
        });
        break;
      case "3":
        navigate(`/attestation/dashboard`, {
          state: { menuItemSelected: e.key },
        });
    }
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  return (
    <>
      {screens.xs ? (
        <>
          <Header
            style={{
              position: "fixed",
              zIndex: 1,
              width: "100%",
              padding: "0 20px",
            }}
          >
            <Row>
              <Col span={8}>
                <MenuOutlined
                  style={{ fontSize: "16px", color: "white" }}
                  onClick={() => {
                    setDrawerVisible(true);
                  }}
                />
                <Drawer
                  placement="left"
                  closable={false}
                  onClose={onDrawerClose}
                  visible={drawerVisible}
                  height="auto"
                >
                  <Menu
                    onClick={(e) => menuItemSelected(e)}
                    theme="light"
                    mode="vertical"
                    defaultSelectedKeys={[selectedMenu]}
                    selectedKeys={[selectedMenu]}
                  >
                    <Menu.Item key="2">Instructions</Menu.Item>
                    <Menu.Item key="3">Attestation Dashboard</Menu.Item>
                    <Menu.Item key="1" style={{ marginLeft: "auto" }}>
                      Logout
                    </Menu.Item>
                  </Menu>
                </Drawer>
              </Col>
              <Col span={8}>
                <img className="logo" src={ECFMGLogo} />
              </Col>
              <Col span={8}></Col>
            </Row>
          </Header>
        </>
      ) : (
        <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <img className="logo" src={ECFMGLogo} />
          <Menu
            onClick={(e) => menuItemSelected(e)}
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[selectedMenu]}
            selectedKeys={[selectedMenu]}
          >
            <Menu.Item key="2">Instructions</Menu.Item>
            <Menu.Item key="3">Attestation Dashboard</Menu.Item>
            <Menu.Item key="1" style={{ marginLeft: "auto" }}>
              Logout
            </Menu.Item>
          </Menu>
        </Header>
      )}
    </>
  );
};

export default AuthorityHeaderContent;
