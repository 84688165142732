import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Spin, Row, Col, Popconfirm, message, Typography } from "antd";
import { useQuery, useMutation } from "@apollo/client/react";
import CannotCertify from "./cannotCertify";
import moment from "moment";
import {
  AuthorityAttestationLayoutAuthorityPortalDocument,
  AuthorityAttestationLayoutRejectDocument,
  AuthorityAttestationLayoutAttestDocument,
  UiPortalConfigType,
} from "../../generated";
import { CertificationInstructionsFactory } from "./certification-instructions-factory";
const { Title, Paragraph, Text, Link } = Typography;

const AttestationLayout: FC<any> = (props) => {
  const navigate = useNavigate();
  const [showCannotCertifyModal, setShowCannotCertifyModal] =
    useState<boolean>(false);
  const [disabledButtons, setDisableButtons] = useState<boolean>(false);

  let { usmleId } = useParams<any>();

  //All is default option to grab everything, but will filter by usmleID
  const { loading, error, data, refetch } = useQuery(
    AuthorityAttestationLayoutAuthorityPortalDocument,
    {
      variables: { attestationOutput: "All", usmleId: usmleId },
    }
  );

  const applicantDetails =
    data?.authorityPortal?.attestationRecords?.length! > 0
      ? data?.authorityPortal?.attestationRecords?.[0]
      : null;

  const [authorityPortalReject] = useMutation(
    AuthorityAttestationLayoutRejectDocument,
    {
      refetchQueries: [
        { query: AuthorityAttestationLayoutAuthorityPortalDocument },
      ],
    }
  );
  const [authorityPortalAttest] = useMutation(
    AuthorityAttestationLayoutAttestDocument,
    {
      refetchQueries: [
        { query: AuthorityAttestationLayoutAuthorityPortalDocument },
      ],
    }
  );

  const onCannotCertifyClick = () => {
    setShowCannotCertifyModal(!showCannotCertifyModal);
  };

  const onCancelClick = () => {
    navigate(
      "../dashboard",
      { state: { menuItemSelected: "3" } } //Attestation Dashboard page is menu item #3
    );
  };

  const CannotCertifyMutation = async (
    selectedRejectionReason: string,
    rejectionReasonText: string
  ) => {
    const applicantDetails =
      data?.authorityPortal?.attestationRecords?.length! > 0
        ? data?.authorityPortal?.attestationRecords?.[0]
        : null;

    setDisableButtons(true);

    await authorityPortalReject({
      variables: {
        usmleId: applicantDetails?.usmleId!,
        dataVersion: applicantDetails?.dataVersion!,
        rejectionReason: selectedRejectionReason,
        rejectionReasonText: rejectionReasonText,
      },
    })
      .then((data: any) => {
        setShowCannotCertifyModal(false);
        message.success(
          "Thank you. The Clinical Skill Attestation for this applicant has been submitted."
        );
        refetch();
      })
      .catch((error: any) => {
        setDisableButtons(false);
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const onCertify = async () => {
    setDisableButtons(true);
    await authorityPortalAttest({
      variables: {
        usmleId: applicantDetails?.usmleId!,
        dataVersion: applicantDetails?.dataVersion!,
      },
    })
      .then((data: any) => {
        message.success(
          "Thank you. The Clinical Skill Attestation for this applicant has been submitted."
        );
        refetch();
      })
      .catch((error: any) => {
        setDisableButtons(false);
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const ApplicantDetails = () => {
    return (
      <>
        <Row>
          <Col span={8}>
            <Text>Applicant's USMLE/ECFMG Identification Number: </Text>
          </Col>
          <Col span={12}>
            <Text strong>{applicantDetails?.usmleId}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>Applicant's Name: </Text>
          </Col>
          <Col span={12}>
            <Text strong>
              {applicantDetails?.lastName}, {applicantDetails?.firstName}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>Applicant's Date of Birth: </Text>
          </Col>
          <Col span={12}>
            <Text strong>
              {moment(applicantDetails?.dateOfBirth).format("MM/DD/YYYY")}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>Medical School: </Text>
          </Col>
          <Col span={12}>
            <Text strong>
              {applicantDetails?.gradSchool},{" "}
              {applicantDetails?.gradSchoolCountry}
            </Text>
          </Col>
        </Row>
      </>
    );
  };

  const CertificationStatus = () => {
    const color = {
      green: "#c9f5c6",
      red: "#f09999",
    };

    const backgroundColor =
      applicantDetails?.attestationOutput === "Attested"
        ? color.green
        : color.red;
    return (
      <>
        <div style={{ backgroundColor: backgroundColor }}>
          <div>
            Status:{" "}
            {applicantDetails?.attestationOutput === "Attested"
              ? "Certified"
              : applicantDetails?.attestationOutput}
          </div>
          <div>Completed By: {applicantDetails?.completedBy}</div>
          {applicantDetails?.attestationOutput === "Rejected" ? (
            <>
              <div>Rejection Reason: {applicantDetails?.rejectionReason}</div>
              {applicantDetails?.rejectionReasonText ? (
                <div>
                  Additional Information:{" "}
                  {applicantDetails?.rejectionReasonText}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </>
    );
  };

  const SubmitMiniCEX = () => {
    return applicantDetails?.attestationOutput ? (
      <>
        <CertificationStatus></CertificationStatus>
        <hr className="noshade" />
        <Row>
          <Col span={6} style={{ margin: "auto" }}>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                onCancelClick();
              }}
            >
              Continue to Dashboard
            </Button>
          </Col>
        </Row>
      </>
    ) : (
      <div style={{ textAlign: "right" }}>
        <Row>
          <Col span={6}>
            <Button
              size="large"
              onClick={() => {
                onCancelClick();
              }}
              loading={disabledButtons}
            >
              Cancel
            </Button>
          </Col>
          <Col span={6}>
            <Button
              size="large"
              type="primary"
              danger
              onClick={() => onCannotCertifyClick()}
              loading={disabledButtons}
            >
              Cannot Certify
            </Button>
          </Col>
          <Col span={6}>
            <Popconfirm
              title={
                "You are about to submit this Mini-CEX evaluation to ECFMG. Once submitted, it cannot be retracted or changed.  "
              }
              onConfirm={() => {
                onCertify();
              }}
              disabled={disabledButtons}
            >
              <Button size="large" type="primary" loading={disabledButtons}>
                Certify
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </div>
    );
  };

  const CannotCertifyComponent = () => {
    return !!data ? (
      <CannotCertify
        authorityPortalData={data}
        visible={showCannotCertifyModal}
        onClose={() => onCannotCertifyClick()}
        disableButtons={disabledButtons}
        CannotCertifyMutation={(
          rejectionReason: string,
          rejectionReasonText: string
        ) => CannotCertifyMutation(rejectionReason, rejectionReasonText)}
      ></CannotCertify>
    ) : null;
  };

  return (
    <>
      {loading ? (
        <Paragraph style={{ textAlign: "center", margin: "80px 40px" }}>
          <Spin size="large" />
          <Title level={2}>Loading...</Title>
        </Paragraph>
      ) : (
        <>
          <div className="site-layout-content">
            <Title level={3}>Clinical Skills Attestation</Title>
            <hr className="noshade" />
            <ApplicantDetails></ApplicantDetails>
            <hr className="noshade" />
            <CertificationInstructionsFactory versionConfig={data?.authorityPortal?.attestationRecords?.[0]?.uiConfig as UiPortalConfigType/*UiPortalConfigType*/}/>
            <hr className="noshade" />
            {SubmitMiniCEX()}
          </div>
          {CannotCertifyComponent()}
        </>
      )}
    </>
  );
};

export default AttestationLayout;
