import { FC, useState, useEffect } from "react";
import { Button, Table, Modal, Grid, Card, List, Typography } from "antd";
import { FormOutlined, InfoCircleOutlined, SolutionOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import {
  PHYSICIAN_ALLOWED_ACTIONS,
  CEX_STATUS,
  DATE_FORMAT,
  ACTIVE_MINICEX_STATUSES,
} from "../../common/constants";
import { PhysicianAttestation } from "./physician-attestation";
import { MiniCexSummaryType, PhysicianResponseType } from "../../generated";

const { Text, Paragraph } = Typography;

const { useBreakpoint } = Grid;

const ComponentPropTypes = {
  onReviewResponse: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export interface IProps {
  physician?: PhysicianResponseType;
  minicex: MiniCexSummaryType[];
  onReviewResponse: (record: any, response: any, index: any) => void;
  loading: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const PhysicianActiveEncounterCard: FC<ComponentProps> = ({
  physician,
  minicex,
  onReviewResponse,
  loading,
}) => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [stateModel, setstateModel] = useState<any>();
  const [showAttestationModal, setShowAttestationModal] =
    useState<boolean>(false);
  const [encounterList, setEncounterList] = useState<any>([]);
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (minicex) {
      var listData: any = [];
      minicex?.map((record: any) => {
        listData.push({
          title: `${record.encounterId}`,
          record,
        });
      });
      setEncounterList(listData);
    }
  }, [minicex]);

  useEffect(() => {
    var filteredStateModel = currentRecord?.stateModel.filter(
      (item: { state: string }) => item.state !== ACTIVE_MINICEX_STATUSES[0]
    ); //ACTIVE_MINICEX_STATUSES[0] = MINICEX_CREATED_PHYSICIAN_INVITE_PENDING
    setstateModel(filteredStateModel);
  }, [currentRecord]);

  const onReviewClick = (record: any /*index: any*/) => {
    setCurrentRecord(record);
    setShowAttestationModal(true);
  };

  const closeAttestationModal = () => {
    setShowAttestationModal(false);
  };

  const handleOnReviewResponse = (record: any, response: any) => {
    onReviewResponse(record, response, currentIndex);
    setShowAttestationModal(false);
  };

  const onOpenEvaluation = (record: any) => {
    navigate(
      `/physician/dashboard/minicex/${record?.encounterId}`,
      { state: { menuItemSelected: "" } } //No menu item for this route
    );
  };

  const setModalWidth = () => {
    if (screens.lg) {
      return "50vw";
    } else {
      return "90vw";
    }
  };

  const renderButtons = (record: any) => {
    if (record.allowedActions.includes(PHYSICIAN_ALLOWED_ACTIONS.REVIEW)) {
      return (
        <Button
          type="primary"
          onClick={() => onReviewClick(record /*, index*/)}
          icon={<SolutionOutlined />}
        >
          Review Request
        </Button>
      );
    } else if (record.allowedActions.includes(PHYSICIAN_ALLOWED_ACTIONS.OPEN)) {
      return (
        <Button
          type="primary"
          onClick={() => {
            onOpenEvaluation(record);
          }}
          icon={<FormOutlined />}
        >
          Open Mini-CEX
        </Button>
      );
    } else {
      return <div></div>;
    }
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  const historyColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <Text>{getStatus(CEX_STATUS, record.state) + " "}</Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any, record: any) => (
        <div>
          {record.timestamp !== undefined ? (
            <Text>
              {moment(record.timestamp)
                .utc()
                .format(`${DATE_FORMAT}, h:mm:ss a`)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <List
        dataSource={encounterList}
        itemLayout="vertical"
        pagination={{ pageSize: 10 }}
        loading={loading}
        renderItem={(item: any) => (
          <List.Item key={item.title}>
            <Card>
              <div>
                <Text strong> Encounter ID:</Text> {item?.record?.encounterId}
              </div>
              <div>
                <Text strong>USMLE/ECFMG ID:</Text> {item?.record?.usmleId}
              </div>
              <div>
                <Text strong>Applicant's Name: </Text>
                {item?.record?.applicantRestOfName !== undefined
                  ? item?.record?.applicantRestOfName +
                    " " +
                    item?.record?.applicantLastName
                  : item?.record?.applicantLastName}
              </div>
              <div>
                <Text strong>Status: </Text>{" "}
                {item?.record?.state !== undefined ? (
                  <>
                    {getStatus(CEX_STATUS, item?.record?.state) + " "}
                    <InfoCircleOutlined
                      style={{ fontSize: "18px" }}
                      onClick={(event: any) => {
                        setCurrentRecord(item?.record);
                        setShowEncounterHistoryModal(true);
                      }}
                    />
                  </>
                ) : (
                  <div></div>
                )}
              </div>
              <div>
                <Text strong>Date Requested: </Text>
                {item?.record?.createdAt ? (
                  <Text>
                    {moment(item?.record?.createdAt).utc().format(DATE_FORMAT)}
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </div>
              <div>
                <Text strong>Action Due: </Text>{" "}
                {item?.record?.expiresAt ? (
                  <Text>
                    {moment(item?.record?.expiresAt).utc().format(DATE_FORMAT) +
                      " 23:59"}
                  </Text>
                ) : (
                  <Text></Text>
                )}
              </div>
              <div>{renderButtons(item?.record)}</div>
            </Card>
          </List.Item>
        )}
      ></List>

      <Modal
        title="Review Request"
        visible={showAttestationModal}
        width={setModalWidth()}
        style={{ height: "100%" }}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <PhysicianAttestation
          currentRecord={currentRecord}
          currentIndex={currentIndex}
          closeAttestationModal={() => closeAttestationModal()}
          onReviewResponse={(record: any, response: any) =>
            handleOnReviewResponse(record, response)
          }
          profileStatus={physician?.minicex?.profileStatus as string}
        ></PhysicianAttestation>
      </Modal>
      <Modal
        title="Status History"
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button
            size="large"
            key="close"
            onClick={() => setShowEncounterHistoryModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div>
          Applicant:{" "}
          <Text
            strong
          >{`${currentRecord?.applicantLastName}, ${currentRecord?.applicantRestOfName}`}</Text>
          <br />
          Encounter ID: <Text strong>{currentRecord?.encounterId}</Text>
          <br />
          <br />
          <Table columns={historyColumns} dataSource={stateModel}></Table>
          <Paragraph>
            All times are calculated using Coordinated Universal Time (UTC).
          </Paragraph>
        </div>
      </Modal>
    </>
  );
};
export default PhysicianActiveEncounterCard;
