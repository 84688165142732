import { FC, useEffect, useState } from 'react';
import { List } from 'antd';
import PropTypes, { InferProps } from "prop-types";
import EmailItem from './email-item';

const ComponentPropTypes = {
  secondaryEmails: PropTypes.arrayOf(PropTypes.string),
  onRemoveEmail: PropTypes.func.isRequired
};

export interface IProps {
  __typename?: "PhysicianResponseType";
  secondaryEmails?: string[];
  onRemoveEmail: (email: string) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const EmailList: FC<ComponentProps> = ({
  secondaryEmails,
  onRemoveEmail
}) => {
  return (
    <>
      <List
        locale={{
          emptyText: "No secondary emails recorded",
        }}
        size="small"
        bordered
        dataSource={secondaryEmails}
        renderItem={(emails) => (
          <EmailItem
            email={emails}
            onEmailRemoval={onRemoveEmail}
          />
        )}
      />
    </>
  )
}

export default EmailList;