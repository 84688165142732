import { FC } from "react";
import { Divider, Grid, Descriptions, Typography } from "antd";
import {
  convertDate,
  DATE_FORMAT,
  getPhysicianName,
  MINICEX_REJECTION_REASONS,
  MINICEX_REJECTION_REASON_KEYS,
} from "../common/constants";
import _ from "lodash";
import { MiniCexSummaryType, PhysicianResponseType } from "../generated";
import dayjs from "dayjs";
const { Text, Paragraph } = Typography;

const { useBreakpoint } = Grid;

export interface IProps {
  __typename?: "ReviewRejectedMiniCEX";
  physician?: PhysicianResponseType;
  miniCEX?: MiniCexSummaryType;
}

const renderExplanation = (miniCEX: IProps["miniCEX"], reasonKey: any) => {
  if (reasonKey === MINICEX_REJECTION_REASON_KEYS.OTHER) {
    return (
      <>
        <div>Explanation:</div>
        <div style={{ paddingLeft: "10px" }}>
          {miniCEX?.rejectionReasonComments}
        </div>
      </>
    );
  }
  return null;
};

const ReviewRejectedMiniCEXV2: FC<IProps> = ({ miniCEX, physician }) => {
  const screens = useBreakpoint();

  const getReasonTextFromKey = (reasonKey: string) => {
    return <>{_.find(MINICEX_REJECTION_REASONS, { key: reasonKey })?.value}</>;
  };

  const ApplicantDetails = () => {
    return (
      <>
        {screens.lg ? (
          <Descriptions
            className="ecfmg-small-descriptions"
            size="small"
            layout="vertical"
            column={5}
            style={{
              borderStyle: "solid",
              borderWidth: "0.5px",
              padding: "20px",
            }}
          >
            <Descriptions.Item label={"Applicant"}>
              {miniCEX?.applicantLastName}, {miniCEX?.applicantRestOfName}
            </Descriptions.Item>
            <Descriptions.Item label="USMLE/ECFMG ID">
              {miniCEX?.usmleId}
            </Descriptions.Item>
            <Descriptions.Item label={"Encounter ID"}>
              {miniCEX?.encounterId}
            </Descriptions.Item>
            <Descriptions.Item label="Date Requested">
              {convertDate(miniCEX?.createdAt)}
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <>
            <Descriptions
              className="ecfmg-small-descriptions"
              size="small"
              layout="vertical"
              column={2}
              style={{
                borderStyle: "solid",
                borderWidth: "0.5px",
                padding: "20px",
              }}
            >
              <Descriptions.Item label={"Applicant"}>
                {miniCEX?.applicantLastName}, {miniCEX?.applicantRestOfName}
              </Descriptions.Item>
              <Descriptions.Item label="USMLE/ECFMG ID">
                {miniCEX?.usmleId}
              </Descriptions.Item>
              <Descriptions.Item label={"Encounter ID"}>
                {miniCEX?.encounterId}
              </Descriptions.Item>
              <Descriptions.Item label="Date Requested">
                {miniCEX?.createdAt ? convertDate(miniCEX?.createdAt) : "N/A"}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </>
    );
  };

  const PhysicianDetails = () => {
    return (
      <>
        <Divider orientation="left">
          <Text strong>Physician Evaluator Details</Text>
        </Divider>
        <Descriptions
          className="ecfmg-small-descriptions"
          size="small"
          layout="vertical"
          column={screens.lg ? 5 : 2}
          style={{
            padding: "20px",
          }}
        >
          <Descriptions.Item label="Name">
            {getPhysicianName(
              physician?.profile?.lastName,
              physician?.profile?.restOfName
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Primary E-mail">
            {physician?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Date of Birth">
            {physician?.profile?.dateOfBirth
              ? dayjs(physician?.profile?.dateOfBirth).format(DATE_FORMAT)
              : "N/A"}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  const RejectionDetails = () => {
    let RejectHeader: any = null;
    let reasonKey: any = null;
    // rejection keys from Attestation will have "_" in front
    if (
      miniCEX?.rejectionReason &&
      miniCEX?.rejectionReason.length > 0 &&
      miniCEX?.rejectionReason[0] === "_"
    ) {
      RejectHeader = () => {
        return (
          <>
            <Paragraph>
              I am unable to certify the statements in this Mini-CEX Attestation
              by Physician Evaluator.
            </Paragraph>
          </>
        );
      };

      reasonKey = miniCEX?.rejectionReason.substring(1);
    } else {
      RejectHeader = () => {
        return (
          <>
            <Paragraph>
              I do not accept this request to complete the Mini-CEX for this
              applicant, and I confirm that a clinical encounter did not take
              place for this request.
            </Paragraph>
          </>
        );
      };
      reasonKey = miniCEX?.rejectionReason;
    }

    return (
      <>
        <RejectHeader />
        {miniCEX?.rejectionReason && miniCEX?.rejectionReason.length > 1 ? (
          <>
            <div>
              <Text style={{ color: "#333333" }} strong>
                Reason For Rejection:
              </Text>
            </div>
            <div style={{ paddingLeft: "10px" }}>
              {getReasonTextFromKey(reasonKey)}
            </div>
            {renderExplanation(miniCEX, reasonKey)}
          </>
        ) : (
          <></>
        )}
      </>
    );
  };
  const MiniCEXView = (
    <>
      <ApplicantDetails />
      <PhysicianDetails />
      <Divider orientation="left">
        <Text strong>Rejection Details</Text>
      </Divider>

      <RejectionDetails />
    </>
  );

  return <>{MiniCEXView}</>;
};
export default ReviewRejectedMiniCEXV2;
