import { FC, useState } from "react";

import {
  Button,
  Modal,
  Popconfirm,
  Row,
  Col,
  Select,
  Input,
  Form,
  Typography,
} from "antd";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;

const CannotCertify: FC<any> = (props) => {
  const [selectedRejectionReason, setSelectedRejectionReason] =
    useState<string>("");

  const [rejectionReasonText, setRejectionReasonText] = useState<string>("");

  const [form] = Form.useForm();

  const certificationInstructions = (
    <>
      <Paragraph style={{ color: "#333333" }} strong>
        I cannot certify this applicant’s Clinical Skills Attestation.
      </Paragraph>
      <ul>
        <li>
          I understand that this response will be used to evaluate this
          individual’s application to meet the clinical skills requirement for
          ECFMG Certification for the purpose of entering graduate medical
          education in the United States.
        </li>
        <li>I am authorized to confirm this on behalf of this institution.</li>
        <li>
          I have read and understand the{" "}
          <Link
            href="https://www.ecfmg.org/resources/irregular-behavior.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            ECFMG Policies and Procedures Regarding Irregular Behavior
          </Link>{" "}
          and agree to abide by these policies and procedures. I understand
          that, as provided in the ECFMG Policies and Procedures Regarding
          Irregular Behavior, ECFMG may find that submission of falsified
          documents and/or false information to ECFMG constitutes irregular behavior.
        </li>
      </ul>
    </>
  );

  const ApplicantDetails = () => {
    const applicantDetails =
      props.authorityPortalData?.authorityPortal?.attestationRecords?.length > 0
        ? props.authorityPortalData.authorityPortal.attestationRecords[0]
        : null;

    return (
      <>
        <Row>
          <Col span={8}>
            <Text>Applicant's USMLE/ECFMG Identification Number: </Text>
          </Col>
          <Col span={12}>
            <b>{applicantDetails?.usmleId}</b>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>Applicant's Name: </Text>
          </Col>
          <Col span={12}>
            <Text strong>
              {applicantDetails?.lastName}, {applicantDetails?.firstName}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>Applicant's Date of Birth: </Text>
          </Col>
          <Col span={12}>
            <Text strong>
              {moment(applicantDetails?.dateOfBirth).format("MM/DD/YYYY")}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>Medical School: </Text>
          </Col>
          <Col span={12}>
            <Text strong>
              {applicantDetails?.gradSchool},{" "}
              {applicantDetails?.gradSchoolCountry}
            </Text>
          </Col>
        </Row>
      </>
    );
  };

  const onRejectionReasonChange = (event: any) => {
    setSelectedRejectionReason(event);
    setRejectionReasonText("");
  };

  const rejectionReasonDropdown = (
    <>
      <Paragraph>
        Please select the reason you cannot certify this attestation, and
        provide additional information, if applicable:
      </Paragraph>
      <Form.Item
        name="rejectionReason"
        rules={[{ required: true, message: "Please select a reason" }]}
      >
        <Select
          placeholder={"Select a reason"}
          style={{ width: 500 }}
          onChange={(e) => onRejectionReasonChange(e)}
        >
          <Option value="Applicant is not a current student or a graduate of this medical school">
            Applicant is not a current student or a graduate of this medical
            school.
          </Option>
          <Option value="Applicant's graduation date is prior to January 1, 2023.">
            Applicant's graduation date is prior to January 1, 2023.
          </Option>
          <Option value="Applicant has not demonstrated a satisfactory level of clinical skills">
            Applicant has not demonstrated a satisfactory level of clinical
            skills.
          </Option>
          <Option value="OTHER">Other</Option>
        </Select>
      </Form.Item>
    </>
  );

  const rejectionReasonTextLayout = (
    <>
      <Paragraph>Additional Information</Paragraph>
      <TextArea
        placeholder={"Input additional information here"}
        value={rejectionReasonText}
        onChange={(e: any) => {
          onRejectionReasonTextChange(e);
        }}
      ></TextArea>
    </>
  );

  const onRejectionReasonTextChange = (e: any) => {
    setRejectionReasonText(e.target.value);
    form.setFieldsValue({ rejectionReasonText: e.target.value });
  };

  const onRejectionConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        props.CannotCertifyMutation(
          selectedRejectionReason,
          rejectionReasonText
        );
      })
      .catch((errorInfo) => {
        console.log("error", errorInfo);
      });
  };

  return (
    <>
      <Modal
        visible={props.visible}
        title={"Cannot Certify"}
        width={"80%"}
        footer={[
          <Button
            size="large"
            onClick={() => props.onClose()}
            loading={props?.disableButtons}
          >
            Cancel
          </Button>,
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={() => {
              onRejectionConfirm();
            }}
            disabled={props?.disableButtons}
          >
            <Button
              size="large"
              type="primary"
              danger
              loading={props?.disableButtons}
            >
              Cannot Certify
            </Button>
          </Popconfirm>,
        ]}
        closable={false}
      >
        <ApplicantDetails />
        <hr className="noshade" />
        {certificationInstructions}
        <hr className="noshade" />
        <Form form={form} onFinish={onRejectionConfirm}>
          {rejectionReasonDropdown}
          <br></br>
          <Form.Item
            name="rejectionReasonText"
            rules={[
              {
                required: selectedRejectionReason === "OTHER",
                message: "Please provide additional Information",
              },
            ]}
          >
            {selectedRejectionReason === "OTHER"
              ? rejectionReasonTextLayout
              : null}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CannotCertify;
