import { FC } from "react";

import { Typography } from "antd";

const { Paragraph, Link } = Typography;

interface CertificationInstructionsV1Props {}

export const CertificationInstructionsV1: FC<CertificationInstructionsV1Props> =
  (props) => {
    return (
      <>
        <Paragraph style={{ color: "#333333" }} strong>
          I certify that:
        </Paragraph>
        <ul>
          <li>
            The above-named individual is a current student of this medical
            school or graduated from this medical school on or after January 1,
            2021.
          </li>
          <li>
            The dean of this medical school or the dean's designee has received
            and reviewed three completed Mini-CEX forms (or their equivalents)
            evaluating clinical patient encounters performed by this applicant.
          </li>
          <li>
            Each of this applicant's Mini-CEX forms (or their equivalents) was
            completed by a different faculty member of the medical school or
            affiliated clinical rotation site, and these faculty members
            routinely assess medical student performance.
          </li>
          <li>
            The dean or dean's designee has confirmed that the three completed
            Mini-CEX evaluations (or their equivalents) indicate that the
            applicant has demonstrated a satisfactory level of clinical skills.
          </li>
          <li>
            I understand that this ECFMG Clinical Skills Attestation will be
            used to evaluate this individual's application to meet the clinical
            skills requirement for ECFMG Certification for the purpose of
            entering graduate medical education in the United States.
          </li>
          <li>
            I am authorized to certify this on behalf of this institution.
          </li>
          <li>
            I have read and understand the{" "}
            <Link
              href="https://www.ecfmg.org/resources/irregular-behavior.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              ECFMG Policies and Procedures Regarding Irregular Behavior
            </Link>{" "}
            and agree to abide by these policies and procedures. I understand
            that, as provided in the ECFMG Policies and Procedures Regarding
            Irregular Behavior, ECFMG may find that submission of falsified
            documents and/or false information to ECFMG constitutes irregular
            behavior.
          </li>
        </ul>
      </>
    );
  };
