import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Input,
  Radio,
  Space,
  message,
  Form,
  Typography,
  Layout,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useMutation } from "@apollo/client";
import useForm from "antd/lib/form/hooks/useForm";
import { OPT_OUT_REASON, OPT_OUT_REASON_MAPPING } from "../common/constants";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { PhysicianOptOutNoAuthOptOutNoAuthDocument } from "../generated";
import Maintenance from "../components/maintenance/maintenance";
import ImpendingMaintenance from "../components/maintenance/impendingMaintenance";
import { Helmet } from "react-helmet";
import FooterLayout from "../footer-layout";
import UnauthHeader from "../unauth-header";
const { Title, Paragraph, Text } = Typography;
const { Header, Content } = Layout;

const PhysicianOptOutNoAuth: FC<any> = (props) => {
  const [rejectReason, setRejectReason] = useState<string>("");
  const [otherTextAreaHidden, setOtherTextAreaHidden] = useState<boolean>(true);
  const [otherText, setOtherText] = useState<string>("");
  const [gqlOptOut] = useMutation(PhysicianOptOutNoAuthOptOutNoAuthDocument);
  const [email, setEmail] = useState<string>("");

  const [token, setToken] = useState<string>("");
  const [form] = useForm();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  let params = useParams();

  useEffect(() => {
    if (rejectReason === OPT_OUT_REASON.OTHER) {
      setOtherTextAreaHidden(false);
    } else {
      setOtherTextAreaHidden(true);
    }
  }, [rejectReason]);

  useEffect(() => {
    setToken(params.token ?? "");
  }, [params]);

  const onContinueClick = async () => {
    await gqlOptOut({
      variables: {
        email: email,
        token: token,
        reason: rejectReason,
        reasonComments: otherText,
      },
    })
      .then(() => {
        navigate({
          pathname: "/physician/opted-out-no-auth",
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onGoBackClick = () => {
    navigate({
      pathname: "/",
    });
  };

  const optOut = (
    <Form
      form={form}
      name="profile"
      onFinish={onContinueClick}
      layout="vertical"
    >
      <div>
        <div>
          <Title level={2}>
            <Text strong>Opt Out</Text>
          </Title>

          <div>
            <Paragraph>
              Please enter your e-mail address, and click Confirm to opt out of
              the ECFMG Mini-CEX evaluation process for Pathway 6 and to be
              removed from ECFMG’s Clinical Skills Evaluation and Attestation
              Portal. Once removed, you will no longer receive requests from
              applicants to complete the ECFMG Mini-CEX for Pathway 6, and you
              will not have access to any pending or completed requests.
            </Paragraph>
          </div>
        </div>

        <div>
          <div>
            <Row style={{ marginBottom: 8 }}>
              <Col flex="110px">E-mail Address:</Col>
              <Col flex={screens.xs ? "auto" : "40%"}>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
          </div>
          <br></br>
          <Row>
            <Col>
              <Paragraph>Please select a reason: </Paragraph>
            </Col>

            <Col offset={1}>
              <Space direction="vertical" size="large">
                <Radio.Group
                  onChange={(e) => setRejectReason(e.target.value)}
                  value={rejectReason}
                >
                  <Space direction="vertical" size="large">
                    {OPT_OUT_REASON_MAPPING.map((reason) => (
                      <div style={{ marginBottom: "-18px" }} key={reason.key}>
                        <Radio value={reason.key}>
                          <div
                            style={{
                              whiteSpace: "normal",
                              paddingLeft: "25px",
                              marginTop: "-20px",
                            }}
                          >
                            {reason.value}
                          </div>
                        </Radio>
                      </div>
                    ))}
                  </Space>
                </Radio.Group>
                <TextArea
                  hidden={otherTextAreaHidden}
                  onChange={(e) => setOtherText(e.target.value)}
                  value={otherText}
                ></TextArea>
              </Space>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Col>
              <Space direction="horizontal">
                <Button
                  size="large"
                  onClick={(e) => {
                    onGoBackClick();
                  }}
                >
                  Go Back
                </Button>

                <Button size="large" htmlType="submit" type="primary">
                  Confirm
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );

  return (
    <>
      <Helmet>
        <title>Opt Out</title>
      </Helmet>
      <Layout className="layout">
        <UnauthHeader />

        <Layout>
          <Header style={{ width: "100%" }}></Header>
          <Content
            className="site-layout"
            style={{
              marginTop: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                flex: "1 auto",
                display: "flex",
                flexFlow: "column",
                borderRadius: "0 0 0.8rem 0.8rem",
              }}
            >
              <ImpendingMaintenance ShowImpendingMessageImmediately={true}>
                <Maintenance>
                  <div className="site-layout-content">{optOut}</div>
                </Maintenance>
              </ImpendingMaintenance>
            </div>
          </Content>

          <FooterLayout />
        </Layout>
      </Layout>
    </>
  );
};

export default PhysicianOptOutNoAuth;
