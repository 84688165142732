import React, { useEffect } from "react";
import logo from "./logo.svg";
import Login from "./login";
import AuthorityDashboardLayout from "./authority/authority-dashboard-layout";
import PhysicianLayout from "./physician/physician-layout";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import "./App.css";
import RequireMsal from "./components/require-msal";
import { LOGIN_TYPE } from "./common/constants";
import ApolloConnection from "./core/apollo-connection";
import PhysicianOptOutNoAuth from "./physician/physician-optOut-noauth";
import PhysicianOptedOut from "./physician/physician-opted-out";
import { useGA4React } from "ga-4-react";
import { ScrollToTop } from "./physician/physician-scroll-to-top";

function App() {
  const ga = useGA4React();
  const location = useLocation();

  useEffect(() => {
    if (ga) {
      ga.pageview(location.pathname);
    }
  }, [location, ga]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <ApolloConnection>
              <Login />
            </ApolloConnection>
          }
        />

        <Route
          path={`/physician/opt-out-no-auth/:token`}
          element={
            <ApolloConnection>
              <PhysicianOptOutNoAuth />
            </ApolloConnection>
          }
        />

        <Route
          path={`/physician/opted-out-no-auth`}
          element={
            <ApolloConnection>
              <PhysicianOptedOut />
            </ApolloConnection>
          }
        />

        {/*Don't use exact when using nested routes */}
        <Route
          path="/attestation/*"
          element={
            <RequireMsal identifier={LOGIN_TYPE.EMSWPLogin}>
              <ApolloConnection
                AuthenticationIdentifier={LOGIN_TYPE.EMSWPLogin}
              >
                <AuthorityDashboardLayout></AuthorityDashboardLayout>
              </ApolloConnection>
            </RequireMsal>
          }
        />
        <Route
          path="/physician/*"
          element={
            <RequireMsal identifier={LOGIN_TYPE.PhysicianLogin}>
              <ApolloConnection
                AuthenticationIdentifier={LOGIN_TYPE.PhysicianLogin}
              >
                <PhysicianLayout></PhysicianLayout>
              </ApolloConnection>
            </RequireMsal>
          }
        />
      </Routes>
    </>
  );
}

export default App;
