import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Row, Col, Radio, Form, Typography } from 'antd';

const { Text } = Typography;

const ComponentPropTypes = {
  questionName: PropTypes.string.isRequired,
  score: PropTypes.number,
  setScore: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: 'MiniCEXRadioDesktop';
  questionName: string;
  score?: number;
  setScore: (value: any) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const MiniCEXRadioDesktop: FC<ComponentProps> = ({ questionName, score, setScore }) => {
  const onRadioChange = (value: any) => {
    setScore(value);
  };

  return (
    <div>
      <Form.Item name={questionName} initialValue={score}>
        <Row style={{ marginLeft: 15 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <div
                style={{
                  background: 'lightgray',
                  width: '33%',
                  textAlign: 'center',
                  height: '70px',
                }}
              >
                <Row
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    height: "65%",
                  }}
                >
                  <Col md={24} lg={24} xl={24} style={{ marginTop: '5px' }}>
                    <Text strong>Performs at the level of a medical school student beginning clinical experiences</Text>
                  </Col>
                </Row>
                <Row
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    width: "inherit",
                  }}
                >
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={1}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 1}
                    >
                      1
                    </Radio>
                  </Col>
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={2}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 2}
                    >
                      2
                    </Radio>
                  </Col>
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={3}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 3}
                    >
                      3
                    </Radio>
                  </Col>
                </Row>
              </div>

              <div style={{ background: '#b3afaf', width: '33%', textAlign: 'center' }}>
                <Row
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    height: "65%",
                  }}
                >
                  <Col md={24} lg={24} xl={24} style={{ marginTop: '5px' }}>
                    <Text strong>Performs at the level of a medical school graduate</Text>
                  </Col>
                </Row>
                <Row
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    width: "inherit",
                  }}
                >
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={4}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 4}
                    >
                      4
                    </Radio>
                  </Col>
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={5}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 5}
                    >
                      5
                    </Radio>
                  </Col>
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={6}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 6}
                    >
                      6
                    </Radio>
                  </Col>
                </Row>
              </div>
              <div style={{ background: '#969595', width: '33%', textAlign: 'center' }}>
                <Row
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    height: "65%",
                  }}
                >
                  <Col md={24} lg={24} xl={24} style={{ marginTop: '5px' }}>
                    <Text strong>Performs at the level of an experienced doctor / practicing physician</Text>
                  </Col>
                </Row>
                <Row
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    width: "inherit",
                  }}
                >
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={7}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 7}
                    >
                      7
                    </Radio>
                  </Col>
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={8}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 8}
                    >
                      8
                    </Radio>
                  </Col>
                  <Col md={8} lg={8} xl={8}>
                    <Radio
                      value={9}
                      onChange={(e) => onRadioChange(e.target.value)}
                      checked={score === 9}
                    >
                      9
                    </Radio>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};
