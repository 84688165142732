import { message } from 'antd';
import { Parser } from 'json2csv';
import _ from 'lodash';
import moment from 'moment';

//headers for csv file
const fields = [
  {
    label: 'USMLE/ECFMG ID',
    value: 'usmleId',
  },
  {
    label: 'Date of Birth',
    value: 'dateOfBirth',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Rest of Name',
    value: 'firstName',
  },
  {
    label: 'Date Requested',
    value: 'requestTimestamp',
  },
  {
    label: 'Status',
    value: 'attestationOutput',
  },
  {
    label: 'Date Completed',
    value: 'completeTimestamp',
  },
];

const getAttestationOutput = (attestationOutput: string | undefined) => {
  if (!attestationOutput) {
    return 'New';
  }
  if (attestationOutput === 'Attested') {
    return 'Certified';
  }
  return attestationOutput;
};

export const downloadCSV = (dataForCSV: any, statusFilter: string) => {
  var jsonDataForCSV = JSON.parse(JSON.stringify(dataForCSV));

  _.forEach(jsonDataForCSV, (e) => {
    e.usmleId = `'${e.usmleId}'`;
    e.dateOfBirth = moment(e.dateOfBirth).format('MM/DD/YYYY');
    e.requestTimestamp = moment(e.requestTimestamp).format('MM/DD/YYYY');
    e.completeTimestamp = e.completeTimestamp ? moment(e.completeTimestamp).format('MM/DD/YYYY') : "N/A";
    e.attestationOutput = getAttestationOutput(e.attestationOutput);
    delete e.__typename;
    delete e.action;
  });

  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(jsonDataForCSV);
  const element = document.createElement('a');
  const file = new Blob([csv], {
    type: 'text/csv',
  });
  element.href = URL.createObjectURL(file);
  element.download = `attestation-records-${
    statusFilter === 'Attested' ? 'Certified' : statusFilter
  }.csv`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  message.success('Download report successfully!');
};
