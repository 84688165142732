import React, { FC, useEffect } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMsal } from '../components/msal-react-lite';
import { LOGIN_TYPE } from '../common/constants';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { useFeatureFlags } from '../components/feature-flag-react-lite';

export interface AuthProps {
  AuthenticationIdentifier?: string;
}

const apolloUrl =
  process.env.REACT_APP_GRAPH_SERVER !== null &&
  typeof process.env.REACT_APP_GRAPH_SERVER !== 'undefined'
    ? `${process.env.REACT_APP_GRAPH_SERVER}`
    : 'http://localhost:7071/api/graphql';

const ApolloConnection: FC<any> = (props) => {
  const { getAuthToken, getIsLoggedIn } = useMsal();
  const { GetFeatureFlagByName } = useFeatureFlags();

  const hasAuth =
    props.AuthenticationIdentifier !== null &&
    typeof props.AuthenticationIdentifier !== 'undefined';

  const withToken = setContext(async (_, { headers }) => {
    if (hasAuth) {
      const token = await getAuthToken(props.AuthenticationIdentifier);
      if (props.AuthenticationIdentifier == LOGIN_TYPE.EMSWPLogin) {
        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : null,
            portal: 'Authority',
          },
        };
      } else if (props.AuthenticationIdentifier == LOGIN_TYPE.PhysicianLogin) {
        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : null,
            portal: 'Physician',
          },
        };
      }
    } else {
      return {
        headers: {
          ...headers,
        },
      };
    }
  });

  var httpLink;
  if (GetFeatureFlagByName('ENABLE_APOLLO_BATCH_AUTHORITY') === 'true') {
    httpLink = new BatchHttpLink({
      uri: apolloUrl,
      batchMax: parseInt(GetFeatureFlagByName('APOLLO_BATCH_MAX_AUTHORITY')) ?? 1, //by default disable batching
      batchInterval: parseInt(GetFeatureFlagByName('APOLLO_BATCH_INTERVAL_AUTHORITY')) ?? 0,
    });
  } else {
    httpLink = createHttpLink({
      uri: apolloUrl,
    });
  }

  const cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'CaseResponseType':
          return `CaseResponseType:${(responseObject as any).caseSummary._id}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });

  const client = new ApolloClient({
    link: from([withToken, httpLink]),
    cache: cache,
  });

  useEffect(() => {
    if (hasAuth && !getIsLoggedIn(props.AuthenticationIdentifier) && client) {
      (async () => {
        try {
          await client.resetStore(); //clear Apollo cache when user logs off
        } catch (err: any) {
          if (err.message !== 'Failed to fetch') {
            console.error('Apollo Reset error', err);
          }
        }
      })();
    }
  }, [hasAuth, getIsLoggedIn, props.AuthenticationIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default ApolloConnection;
