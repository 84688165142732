import { FC, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Collapse,
  message,
  Spin,
  Grid,
  Modal,
  Typography,
  Space,
} from "antd";
import PropTypes, { InferProps } from "prop-types";
import { MiniCEXRadioDesktop } from "./minicex-radio-desktop";
import { MiniCEXRadioMobile } from "./minicex-radio-mobile";
import { useMutation } from "@apollo/client";
import {
  confirmNavigatingAway,
  EVALUATION_STATE,
  MENU_KEYS,
  MINICEX_QUESTION_NAMES_V1,
} from "../../../common/constants";
import { DocumentNode } from "graphql";
import {
  PhysicianMiniCexEvaluationSetEvaluationDocument,
  PhysicianMiniCexEvaluationDetailsFieldsFragmentDoc,
  MiniCexEvaluationType,
} from "../../../generated";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography;

const { Panel } = Collapse;

const MiniCEXEvaluationFragment = {
  MiniCEXEvaluation: PhysicianMiniCexEvaluationDetailsFieldsFragmentDoc,
};

const panelColors = {
  DEFAULT: "#F2F2F2",
  ERROR: "pink",
};

const ComponentPropTypes = {
  encounterId: PropTypes.string.isRequired,
  dataVersion: PropTypes.number.isRequired,
  updateScreenName: PropTypes.func.isRequired,
  refetchQuery: PropTypes.object.isRequired,
  activePanels: PropTypes.array.isRequired,
  setActivePanels: PropTypes.func.isRequired,
};

export interface IProps {
  evaluation?: MiniCexEvaluationType;
  encounterId: string;
  dataVersion: number;
  updateScreenName: (screenName: string) => void;
  refetchQuery: DocumentNode;
  activePanels: any[];
  setActivePanels: (panels: string[]) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const MiniCEXEvaluation: FC<ComponentProps> = ({
  evaluation,
  refetchQuery,
  encounterId,
  dataVersion,
  updateScreenName,
  activePanels,
  setActivePanels,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [medicalInterviewingScore, setMedicalInterviewingScore] =
    useState<number>();
  const [physicalExaminationScore, setPhysicalExaminationScore] =
    useState<number>();
  const [
    professionalismCommunicationScore,
    setprofessionalismCommunicationScore,
  ] = useState<number>();
  const [clinicalReasoningScore, setClinicalReasoningScore] =
    useState<number>();
  const [medicalInterviwingPanelColor, setMedicalInterviewingPanelColor] =
    useState<string>(panelColors.DEFAULT);
  const [physicalExaminationPanelColor, setPhysicalExaminationPanelColor] =
    useState<string>(panelColors.DEFAULT);
  const [
    professionalCommunicationPanelColor,
    setProfessionalCommunicationPanelColor,
  ] = useState<string>(panelColors.DEFAULT);
  const [clinicalReasoningPanelColor, setClinicalReasoningPanelColor] =
    useState<string>(panelColors.DEFAULT);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [MINICEX_SET_EVALUATION, { loading: setMiniCEXLoading }] = useMutation(
    PhysicianMiniCexEvaluationSetEvaluationDocument,
    {
      refetchQueries: [
        {
          query: refetchQuery as DocumentNode,
          variables: { encounterId: encounterId },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (medicalInterviewingScore === undefined) {
      evaluation?.categories?.map((category: any) => {
        if (category.name === MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING) {
          setMedicalInterviewingScore(category.score);
        } else if (
          category.name === MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION
        ) {
          setPhysicalExaminationScore(category.score);
        } else if (
          category.name ===
          MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM
        ) {
          setprofessionalismCommunicationScore(category.score);
        } else if (
          category.name === MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING
        ) {
          setClinicalReasoningScore(category.score);
        }
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    console.log(setMiniCEXLoading);
  }, [setMiniCEXLoading]);

  const formSubmission = async (isComplete: boolean) => {
    if (isComplete) {
      if (medicalInterviewingScore === undefined) {
        message.error("Please enter Medical Interviewing Score");
        setMedicalInterviewingPanelColor(panelColors.ERROR);
        return;
      }
      if (physicalExaminationScore === undefined) {
        message.error("Please enter Physical Examination Score");
        setPhysicalExaminationPanelColor(panelColors.ERROR);
        return;
      }
      if (professionalismCommunicationScore === undefined) {
        message.error("Please enter Professionalism / Communication Score");
        setProfessionalCommunicationPanelColor(panelColors.ERROR);
        return;
      }
      if (clinicalReasoningScore === undefined) {
        message.error("Please enter Clinical Reasoning Score");
        setClinicalReasoningPanelColor(panelColors.ERROR);
        return;
      }
    }
    var categories = [];

    if (medicalInterviewingScore) {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING,
        score: medicalInterviewingScore,
      });
    }
    if (physicalExaminationScore) {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION,
        score: physicalExaminationScore,
      });
    }
    if (professionalismCommunicationScore) {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM,
        score: professionalismCommunicationScore,
      });
    }
    if (clinicalReasoningScore) {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING,
        score: clinicalReasoningScore,
      });
    }

    const input = {
      categories: categories,
    };
    await MINICEX_SET_EVALUATION({
      variables: {
        encounterId: encounterId,
        dataVersion: dataVersion,
        isComplete: isComplete,
        input: input,
      },
    })
      .then((data: any) => {
        setIsFormDirty(false);
        location.state = {
          menuItemSelected: MENU_KEYS.DASHBOARD,
          isMiniCEXFormDirty: false,
        };
        if (isComplete) {
          const progress =
            data?.data?.PhysicianPortal_MiniCEX_setEvaluation?.form?.progress;
          const additional_info = progress?.find(
            (element: any) => element.state === "ADDITIONAL_INFO"
          );
          if (additional_info.status === "NOT_REQUIRED") {
            updateScreenName(EVALUATION_STATE.REVIEW);
          } else {
            updateScreenName(EVALUATION_STATE.ADDITIONAL_INFO);
          }
        } else {
          message.success("Saved successfully");
        }
      })
      .catch((error: any) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const onPanelChange = (e: any) => {
    setActivePanels(e);
  };

  const PreviousButton = () => {
    return (
      <Button
        size={screens.lg ? "large" : "middle"}
        style={{ float: "left" }}
        onClick={() => {
          if (isFormDirty) {
            confirmNavigatingAway(() => {
              setIsFormDirty(false);
              updateScreenName(EVALUATION_STATE.ENCOUNTER);
            });
          } else {
            updateScreenName(EVALUATION_STATE.ENCOUNTER);
          }
        }}
      >
        <DoubleLeftOutlined />
        Previous
      </Button>
    );
  };

  const ReturnToDashboardButton = () => {
    const buttonLargeStyle: React.CSSProperties = {
      float: "left",
      // marginLeft: "25px",
    };
    const buttonMobileStyle: React.CSSProperties = {
      width: "100%",
    };
    return (
      <>
        <Button
          size={screens.lg ? "large" : "middle"}
          style={screens.lg ? buttonLargeStyle : buttonMobileStyle}
          onClick={() => {
            if (isFormDirty) {
              confirmNavigatingAway(() => {
                setIsFormDirty(false);
                navigate("/physician/dashboard", {
                  state: {
                    menuItemSelected: MENU_KEYS.DASHBOARD,
                    isMiniCEXFormDirty: false,
                  },
                });
              });
            } else {
              navigate("/physician/dashboard", {
                state: {
                  menuItemSelected: MENU_KEYS.DASHBOARD,
                  isMiniCEXFormDirty: false,
                },
              });
            }
          }}
        >
          Return to Dashboard
        </Button>
      </>
    );
  };

  const renderActionButtons = () => {
    return screens.lg ? (
      <>
        <Space>
          <PreviousButton />
          <ReturnToDashboardButton />
        </Space>

        <Space style={{ float: "right" }}>
          <Button
            size="large"
            onClick={async () => {
              await formSubmission(false);
            }}
          >
            Save
          </Button>
          <Button type="primary" htmlType="submit" size="large">
            Continue <DoubleRightOutlined />
          </Button>
        </Space>
      </>
    ) : (
      <>
        <Space direction="vertical" style={{ width: "100%" }}>
          <div style={{ alignItems: "justify" }}>
            <Space
              direction="horizontal"
              align={"start"}
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              <PreviousButton />

              <Button
                size="middle"
                onClick={async () => {
                  await formSubmission(false);
                }}
              >
                Save
              </Button>
              <Button size="large" type="primary" htmlType="submit">
                Continue <DoubleRightOutlined />
              </Button>
            </Space>
          </div>

          <ReturnToDashboardButton />
        </Space>
      </>
    );
  };

  const renderMiniCEXQuestion = (
    questionName: string,
    score: number | undefined,
    setScore: any
  ) => {
    return (
      <>
        {screens.lg ? (
          <MiniCEXRadioDesktop
            questionName={questionName}
            score={score}
            setScore={setScore}
          ></MiniCEXRadioDesktop>
        ) : (
          <MiniCEXRadioMobile
            questionName={questionName}
            score={score}
            setScore={setScore}
          ></MiniCEXRadioMobile>
        )}
      </>
    );
  };

  const onFieldsChangeHandler = () => {
    let savedMedScore = "";
    let savedPhyScore = "";
    let savedProScore = "";
    let savedCliScore = "";
    evaluation?.categories?.map((category: any) => {
      if (category.name === MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING) {
        savedMedScore = category.score.toString();
      } else if (
        category.name === MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION
      ) {
        savedPhyScore = category.score.toString();
      } else if (
        category.name ===
        MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM
      ) {
        savedProScore = category.score.toString();
      } else if (
        category.name === MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING
      ) {
        savedCliScore = category.score.toString();
      }
    });

    let formMedScore = form.getFieldValue(
      MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING
    );
    let formPhyScore = form.getFieldValue(
      MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION
    );
    let formProScore = form.getFieldValue(
      MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM
    );
    let formCliScore = form.getFieldValue(
      MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING
    );

    if (
      (formMedScore && savedMedScore !== formMedScore.toString()) ||
      (formPhyScore && savedPhyScore !== formPhyScore.toString()) ||
      (formProScore && savedProScore !== formProScore.toString()) ||
      (formCliScore && savedCliScore !== formCliScore.toString())
    ) {
      setIsFormDirty(true);
      location.state = {
        menuItemSelected: MENU_KEYS.DASHBOARD,
        isMiniCEXFormDirty: true,
      };
    } else {
      setIsFormDirty(false);
      location.state = {
        menuItemSelected: MENU_KEYS.DASHBOARD,
        isMiniCEXFormDirty: false,
      };
    }
  };

  const renderForm = () => {
    return (
      <>
        <Form
          form={form}
          onFinish={() => formSubmission(true)}
          onFieldsChange={(changed, all) => onFieldsChangeHandler()}
        >
          {/* <Prompt when={isFormDirty} message={MiniCEXFormNavigateAwayConfirmMessage} /> */}
          <Collapse
            defaultActiveKey={activePanels}
            onChange={(e) => {
              onPanelChange(e);
            }}
          >
            <Panel
              header={
                <Text strong>{`Medical Interviewing Skills - ${
                  medicalInterviewingScore
                    ? medicalInterviewingScore
                    : "Incomplete"
                }`}</Text>
              }
              key="MEDICAL_INTERVIEWING"
              style={{ backgroundColor: medicalInterviwingPanelColor }}
            >
              <>
                <div>
                  <em>
                    Expectation for an individual who performs like a medical
                    school graduate:
                    <ul>
                      <li>
                        Facilitates accurate collection of a patient's history
                      </li>
                      <li>
                        Effectively uses questions/directions to obtain accurate
                        information needed
                      </li>
                      <li>History is organized and accurate</li>
                    </ul>
                  </em>
                  {renderMiniCEXQuestion(
                    MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING,
                    medicalInterviewingScore,
                    (value: any) => {
                      setMedicalInterviewingPanelColor(panelColors.DEFAULT);
                      setMedicalInterviewingScore(value);
                    }
                  )}
                </div>
              </>
            </Panel>
            <Panel
              header={
                <Text strong>{`Physical Examination Skills - ${
                  physicalExaminationScore
                    ? physicalExaminationScore
                    : "Incomplete"
                }`}</Text>
              }
              key="PHYSICIAN_EXAMINATION"
              style={{ backgroundColor: physicalExaminationPanelColor }}
            >
              <>
                <div>
                  <em>
                    Expectation for an individual who performs like a medical
                    school graduate:
                    <ul>
                      <li>Follows efficient, logical sequence</li>
                      <li>
                        Focuses physical exam on patient problem or presentation
                      </li>
                      <li>Sensitive to patient’s modesty and comfort</li>
                    </ul>
                  </em>
                  {renderMiniCEXQuestion(
                    MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION,
                    physicalExaminationScore,
                    (value: any) => {
                      setPhysicalExaminationPanelColor(panelColors.DEFAULT);
                      setPhysicalExaminationScore(value);
                    }
                  )}
                </div>
              </>
            </Panel>
            <Panel
              header={
                <Text strong>{`Professionalism/Communication Skills - ${
                  professionalismCommunicationScore
                    ? professionalismCommunicationScore
                    : "Incomplete"
                }`}</Text>
              }
              key="PROFESSIONALISM_COMMUNICATION"
              style={{ backgroundColor: professionalCommunicationPanelColor }}
            >
              <>
                <div>
                  <em>
                    Expectation for an individual who performs like a medical
                    school graduate:
                    <ul>
                      <li>Shows respect, compassion, and empathy</li>
                      <li>Establishes trust</li>
                      <li>Responds appropriately to non-verbal cues</li>
                    </ul>
                  </em>
                  {renderMiniCEXQuestion(
                    MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM,
                    professionalismCommunicationScore,
                    (value: any) => {
                      setProfessionalCommunicationPanelColor(
                        panelColors.DEFAULT
                      );
                      setprofessionalismCommunicationScore(value);
                    }
                  )}
                </div>
              </>
            </Panel>
            <Panel
              header={
                <Text strong>{`Clinical Reasoning & Judgment - ${
                  clinicalReasoningScore ? clinicalReasoningScore : "Incomplete"
                }`}</Text>
              }
              key="CLINICAL_REASONING"
              style={{ backgroundColor: clinicalReasoningPanelColor }}
            >
              <>
                <div>
                  <em>
                    Expectation for an individual who performs like a medical
                    school graduate:
                    <ul>
                      <li>
                        Delivers efficient oral presentation of the patient’s
                        problems, including differential diagnoses and
                        recommendations for next steps
                      </li>
                    </ul>
                  </em>
                  {renderMiniCEXQuestion(
                    MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING,
                    clinicalReasoningScore,
                    (value: any) => {
                      setClinicalReasoningPanelColor(panelColors.DEFAULT);
                      setClinicalReasoningScore(value);
                    }
                  )}
                </div>
              </>
            </Panel>
          </Collapse>
          <br />
          {renderActionButtons()}
          <Modal
            footer={null}
            visible={setMiniCEXLoading}
            closable={false}
            style={{ textAlign: "center" }}
            width="200px"
          >
            <Spin /> Processing...
          </Modal>
        </Form>
      </>
    );
  };

  return <>{renderForm()}</>;
};

export default {
  Component: MiniCEXEvaluation,
  Fragment: MiniCEXEvaluationFragment,
};
