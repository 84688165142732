import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Popconfirm, message, Select, Space, Typography } from "antd";
import { useMutation } from "@apollo/client/react";
import {
  SURVEY_QUESTION_CODES,
  SURVEY_QUESTION_TEXT,
} from "../common/constants";
import { Helmet } from "react-helmet";
import {
  PhysicianMiniCexSurveySetSurveyDocument,
  PhysicianMiniCexSurveySetSurveyMutationVariables,
} from "../generated";

const { Paragraph, Text } = Typography;

const { Option } = Select;
const { Title } = Typography;

type locationState = {
  encounterId: string;
  dataVersion: number;
};

const MiniCEXSurvey: FC<any> = (props) => {
  const navigate = useNavigate();
  const [feedbackOnTheirPerformance, setfeedbackOnTheirPerformance] =
    useState<string>("N/A");
  const [technologyInPatientRoom, setTechnologyInPatientRoom] =
    useState<string>("N/A");
  const [conductedInEnglish, setConductedInEnglish] = useState<string>("N/A");
  const [surveyData, setSurveyData] =
    useState<PhysicianMiniCexSurveySetSurveyMutationVariables>();
  const [saveSurveyResponses, { loading: saveSurveyResponsesLoading }] =
    useMutation(PhysicianMiniCexSurveySetSurveyDocument);
  const location = useLocation();
  const state = location.state as locationState;
  const popconText = (
    <>
      <b>Skip Survey?</b>
      <div>
        You will not be able to return to the survey again if you return to the
        dashboard.
      </div>
    </>
  );

  useEffect(() => {
    var setSurvey = {
      encounterId: state?.encounterId,
      dataVersion: state?.dataVersion,
      input: {
        questions: [
          {
            questionId: SURVEY_QUESTION_CODES.feedback_on_their_performance,
            response: "N/A",
          },
          {
            questionId: SURVEY_QUESTION_CODES.technology_in_the_patient_room,
            response: "N/A",
          },
          {
            questionId: SURVEY_QUESTION_CODES.conducted_in_english,
            response: "N/A",
          },
        ],
      },
    } as PhysicianMiniCexSurveySetSurveyMutationVariables;

    setSurveyData(setSurvey);
  }, []);

  const updateResponse = (value: string, questionId: any) => {
    if (surveyData) {
      var setSurvey = surveyData;

      for (var i in setSurvey?.input?.questions) {
        if (setSurvey?.input?.questions[+i]?.questionId === questionId) {
          setSurvey.input!.questions[+i]!.response = value.toString();
          setSurveyData(setSurvey);
          break;
        }
      }
    }
  };

  const onChangeFeedbackOnTheirPerformance = (value: any) => {
    setfeedbackOnTheirPerformance(value);
  };

  const onChangeTechnologyInPatientRoom = (value: any) => {
    setTechnologyInPatientRoom(value);
  };

  const onChangeConductedInEnglish = (value: any) => {
    setConductedInEnglish(value);
  };

  const returnToDashboard = () => {
    console.log("TODO: Return code");
    navigate({
      pathname: `/physician/dashboard`,
    });
  };

  const completeSurvey = async () => {
    await saveSurveyResponses({
      variables: surveyData,
    })
      .then((result) => {
        message.success("Survey completed successfully");
        returnToDashboard();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <>
      <Helmet>
        <title>Physician Survey</title>
      </Helmet>
      <div className="site-layout-background ">
        <Title level={3}>Survey</Title>
        <Paragraph>
          Thank you for completing the Mini-CEX evaluation for this applicant.
          We appreciate your assistance in evaluating the clinical skills of
          applicants for ECFMG Certification.
        </Paragraph>
        <Paragraph>
          We ask that you provide some additional information to
          assist in our future planning. Your response to these questions is
          optional and will not be used to evaluate the Mini-CEX.
        </Paragraph>
        <div>{SURVEY_QUESTION_TEXT.feedback_on_their_performance}</div>
        <Select
          style={{ marginLeft: 15, width: "175px" }}
          value={feedbackOnTheirPerformance}
          onChange={(value: string) => {
            updateResponse(
              value,
              SURVEY_QUESTION_CODES.feedback_on_their_performance
            );
            onChangeFeedbackOnTheirPerformance(value);
          }}
        >
          <Option key="N/A" value="N/A">
            {" "}
          </Option>
          <Option key="yes" value="Yes">
            Yes
          </Option>
          <Option key="no" value="No">
            No
          </Option>
        </Select>
        <br />
        <br />
        <div>{SURVEY_QUESTION_TEXT.technology_in_the_patient_room}</div>
        <Select
          style={{ marginLeft: 15, width: "175px" }}
          value={technologyInPatientRoom}
          onChange={(value: string) => {
            updateResponse(
              value,
              SURVEY_QUESTION_CODES.technology_in_the_patient_room
            );
            onChangeTechnologyInPatientRoom(value);
          }}
        >
          <Option key="N/A" value="N/A">
            {" "}
          </Option>
          <Option key="yes" value="Yes">
            Yes
          </Option>
          <Option key="no" value="No">
            No
          </Option>
        </Select>
        <br />
        <br />
        <div>{SURVEY_QUESTION_TEXT.conducted_in_english}</div>
        <Select
          style={{ marginLeft: 15, width: "175px" }}
          value={conductedInEnglish}
          onChange={(value: string) => {
            updateResponse(value, SURVEY_QUESTION_CODES.conducted_in_english);
            onChangeConductedInEnglish(value);
          }}
        >
          <Option key="N/A" value="N/A">
            {" "}
          </Option>
          <Option key="yes" value="Yes">
            Yes
          </Option>
          <Option key="no" value="No">
            No
          </Option>
        </Select>
        <br />
        <br />
        <Paragraph>
          Thank you for taking the time to complete the survey.
        </Paragraph>
        <br />
        <Popconfirm
          title={popconText}
          icon={null}
          onConfirm={() => returnToDashboard()}
          placement="topLeft"
          okText="OK"
          cancelText="Cancel"
        >
          <Button size="large">Return to Dashboard</Button>
        </Popconfirm>

        <Button
          type="primary"
          size="large"
          onClick={() => completeSurvey()}
          loading={saveSurveyResponsesLoading}
          style={{ float: "right" }}
        >
          Submit Survey
        </Button>
      </div>
    </>
  );
};

export default MiniCEXSurvey;
