import React, { FC, Suspense } from "react";
import PropTypes, { InferProps } from "prop-types";
import { DocumentNode } from "@apollo/client";
import {
  PhysicianEvaluationLayoutPhysicianPortalDocument,
  PhysicianLicenseRecordType,
  PhysicianProfileType,
  PhysicianResponseType,
} from "../../../generated";

const ComponentPropTypes = {
  evaluation: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        reasonText: PropTypes.string.isRequired,
        score: PropTypes.string.isRequired,
        isAdditionalInfoRequired: PropTypes.bool.isRequired,
      })
    ),
    additionalInfoText: PropTypes.string.isRequired,
  }),
  encounterId: PropTypes.string.isRequired,
  dataVersion: PropTypes.number.isRequired,
  updateScreenName: PropTypes.func.isRequired,
  refetchQuery: PropTypes.object.isRequired,
  licenses: PropTypes.object,
  showFaimerPreSurveyQuestions: PropTypes.bool.isRequired,
  miniCEXVersionNum: PropTypes.number.isRequired,
};

export interface IProps {
  __typename?: "MiniCEXReview";
  evaluation?: {
    categories: {
      name: string;
      reasonText: string;
      score: string;
      isAdditionalInfoRequired: boolean;
    }[];

    additionalInfoText: string;
  };
  encounterId: string;
  dataVersion: number | undefined;
  updateScreenName: (screenName: string) => void;
  refetchQuery: DocumentNode;
  licenses?: PhysicianLicenseRecordType[];
  showFaimerPreSurveyQuestions: boolean;
  miniCEXVersionNum: number;
  physician?: PhysicianResponseType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const MiniCEXReviewWrapper: FC<ComponentProps> = ({
  encounterId,
  dataVersion,
  updateScreenName,
  licenses,
  showFaimerPreSurveyQuestions,
  miniCEXVersionNum,
  physician,
}) => {
  const MiniCEXReviewV1 = React.lazy(() => import("./minicex-review-v1"));

  const renderVersion = () => {
    switch (miniCEXVersionNum) {
      case 1: {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <MiniCEXReviewV1
              key={encounterId}
              encounterId={encounterId as string}
              dataVersion={dataVersion}
              updateScreenName={(screenName: string) => {
                updateScreenName(screenName);
              }}
              refetchQuery={PhysicianEvaluationLayoutPhysicianPortalDocument}
              licenses={licenses}
              showFaimerPreSurveyQuestions={showFaimerPreSurveyQuestions}
              physician={physician}
            />
          </Suspense>
        );
      }
      default: {
        return <div></div>;
      }
    }
  };

  return renderVersion();
};

export default MiniCEXReviewWrapper;
