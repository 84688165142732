import React, { FC, useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Layout } from "antd";
import AttestationDashboardLayout from "./attestationDashboard/attestationDashboardLayout";
import AttestationLayout from "./attestationView/attestation-layout";
import SchoolInstructions from "./school-instructions";
import RequireAuth from "../components/requireauth";
import { useMsal } from "../components/msal-react-lite";
import Maintenance from "../components/maintenance/maintenance";
import ImpendingMaintenance from "../components/maintenance/impendingMaintenance";
import { LOGIN_TYPE } from "../common/constants";
import FooterLayout from "../footer-layout";
import AuthorityHeaderContent from "./authority-header-content";

const { Content } = Layout;

const loginType = LOGIN_TYPE.EMSWPLogin; //You you reach this page you logged in as an EMSWP school

type stateType = {
  menuItemSelected: string;
};

const AuthorityDashboardLayout: FC<any> = (props) => {
  const location = useLocation();
  const state = location.state as stateType;
  const [selectedMenu, setSelectedMenu] = useState("2");
  const { getAuthResult } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  let isRendered = React.useRef(false); //Used to make Async code not get called on every render.
  useEffect(() => {
    (async () => {
      // IIFE to make async code work in a non-async Functional Component
      if (!isRendered.current) {
        var authResult = await getAuthResult(loginType);
        console.log(`authResult:${authResult}`);
        setIsAuthenticated(authResult ? true : false);
      }
    })();

    return () => {
      isRendered.current = true;
    };
  }, [isAuthenticated]);

  useEffect(() => {
    setSelectedMenu(state?.menuItemSelected); //Highlight the correct menu item on change
  }, [state?.menuItemSelected]);

  useEffect(() => {
    if (location.pathname.includes("instructions")) {
      setSelectedMenu("2");
      return;
    }
    if (location.pathname.includes("dashboard")) {
      setSelectedMenu("3");
    }
  }, [location]);

  return (
    <Layout className="layout">
      <AuthorityHeaderContent />
      <Content
        className="site-layout"
        style={{ marginTop: "0px", display: "flex", flexDirection: "column" }}
      >
        <div
          className="site-layout-background"
          style={{
            flex: "1 auto",
            display: "flex",
            flexFlow: "column",
            borderRadius: "0 0 0.8rem 0.8rem",
            marginTop: "30px",
          }}
        >
          <ImpendingMaintenance ShowImpendingMessageImmediately={false}>
            <Maintenance>
              <RequireAuth isAuthenticated={isAuthenticated}>
                <Routes>
                  <Route
                    path={`/instructions`}
                    element={<SchoolInstructions {...props} />}
                  />

                  <Route
                    path={`/dashboard`}
                    element={<AttestationDashboardLayout {...props} />}
                  />
                  <Route
                    path={`/dashboard/detail/:usmleId`}
                    element={<AttestationLayout {...props} />}
                  />
                </Routes>
              </RequireAuth>
            </Maintenance>
          </ImpendingMaintenance>
        </div>
      </Content>
      <FooterLayout />
    </Layout>
  );
};

export default AuthorityDashboardLayout;
