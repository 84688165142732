import { FC } from "react";
import { List, Popconfirm, Tooltip, Typography } from "antd";
import PropTypes, { InferProps } from "prop-types";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ComponentPropTypes = {
  email: PropTypes.string,
  onEmailAdd: PropTypes.func,
  onEmailRemoval: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: "";
  email: string;
  onEmailAdd?: () => void;
  onEmailRemoval: (email: string) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const EmailItem: FC<ComponentProps> = ({ email, onEmailRemoval }) => {
  return (
    <List.Item
      actions={[
        <Popconfirm
          title={`Are you sure you want to remove ${email}?`}
          onConfirm={() => {
            onEmailRemoval(email);
          }}
        >
          <Tooltip title="Remove email">
            <CloseCircleOutlined style={{ color: "#D11D5C" }} />
          </Tooltip>
        </Popconfirm>,
      ]}
    >
      <Text>{email}</Text>
    </List.Item>
  );
};

export default EmailItem;
