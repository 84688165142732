import React, { FC, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Input,
  Button,
  Grid,
  Form,
  message,
  Modal,
  Spin,
  Typography,
  Space,
} from "antd";
import PropTypes, { InferProps } from "prop-types";
import { useMutation } from "@apollo/client";
import {
  confirmNavigatingAway,
  EVALUATION_STATE,
  MENU_KEYS,
  MINICEX_QUESTION_NAMES_V1,
} from "../../../common/constants";
import { DocumentNode } from "graphql";
import {
  PhysicianMiniCexAdditionalDetailsSetAdditionalInfoDocument,
  PhysicianMiniCexAdditionalDetailsEvaluationFieldsFragmentDoc,
  MiniCexEvaluationType,
} from "../../../generated";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Text, Paragraph } = Typography;

const MiniCEXAdditionalInformationFragment = {
  MiniCEXAdditionalInformation:
    PhysicianMiniCexAdditionalDetailsEvaluationFieldsFragmentDoc,
};

const ComponentPropTypes = {
  encounterId: PropTypes.string.isRequired,
  dataVersion: PropTypes.number.isRequired,
  updateScreenName: PropTypes.func.isRequired,
  refetchQuery: PropTypes.object.isRequired,
};

export interface IProps {
  evaluation?: MiniCexEvaluationType;
  encounterId: string;
  dataVersion: number;
  updateScreenName: (screenName: string) => void;
  refetchQuery: DocumentNode;
}
export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const MiniCEXAdditionalInformation: FC<ComponentProps> = ({
  evaluation,
  refetchQuery,
  encounterId,
  dataVersion,
  updateScreenName,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMedicalInterviewingRequired, setIsMedicalInterviewingRequired] =
    useState<boolean>(false);
  const [medicalInterviewingScore, setMedicalInterviewingScore] =
    useState<string>("");
  const [medicalInterviewReason, setMedicalInterviewReason] =
    useState<string>("");
  const [isPhysicianExaminationRequired, setIsPhysicianExaminationRequired] =
    useState<boolean>(false);
  const [physicalExaminationScore, setPhysicalExaminationScore] =
    useState<string>("");
  const [physicalExaminationReason, setPhysicalExaminationReason] =
    useState<string>("");
  const [
    isProfessionalismCommunicationRequired,
    setIsProfessionalismCommunicationRequired,
  ] = useState<boolean>(false);
  const [
    professionalismCommunicationScore,
    setprofessionalismCommunicationScore,
  ] = useState<string>("");
  const [
    professionalismCommunicationReason,
    setprofessionalismCommunicationReason,
  ] = useState<string>("");
  const [isClinicalReasoningRequired, setIsClinicalReasoningRequired] =
    useState<boolean>(false);
  const [clinicalReasoningScore, setClinicalReasoningScore] =
    useState<string>("");
  const [clinicalReasoningReason, setClinicalReasoningReason] =
    useState<string>("");
  const [overallAdditionalInfo, setOverAdditionalInfo] = useState<string>(
    evaluation?.additionalInfoText ?? ""
  );

  const [MINICEX_SET_ADDITIONAL_INFORMATION, { loading: setMiniCEXLoading }] =
    useMutation(PhysicianMiniCexAdditionalDetailsSetAdditionalInfoDocument, {
      refetchQueries: [
        {
          query: refetchQuery as DocumentNode,
          variables: { encounterId: encounterId },
        },
      ],
      awaitRefetchQueries: true,
    });

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    evaluation?.categories?.map((category: any) => {
      if (category.name === MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING) {
        setIsMedicalInterviewingRequired(category.isAdditionalInfoRequired);
        setMedicalInterviewReason(category.reasonText);
        setMedicalInterviewingScore(category.score);
      } else if (
        category.name === MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION
      ) {
        setIsPhysicianExaminationRequired(category.isAdditionalInfoRequired);
        setPhysicalExaminationReason(category.reasonText);
        setPhysicalExaminationScore(category.score);
      } else if (
        category.name ===
        MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM
      ) {
        setIsProfessionalismCommunicationRequired(
          category.isAdditionalInfoRequired
        );
        setprofessionalismCommunicationReason(category.reasonText);
        setprofessionalismCommunicationScore(category.score);
      } else if (
        category.name === MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING
      ) {
        setIsClinicalReasoningRequired(category.isAdditionalInfoRequired);
        setClinicalReasoningReason(category.reasonText);
        setClinicalReasoningScore(category.score);
      }
    });
  }, [evaluation]);

  const [form] = Form.useForm();

  const formSubmission = async (isComplete: boolean) => {
    var categories = [];

    if (medicalInterviewReason && medicalInterviewReason !== "") {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING,
        reasonText: medicalInterviewReason,
      });
    }
    if (physicalExaminationReason && physicalExaminationReason !== "") {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION,
        reasonText: physicalExaminationReason,
      });
    }
    if (
      professionalismCommunicationReason &&
      professionalismCommunicationReason !== ""
    ) {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM,
        reasonText: professionalismCommunicationReason,
      });
    }
    if (clinicalReasoningReason && clinicalReasoningReason !== "") {
      categories.push({
        name: MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING,
        reasonText: clinicalReasoningReason,
      });
    }

    const input = {
      categories: categories,
      additionalInfoText: overallAdditionalInfo,
    };
    await MINICEX_SET_ADDITIONAL_INFORMATION({
      variables: {
        encounterId: encounterId,
        dataVersion: dataVersion,
        isComplete: isComplete,
        input: input,
      },
    })
      .then((data: any) => {
        setIsFormDirty(false);
        location.state = {
          menuItemSelected: MENU_KEYS.DASHBOARD,
          isMiniCEXFormDirty: false,
        };
        if (isComplete) {
          //push to next component
          updateScreenName(EVALUATION_STATE.REVIEW);
        } else {
          message.success("saved successfully");
        }
      })
      .catch((error: any) => {
        //only grab the first error
        const graphQLErrorReference =
          error?.graphQLErrors[0]?.extensions?.referenceId || "";
        message.error(
          `${error.toString()} - Reference ID : ${graphQLErrorReference}`
        );
      });
  };

  const onFieldsChangeHandler = () => {
    let savedMedReason = "";
    let savedPhyReason = "";
    let savedProReason = "";
    let savedCliReason = "";
    let savedAddInfo: any = "";

    evaluation?.categories?.map((category: any) => {
      if (category.name === MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING) {
        savedMedReason = category.reasonText ?? "";
      } else if (
        category.name === MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION
      ) {
        savedPhyReason = category.reasonText ?? "";
      } else if (
        category.name ===
        MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM
      ) {
        savedProReason = category.reasonText ?? "";
      } else if (
        category.name === MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING
      ) {
        savedCliReason = category.reasonText ?? "";
      }
    });
    savedAddInfo = evaluation?.additionalInfoText ?? "";

    let formMedReason =
      form.getFieldValue(MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING) ?? "";
    let formPhyReason =
      form.getFieldValue(MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION) ?? "";
    let formProReason =
      form.getFieldValue(
        MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM
      ) ?? "";
    let formCliReason =
      form.getFieldValue(MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING) ?? "";
    let formAddInfo = form.getFieldValue("AdditionalDetails") ?? "";

    if (
      savedMedReason !== formMedReason ||
      savedPhyReason !== formPhyReason ||
      savedProReason !== formProReason ||
      savedCliReason !== formCliReason ||
      savedAddInfo !== formAddInfo
    ) {
      setIsFormDirty(true);
      location.state = {
        menuItemSelected: MENU_KEYS.DASHBOARD,
        isMiniCEXFormDirty: true,
      };
    } else {
      setIsFormDirty(false);
      location.state = {
        menuItemSelected: MENU_KEYS.DASHBOARD,
        isMiniCEXFormDirty: false,
      };
    }
  };
  const PreviousButton = () => {
    return (
      <Button
        size={screens.lg ? "large" : "middle"}
        style={{ float: "left" }}
        onClick={() => {
          if (isFormDirty) {
            confirmNavigatingAway(() => {
              setIsFormDirty(false);
              updateScreenName(EVALUATION_STATE.EVALUATION);
            });
          } else {
            updateScreenName(EVALUATION_STATE.EVALUATION);
          }
        }}
      >
        <DoubleLeftOutlined />
        Previous
      </Button>
    );
  };

  const ReturnToDashboardButton = () => {
    const buttonLargeStyle: React.CSSProperties = {
      float: "left",
      // marginLeft: "25px",
    };
    const buttonMobileStyle: React.CSSProperties = {
      width: "100%",
    };
    return (
      <>
        <Button
          size={screens.lg ? "large" : "middle"}
          style={screens.lg ? buttonLargeStyle : buttonMobileStyle}
          onClick={() => {
            if (isFormDirty) {
              confirmNavigatingAway(() => {
                setIsFormDirty(false);
                navigate("/physician/dashboard", {
                  state: {
                    menuItemSelected: MENU_KEYS.DASHBOARD,
                    isMiniCEXFormDirty: false,
                  },
                });
              });
            } else {
              navigate("/physician/dashboard", {
                state: {
                  menuItemSelected: MENU_KEYS.DASHBOARD,
                  isMiniCEXFormDirty: false,
                },
              });
            }
          }}
        >
          Return to Dashboard
        </Button>
      </>
    );
  };

  const validateAdditionalTextbox = (rule: any, value: any) => {
    if (value) {
      var nonWhitespaceText = value.replace(/\s/g, "");
      if (nonWhitespaceText.length < 10) {
        return Promise.reject(
          new Error(
            "Please enter a minimum of 10 characters in the above field to proceed."
          )
        );
      } else {
        return Promise.resolve();
      }
    }
    return Promise.reject(
      new Error(
        "Please enter a minimum of 10 characters in the above field to proceed."
      )
    );
  };

  return (
    <>
      <Form
        form={form}
        onFinish={() => formSubmission(true)}
        layout="vertical"
        onFieldsChange={() => onFieldsChangeHandler()}
      >
        {/* <Prompt when={isFormDirty} message={() => MiniCEXFormNavigateAwayConfirmMessage} /> */}
        {isMedicalInterviewingRequired ? (
          <>
            {" "}
            <Text strong>
              <Text type="danger">* </Text>Medical Interviewing Skills
            </Text>
            <div>Score: {medicalInterviewingScore}</div>
            <em>
              Expectation for an individual who performs like a medical school
              graduate:
              <ul>
                <li>Facilitates accurate collection of a patient's history</li>
                <li>
                  Effectively uses questions/directions to obtain accurate
                  information needed
                </li>
                <li>History is organized and accurate</li>
              </ul>
            </em>
            <Paragraph>
              {" "}
              What did you observe during the clinical encounter that led to
              this score? Do not include any personally identifiable information
              about yourself, the applicant, or the patient.
            </Paragraph>
            <Form.Item
              name={MINICEX_QUESTION_NAMES_V1.MEDICAL_INTERVIEWING}
              required={true}
              rules={[
                {
                  validator: validateAdditionalTextbox,
                },
              ]}
              initialValue={medicalInterviewReason}
            >
              <TextArea
                onChange={(e: any) => {
                  setMedicalInterviewReason(e.target.value);
                }}
              ></TextArea>
            </Form.Item>
          </>
        ) : null}

        {isPhysicianExaminationRequired ? (
          <>
            {" "}
            <Text strong>
              <Text type="danger">* </Text>Physical Examination Skills
            </Text>
            <div> Score: {physicalExaminationScore}</div>
            <em>
              Expectation for an individual who performs like a medical school
              graduate:
              <ul>
                <li>Follows efficient, logical sequence</li>
                <li>
                  Focuses physical exam on patient problem or presentation
                </li>
                <li>Sensitive to patient’s modesty and comfort</li>
              </ul>
            </em>
            <Paragraph>
              What did you observe during the clinical encounter that led to
              this score? Do not include any personally identifiable information
              about yourself, the applicant, or the patient.
            </Paragraph>
            <Form.Item
              name={MINICEX_QUESTION_NAMES_V1.PHYSICAL_EXAMINATION}
              required={true}
              rules={[
                {
                  validator: validateAdditionalTextbox,
                },
              ]}
              initialValue={physicalExaminationReason}
            >
              <TextArea
                onChange={(e: any) => {
                  setPhysicalExaminationReason(e.target.value);
                }}
              ></TextArea>
            </Form.Item>
          </>
        ) : null}

        {isProfessionalismCommunicationRequired ? (
          <>
            {" "}
            <Text strong>
              <Text type="danger">* </Text>Professionalism / Communication
              Skills
            </Text>
            <div>Score: {professionalismCommunicationScore}</div>
            <em>
              Expectation for an individual who performs like a medical school
              graduate:
              <ul>
                <li>Shows respect, compassion, and empathy</li>
                <li>Establishes trust</li>
                <li>Responds appropriately to non-verbal cues</li>
              </ul>
            </em>
            <Paragraph>
              What did you observe during the clinical encounter that led to
              this score? Do not include any personally identifiable information
              about yourself, the applicant, or the patient.
            </Paragraph>
            <Form.Item
              name={MINICEX_QUESTION_NAMES_V1.COMMUNICATION_PROFESSIONALISM}
              required={true}
              rules={[
                {
                  validator: validateAdditionalTextbox,
                },
              ]}
              initialValue={professionalismCommunicationReason}
            >
              <TextArea
                onChange={(e: any) => {
                  setprofessionalismCommunicationReason(e.target.value);
                }}
              ></TextArea>
            </Form.Item>
          </>
        ) : null}

        {isClinicalReasoningRequired ? (
          <>
            {" "}
            <Text strong>
              <Text type="danger">* </Text>Clinical Reasoning & Judgment
            </Text>
            <div>Score: {clinicalReasoningScore}</div>
            <em>
              Expectation for an individual who performs like a medical school
              graduate:
              <ul>
                <li>
                  Delivers efficient oral presentation of the patient’s
                  problems, including differential diagnoses and recommendations
                  for next steps
                </li>
              </ul>
            </em>
            <Paragraph>
              What did you observe during the clinical encounter that led to
              this score? Do not include any personally identifiable information
              about yourself, the applicant, or the patient.
            </Paragraph>
            <Form.Item
              name={MINICEX_QUESTION_NAMES_V1.CLINICAL_REASONING}
              required={true}
              rules={[
                {
                  validator: validateAdditionalTextbox,
                },
              ]}
              initialValue={clinicalReasoningReason}
            >
              <TextArea
                onChange={(e: any) => {
                  setClinicalReasoningReason(e.target.value);
                }}
              ></TextArea>
            </Form.Item>
          </>
        ) : null}

        <br />

        <Text strong>Additional Feedback:</Text>
        <Paragraph>
          Is there additional information you would like to provide to ECFMG to
          assist in the evaluation of this applicant?{" "}
        </Paragraph>
        <Form.Item
          name="AdditionalDetails"
          initialValue={overallAdditionalInfo}
        >
          <TextArea
            onChange={(e) => {
              setOverAdditionalInfo(e.target.value);
            }}
          />
        </Form.Item>

        <br />
        {screens.lg ? (
          <>
            <Space>
              <PreviousButton />
              <ReturnToDashboardButton />
            </Space>
            <Space style={{ float: "right" }}>
              <Button
                size="large"
                onClick={async () => {
                  await formSubmission(false);
                }}
              >
                Save
              </Button>
              <Button size="large" type="primary" htmlType="submit">
                Continue <DoubleRightOutlined />
              </Button>
            </Space>
          </>
        ) : (
          <>
            <Space direction="vertical" style={{ width: "100%" }}>
              <div style={{ alignItems: "justify" }}>
                <Space
                  align="start"
                  style={{ justifyContent: "space-between", width: "100%" }}
                >
                  <PreviousButton />

                  <Button
                    size="middle"
                    onClick={async () => {
                      await formSubmission(false);
                    }}
                  >
                    Save
                  </Button>
                  <Button size="large" type="primary" htmlType="submit">
                    Continue <DoubleRightOutlined />
                  </Button>
                </Space>
              </div>
              <ReturnToDashboardButton />
            </Space>
          </>
        )}
        <Modal
          footer={null}
          visible={setMiniCEXLoading}
          closable={false}
          style={{ textAlign: "center" }}
          width="200px"
          destroyOnClose
        >
          <Spin /> Processing...
        </Modal>
      </Form>
    </>
  );
};

export default {
  Component: MiniCEXAdditionalInformation,
  Fragment: MiniCEXAdditionalInformationFragment,
};
