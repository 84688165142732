import { FC, useState, useEffect } from "react";
import { Button, Table, Modal, Card, List, Typography } from "antd";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import PropTypes, { InferProps } from "prop-types";
import moment from "moment";
import ReadOnlyMiniCEXFactory from "../read-only-miniCEX-factory";
import {
  PHYSICIAN_ALLOWED_ACTIONS,
  CEX_STATUS,
  DATE_FORMAT,
  ACTIVE_MINICEX_STATUSES,
  WITHDRAW_STATUSES,
} from "../../common/constants";
import _ from "lodash";
import {
  PhysicianDashboardClosedCardPhysicianPortalDocument,
  MiniCexSummaryType,
  PhysicianResponseType,
  MiniCexResponseType,
} from "../../generated";
import MiniCEXPDFFactory from "../miniCEX-pdf-factory";
import ReviewRejectedMiniCEXFactory from "../physician-review-rejected-mini-CEX-factory";

const { Text, Paragraph } = Typography;

const ComponentPropTypes = {
  loading: PropTypes.bool.isRequired,
};

export interface IProps {
  __typename?: "PhysicianClosedEncounterCard";
  physician?: PhysicianResponseType;
  minicexList: MiniCexSummaryType[];
  loading: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const PhysicianClosedEncounterCard: FC<ComponentProps> = ({
  minicexList,
  physician,
  loading,
}) => {
  const [isReadOnlyMiniCEXVisible, setIsReadOnlyMiniCEXVisible] =
    useState(false);
  const [
    gqlGetMiniCEX,
    {
      called: getMiniCEXCalled,
      loading: getMiniCEXLoading,
      data: getMiniCEXData,
    },
  ] = useLazyQuery(PhysicianDashboardClosedCardPhysicianPortalDocument, {
    fetchPolicy: "cache-and-network",
  });
  const [isViewReadOnlyMiniCEXClicked, setIsViewReadOnlyMiniCEXClicked] =
    useState(false);
  const [miniCEXSummary, setMiniCEXSummary] =
    useState<MiniCexResponseType | undefined>(undefined);
  const [encounterList, setEncounterList] = useState<any>([]);
  const [stateModel, setstateModel] = useState<any>();
  const [showEncounterHistoryModal, setShowEncounterHistoryModal] =
    useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [selectedMiniCEX, setSelectedMiniCEX] = useState<any>(undefined);

  const [
    gqlGetMiniCEXForRejection,
    {
      called: getMiniCEXForRejectionCalled,
      loading: getMiniCEXForRejectionLoading,
      data: getMiniCEXForRejectionData,
    },
  ] = useLazyQuery(PhysicianDashboardClosedCardPhysicianPortalDocument, {
    fetchPolicy: "network-only",
  });
  const [isViewRejectedMiniCEXClicked, setIsViewRejectedMiniCEXClicked] =
    useState(false);
  const [isRejectionReviewVisible, setIsRejectionReviewVisible] =
    useState(false);
  const [miniCEXForRejection, setMiniCEXForRejection] =
    useState<MiniCexResponseType | undefined>(undefined);

  useEffect(() => {
    if (minicexList) {
      var listData: any = [];
      minicexList?.map((record: any) => {
        listData.push({
          title: `${record.encounterId}`,
          record,
        });
      });
      setEncounterList(listData);
    }
  }, [minicexList]);

  useEffect(() => {
    // /currentRecord?.stateModel
    var filteredStateModel = currentRecord?.stateModel.filter(
      (item: { state: string }) => item.state !== ACTIVE_MINICEX_STATUSES[0]
    ); //ACTIVE_MINICEX_STATUSES[0] = MINICEX_CREATED_PHYSICIAN_INVITE_PENDING
    setstateModel(filteredStateModel);
  }, [currentRecord]);

  useEffect(() => {
    handleViewReadOnlyMiniCEXClick();
  }, [getMiniCEXData]);

  useEffect(() => {
    handleViewReadOnlyMiniCEXClick();
  }, [isViewReadOnlyMiniCEXClicked]);

  const handleViewReadOnlyMiniCEXClick = () => {
    if (getMiniCEXData) {
      setMiniCEXSummary(
        getMiniCEXData?.physicianPortal?.minicex as MiniCexResponseType
      );
      setIsReadOnlyMiniCEXVisible(true);
      setIsViewReadOnlyMiniCEXClicked(false);
    }
  };
  const viewReadOnlyMiniCEX = async (encounterId: string) => {
    gqlGetMiniCEX({ variables: { encounterId: encounterId } });
    setIsViewReadOnlyMiniCEXClicked(true);
  };

  // handle view rejection mini cex
  useEffect(() => {
    handleViewRejectionReviewClick();
  }, [isViewRejectedMiniCEXClicked]);

  useEffect(() => {
    handleViewRejectionReviewClick();
  }, [getMiniCEXForRejectionData]);

  const handleViewRejectionReviewClick = () => {
    if (getMiniCEXForRejectionData) {
      setMiniCEXForRejection(
        getMiniCEXForRejectionData?.physicianPortal
          ?.minicex as MiniCexResponseType
      );
      setIsRejectionReviewVisible(true);
      setIsViewRejectedMiniCEXClicked(false);
    }
  };

  const viewRejectionReview = async (encounterId: string) => {
    gqlGetMiniCEXForRejection({ variables: { encounterId: encounterId } });
    setIsViewRejectedMiniCEXClicked(true);
  };

  const historyColumns = [
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state: any, record: any) => (
        <div>
          {record.state !== undefined ? (
            <Text>{getStatus(CEX_STATUS, record.state) + " "}</Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
    {
      title: "Completed Date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: any, record: any) => (
        <div>
          {record.timestamp !== undefined ? (
            <Text>
              {moment(record.timestamp)
                .utc()
                .format(`${DATE_FORMAT}, h:mm:ss a`)}
            </Text>
          ) : (
            <Text></Text>
          )}
        </div>
      ),
    },
  ];

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  return (
    <>
      <List
        dataSource={encounterList}
        itemLayout="vertical"
        pagination={{ pageSize: 10 }}
        loading={
          loading ||
          (getMiniCEXLoading && getMiniCEXCalled) ||
          (getMiniCEXForRejectionLoading && getMiniCEXForRejectionCalled)
        }
        renderItem={(item: any) => (
          <List.Item key={item.title}>
            <Card>
              <div>
                <Text strong>Encounter ID:</Text> {item?.record?.encounterId}
              </div>
              <div>
                <Text strong>USMLE/ECFMG ID:</Text> {item?.record?.usmleId}
              </div>
              <div>
                <Text strong>Applicant's Name: </Text>
                {item?.record?.applicantLastName !== undefined ||
                item?.record?.applicantRestOfName !== undefined
                  ? item?.record?.applicantRestOfName +
                    " " +
                    item?.record?.applicantLastName
                  : ""}
              </div>
              <div>
                <Text strong>Status: </Text>{" "}
                {item?.record?.state !== undefined ? (
                  <>
                    {getStatus(CEX_STATUS, item?.record?.state) + " "}
                    <InfoCircleOutlined
                      style={{ fontSize: "18px" }}
                      onClick={(event: any) => {
                        setCurrentRecord(item?.record);
                        setShowEncounterHistoryModal(true);
                      }}
                    />
                  </>
                ) : (
                  <div></div>
                )}
              </div>
              <div>
                <Text strong>Date Requested: </Text>{" "}
                {item?.record?.createdAt ? (
                  <div>
                    {moment(item?.record?.createdAt).utc().format(DATE_FORMAT)}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div>
                <Text strong>Last Action: </Text>{" "}
                {item?.record?.completedAt ? (
                  <div>
                    {moment(item?.record?.completedAt)
                      .utc()
                      .format(DATE_FORMAT)}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div>
                {item?.record?.allowedActions.includes(
                  PHYSICIAN_ALLOWED_ACTIONS.VIEW
                ) &&
                _.indexOf(WITHDRAW_STATUSES, item?.record?.state) === -1 ? (
                  <>
                    <Button
                      type="primary"
                      style={{ width: "80px" }}
                      onClick={async () => {
                        if (item?.record?.rejectionReason) {
                          let tempSeletedMiniCEX = _.find(minicexList, {
                            encounterId: item?.record?.encounterId,
                          });
                          await viewRejectionReview(item?.record.encounterId);
                        } else {
                          await viewReadOnlyMiniCEX(item?.record?.encounterId);
                        }
                      }}
                      loading={getMiniCEXCalled && getMiniCEXLoading}
                      icon={<SearchOutlined />}
                    >
                      View
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Card>
          </List.Item>
        )}
      ></List>
      <Modal
        title="Review Completed Mini-CEX"
        visible={isReadOnlyMiniCEXVisible}
        closable={false}
        width={"70%"}
        onCancel={() => {
          setIsReadOnlyMiniCEXVisible(false);
        }}
        destroyOnClose
        footer={[
          <Button
            size="large"
            onClick={() => setIsReadOnlyMiniCEXVisible(false)}
          >
            Close
          </Button>,
          miniCEXSummary ? (
            <>
              <Button type="primary" size="large">
                <MiniCEXPDFFactory
                  miniCEX={miniCEXSummary}
                  physician={physician}
                />
              </Button>
            </>
          ) : null,
        ]}
      >
        <ReadOnlyMiniCEXFactory
          minicexSummary={miniCEXSummary}
          physician={physician}
        />
      </Modal>
      <Modal
        title={"Status History"}
        visible={showEncounterHistoryModal}
        closable={false}
        footer={[
          <Button
            size="large"
            key="close"
            onClick={() => setShowEncounterHistoryModal(false)}
          >
            Close
          </Button>,
        ]}
      >
        <div>
          Applicant:{" "}
          <Text
            strong
          >{`${currentRecord?.applicantLastName}, ${currentRecord?.applicantRestOfName}`}</Text>
          <br />
          Encounter ID: <Text strong>{currentRecord?.encounterId}</Text>
          <br />
          <br />
          <Table columns={historyColumns} dataSource={stateModel}></Table>
          <Paragraph>
            All times are calculated using Coordinated Universal Time (UTC).
          </Paragraph>
        </div>
      </Modal>

      <Modal
        title="Review Rejected Mini-CEX"
        visible={isRejectionReviewVisible}
        width={"70%"}
        onCancel={() => {
          setIsRejectionReviewVisible(false);
        }}
        destroyOnClose
        footer={[
          <Button
            size="large"
            onClick={() => setIsRejectionReviewVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <ReviewRejectedMiniCEXFactory
          miniCEX={miniCEXForRejection}
          physician={physician}
        />
      </Modal>
    </>
  );
};
export default PhysicianClosedEncounterCard;
