import moment from 'moment-timezone';

function replaceTokens(str: string, mapObj: any){
    var re = new RegExp(Object.keys(mapObj).join("|"),"g");

    return str.replace(re, function(matched){
        return mapObj[matched];
    });
}

export default function(impendingMsg: string, systemMsg: string, impendingTimestamp: string, startTimestamp: string, endTimestamp: string, currTime: any, maintenanceUpcoming: string) {
    let resp:any = {
        maintenanceType : "NONE",
        maintenanceMsg: null
    };

    if(maintenanceUpcoming === "true"){
        let startTimestampStr = moment(startTimestamp).tz('America/New_York').format('h:mm a on dddd, MMMM DD, YYYY');
        let endTimestampStr = moment(endTimestamp).tz('America/New_York').format('h:mm a on dddd, MMMM DD, YYYY');
        let startDateStr = moment(startTimestamp).tz('America/New_York').format('MMMM DD');
        let endDateStr = moment(endTimestamp).tz('America/New_York').format('MMMM DD');
        let timeRangeStr = startDateStr === endDateStr ? startDateStr : `${startDateStr} - ${endDateStr}`
        let mapObj = {
            '##startTimestampStr##': startTimestampStr,
            '##endTimestampStr##': endTimestampStr,
            '##timeRangeStr##': timeRangeStr
        }

        if(moment(startTimestamp).isBefore(currTime) === true && 
            moment(endTimestamp).isAfter(currTime) === true){
                resp = {
                    maintenanceType : "SYSTEM",
                    maintenanceMsg: replaceTokens(systemMsg,mapObj)
                };
        }
        else if(moment(impendingTimestamp).isBefore(currTime) === true && 
            moment(startTimestamp).isAfter(currTime) === true){
                resp = {
                    maintenanceType : "IMPENDING",
                    maintenanceMsg: replaceTokens(impendingMsg,mapObj)
                };
        }
    }

    return resp


}