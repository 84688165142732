import { FC, useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Col, Drawer, Menu, Modal, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Header } from "antd/lib/layout/layout";
import { useNavigate, useLocation } from "react-router-dom";
import {
  confirmNavigatingAway,
  LOGIN_TYPE,
  MENU_KEYS,
  MiniCEXFormNavigateAwayConfirmMessage,
} from "../common/constants";
import { useMsal } from "../components/msal-react-lite";
import PropTypes, { InferProps } from "prop-types";
import ECFMGLogo from "../logos/ECFMG_NoTag_WHITE_TM_forSmall.svg";

const LoginType = LOGIN_TYPE.PhysicianLogin;

interface ILocationState {
  menuItemSelected: string;
  isMiniCEXFormDirty: boolean;
}
const ComponentPropTypes = {
  isPhysicianOptedOut: PropTypes.bool,
};

export interface IProps {
  isPhysicianOptedOut?: boolean;
}

type LocationState = {
  menuItemSelected: string;
  isMiniCEXFormDirty: boolean;
};

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;
const HeaderContent: FC<ComponentProps> = ({ isPhysicianOptedOut }) => {
  const screens = useBreakpoint();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState(MENU_KEYS.LOGOUT);
  const { logout } = useMsal();

  useEffect(() => {
    setSelectedMenu((location?.state as LocationState)?.menuItemSelected); //Highlight the correct menu item on change
  }, [location?.state]);

  useEffect(() => {
    if (location.pathname.includes("profile-management")) {
      setSelectedMenu(MENU_KEYS.PROFILE);
      return;
    }
    if (location.pathname.includes("instructions")) {
      setSelectedMenu(MENU_KEYS.INSTRUCTIONS);
      return;
    }
    if (location.pathname.includes("dashboard")) {
      setSelectedMenu(MENU_KEYS.DASHBOARD);
    }
  }, [location]);

  const doLogout = async (menuKey: any) => {
    setSelectedMenu(menuKey);

    await logout(LoginType);
    navigate(`/`, {
      state: { menuItemSelected: MENU_KEYS.LOGOUT, isMiniCEXFormDirty: false },
    });
  };

  const getMenu = (mode: any) => {
    if (isPhysicianOptedOut === undefined) {
      return null;
    }
    const getVerticalMenuItems = () => (
      <>
        <Menu.Item key={MENU_KEYS.INSTRUCTIONS}>Instructions</Menu.Item>
        <Menu.Item key={MENU_KEYS.DASHBOARD}>Dashboard</Menu.Item>
        <Menu.Item key={MENU_KEYS.PROFILE}>Profile</Menu.Item>
        <Menu.Item key={MENU_KEYS.LOGOUT}>Logout</Menu.Item>
      </>
    );
    const getHorizontalMenuItems = () => (
      <>
        <Menu.Item key={MENU_KEYS.INSTRUCTIONS}>Instructions</Menu.Item>
        <Menu.Item key={MENU_KEYS.DASHBOARD}>Dashboard</Menu.Item>
        <Menu.Item key={MENU_KEYS.PROFILE}>Profile</Menu.Item>
        <Menu.Item key={MENU_KEYS.LOGOUT} style={{ marginLeft: "auto" }}>
          Logout
        </Menu.Item>
      </>
    );
    const getMenuItems = () => {
      if (isPhysicianOptedOut) {
        return (
          <>
            <Menu.Item key={MENU_KEYS.LOGOUT} style={{ marginLeft: "auto" }}>
              Logout
            </Menu.Item>
          </>
        );
      }
      if (mode === "vertical") {
        return getVerticalMenuItems();
      }
      return getHorizontalMenuItems();
    };
    return (
      <Menu
        onClick={(e) => menuItemSelected(e)}
        theme={mode === "vertical" ? "light" : "dark"}
        mode={mode}
        style={{ border: "0" }}
        defaultSelectedKeys={[selectedMenu]}
        selectedKeys={[selectedMenu]}
      >
        {getMenuItems()}
      </Menu>
    );
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  const menuItemSelected = async (e: any) => {
    setDrawerVisible(false);
    switch (e.key) {
      case MENU_KEYS.LOGOUT:
        if (!(location?.state as LocationState)?.isMiniCEXFormDirty) {
          Modal.confirm({
            title: MENU_KEYS.LOGOUT,
            // icon: <LogoutOutlined />,
            icon: null,
            content: "Are you sure you want to logout?",
            okText: "Logout",
            cancelText: "Cancel",
            okButtonProps: { size: "large" },
            cancelButtonProps: { size: "large" },
            onOk: async () => {
              await doLogout(e.key);
            },
          });
        } else {
          Modal.confirm({
            title: MENU_KEYS.LOGOUT,
            // icon: <ExclamationCircleOutlined />,
            icon: null,
            content: MiniCEXFormNavigateAwayConfirmMessage,
            okText: "Logout",
            cancelText: "Cancel",
            okButtonProps: { size: "large" },
            cancelButtonProps: { size: "large" },
            onOk: async () => {
              await doLogout(e.key);
            },
          });
        }

        break;
      case MENU_KEYS.INSTRUCTIONS:
        if (!(location?.state as LocationState)?.isMiniCEXFormDirty) {
          navigate(`/physician/instructions`, {
            state: { menuItemSelected: e.key },
          });
        } else {
          confirmNavigatingAway(() => {
            navigate(`/physician/instructions`, {
              state: { menuItemSelected: e.key, isMiniCEXFormDirty: false },
            });
          });
        }

        break;
      case MENU_KEYS.PROFILE:
        if (!(location?.state as LocationState)?.isMiniCEXFormDirty) {
          navigate(`/physician/profile-management`, {
            state: { menuItemSelected: e.key },
          });
        } else {
          confirmNavigatingAway(() => {
            navigate(`/physician/profile-management`, {
              state: { menuItemSelected: e.key, isMiniCEXFormDirty: false },
            });
          });
        }
        break;

      case MENU_KEYS.DASHBOARD:
        if (!(location?.state as LocationState)?.isMiniCEXFormDirty) {
          navigate(`/physician/dashboard`, {
            state: { menuItemSelected: e.key },
          });
        } else {
          confirmNavigatingAway(() => {
            navigate(`/physician/dashboard`, {
              state: { menuItemSelected: e.key, isMiniCEXFormDirty: false },
            });
          });
        }

        break;
    }
  };
  return (
    <>
      {screens.xs ? (
        <>
          <Header
            style={{
              position: "fixed",
              zIndex: 1,
              width: "100%",
              padding: "0 20px",
            }}
          >
            <Row>
              <Col span={8}>
                <MenuOutlined
                  style={{ fontSize: "16px", color: "white" }}
                  onClick={() => {
                    setDrawerVisible(true);
                  }}
                />
                <Drawer
                  placement="left"
                  closable={false}
                  onClose={onDrawerClose}
                  visible={drawerVisible}
                  height="auto"
                >
                  {getMenu("vertical")}
                </Drawer>
              </Col>
              <Col span={8}>
                <img className="logo" src={ECFMGLogo} />
              </Col>
              <Col span={8}></Col>
            </Row>
          </Header>
        </>
      ) : (
        <>
          <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
            <img className="logo" src={ECFMGLogo} />
            {getMenu("horizontal")}
          </Header>
        </>
      )}
    </>
  );
};
export default HeaderContent;
