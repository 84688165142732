import { FC } from "react";
import { Grid, Descriptions } from "antd";
import { convertDate } from "../../../common/constants";
import { MiniCexSummaryType } from "../../../generated";

const { useBreakpoint } = Grid;

export interface IProps {
  summary?: MiniCexSummaryType;
}

export type ComponentProps = IProps;

const ApplicantDetailsDesktop: FC<ComponentProps> = ({ summary }) => {
  const screens = useBreakpoint();
  return (
    <>
      {screens.lg ? (
        <Descriptions
          className="ecfmg-small-descriptions"
          size="small"
          layout="vertical"
          column={5}
          style={{
            borderStyle: "solid",
            borderWidth: "0.5px",
            padding: "20px",
          }}
        >
          <Descriptions.Item label="Applicant">{`${summary?.applicantLastName}, ${summary?.applicantRestOfName}`}</Descriptions.Item>
          <Descriptions.Item label="USMLE/ECFMG ID">
            {summary?.usmleId}
          </Descriptions.Item>
          <Descriptions.Item label="Encounter ID">
            {summary?.encounterId}
          </Descriptions.Item>
          <Descriptions.Item label="Date Requested">
            {summary ? convertDate(summary?.createdAt) : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Date Accepted">
            {summary ? convertDate(summary?.acceptedAt) : ""}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Descriptions
          className="ecfmg-small-descriptions"
          size="small"
          layout="vertical"
          column={2}
          style={{
            borderStyle: "solid",
            borderWidth: "0.5px",
            padding: "20px",
          }}
        >
          <Descriptions.Item label="Applicant">{`${summary?.applicantLastName}, ${summary?.applicantRestOfName}`}</Descriptions.Item>
          <Descriptions.Item label="USMLE/ECFMG ID">
            {summary?.usmleId}
          </Descriptions.Item>
          <Descriptions.Item label="Encounter ID">
            {summary?.encounterId}
          </Descriptions.Item>
          <Descriptions.Item label="Date Requested">
            {summary ? convertDate(summary?.createdAt) : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Date Accepted">
            {summary ? convertDate(summary?.acceptedAt) : ""}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export default {
  Component: ApplicantDetailsDesktop,
};
