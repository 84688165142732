import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Layout, Button, Typography, Space } from "antd";
import { useMsal } from "./components/msal-react-lite";
import Maintenance from "./components/maintenance/maintenance";
import ImpendingMaintenance from "./components/maintenance/impendingMaintenance";
import { LOGIN_TYPE } from "./common/constants";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Helmet } from "react-helmet";
import FooterLayout from "./footer-layout";
import UnauthHeader from "./unauth-header";

const { Header, Content, Footer } = Layout;
const { Title, Link, Text } = Typography;
const Login: FC<any> = () => {
  const { login, getAuthToken, getIsLoggedIn } = useMsal();
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const [isPhysLoginButtonDisabled, setIsPhysLoginButtonDisabled] =
    useState<boolean>(false);
  const screens = useBreakpoint();
  const navigate = useNavigate();

  var loginAction = async (loginType: string) => {
    console.log("login action triggered");
    setIsLoginButtonDisabled(true);
    setIsPhysLoginButtonDisabled(true);
    await login(loginType);

    //Disable/Enable login button based on login status
    if (getIsLoggedIn(loginType)) {
      setIsLoginButtonDisabled(true);
      setIsPhysLoginButtonDisabled(true);
    } else {
      setIsLoginButtonDisabled(false);
      setIsPhysLoginButtonDisabled(false);
    }

    var authToken = await getAuthToken(loginType);
    if (!(authToken === null || typeof authToken === "undefined")) {
      if (loginType === LOGIN_TYPE.EMSWPLogin) {
        navigate(
          "/attestation/instructions",
          { state: { menuItemSelected: "2" } } //Instruction page is menu item #2
        );
      } else if (loginType === LOGIN_TYPE.PhysicianLogin) {
        navigate("/physician/gateway");
      }
    }
  };

  const loginLayout = () => {
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            textAlign: "center",
            marginBottom: screens.xs ? "30px" : "40px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              display: "inline-block",
              maxWidth: "1024px",
            }}
          >
            <div style={{ textAlign: "left", margin: "0" }}>
              <Title level={2}>
                Clinical Skills Evaluation and Attestation Portal
              </Title>
            </div>
          </div>
        </div>
        {screens.sm ? (
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Row>
              {/* left col */}
              <Col
                span={12}
                style={{
                  borderRight: "1px solid",
                  paddingRight: "20px",
                }}
              >
                <Title level={3}>
                  Pathways 3, 4, &amp; 5 Clinical Skills Attestation
                </Title>
              </Col>
              <Col span={12} style={{ paddingLeft: "20px" }}>
                <Title level={3}>
                  Pathway 6 Mini-Clinical Evaluation Exercise (Mini-CEX)
                </Title>
              </Col>
            </Row>
            <Row>
              {/* left col */}
              <Col
                span={12}
                style={{
                  borderRight: "1px solid",
                  paddingRight: "20px",
                }}
              >
                <Title level={4}>
                  <Text style={{ fontWeight: 500, color: "#333333" }}>
                    Medical School Officials:
                  </Text>
                </Title>
                <p>
                  Log in here using your ECFMG Medical School Web Portal (EMSWP)
                  username and password to complete Clinical Skills Attestations
                  for students and graduates of your medical school who have
                  applied to Pathway 3, 4, or 5 to meet the clinical and
                  communication skills requirements for ECFMG Certification.{" "}
                  <Link
                    href="https://www.ecfmg.org/certification-pathways/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Detailed information on the Pathways
                  </Link>{" "}
                  is available on the ECFMG website. If you have any difficulty
                  logging in, please contact us at{" "}
                  <Link
                    href="mailto:msattestations@ecfmg.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    msattestations@ecfmg.org
                  </Link>.
                </p>
              </Col>
              <Col span={12} style={{ paddingLeft: "20px" }}>
                <Title level={4}>
                  <Text style={{ fontWeight: 500, color: "#333333" }}>
                    Physician Evaluators:
                  </Text>
                </Title>
                <p>
                  Log in here to complete Mini-CEX evaluations for individuals
                  who have applied to Pathway 6 to meet the clinical and
                  communication skills requirements for ECFMG Certification.{" "}
                  <Link
                    href="https://www.ecfmg.org/certification-pathways/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Detailed information on the Pathways
                  </Link>{" "}
                  is available on the ECFMG website. If you have any questions
                  about this process or need assistance to complete a Mini-CEX
                  for an applicant, please contact us at{" "}
                  <Link
                    href="mailto:casemanager@ecfmg.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    casemanager@ecfmg.org
                  </Link>.
                </p>
              </Col>
            </Row>
            <Row>
              {/* left col */}
              <Col
                span={12}
                style={{
                  textAlign: "center",
                  borderRight: "1px solid",
                }}
              >
                <Button
                  size="large"
                  type="primary"
                  onClick={() => loginAction(LOGIN_TYPE.EMSWPLogin)}
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  disabled={isLoginButtonDisabled}
                >
                  Medical School Login
                </Button>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: "center",
                }}
              >
                <Button
                  size="large"
                  type="primary"
                  onClick={() => loginAction(LOGIN_TYPE.PhysicianLogin)}
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  disabled={isPhysLoginButtonDisabled}
                >
                  Physician Evaluator Login
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <>
            <Row style={{ borderBottom: "1px solid", paddingBottom: "20px" }}>
              <Col>
                <Title level={3}>
                  Pathways 3, 4, &amp; 5 Clinical Skills Attestation
                </Title>
                <Title level={4}>
                  <Text italic style={{ fontWeight: 500, color: "#333333" }}>
                    Medical School Officials:
                  </Text>
                </Title>
                <p>
                  Log in here using your ECFMG Medical School Web Portal (EMSWP)
                  username and password to complete Clinical Skills Attestations
                  for students and graduates of your medical school who have
                  applied to Pathway 3, 4, or 5 to meet the clinical and
                  communication skills requirements for ECFMG Certification.{" "}
                  <Link
                    href="https://www.ecfmg.org/certification-pathways/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Detailed information on the Pathways
                  </Link>{" "}
                  is available on the ECFMG website. If you have any difficulty
                  logging in, please contact us at{" "}
                  <Link
                    href="mailto:msattestations@ecfmg.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    msattestations@ecfmg.org
                  </Link>.
                </p>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => loginAction(LOGIN_TYPE.EMSWPLogin)}
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                    disabled={isLoginButtonDisabled}
                  >
                    Medical School Login
                  </Button>
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <Col>
                <Title level={3}>
                  Pathway 6 Mini-Clinical Evaluation Exercise (Mini-CEX)
                </Title>
                <Title level={4}>
                  <Text italic style={{ fontWeight: 500, color: "#333333" }}>
                    Physician Evaluators:
                  </Text>
                </Title>
                <p>
                  Log in here to complete Mini-CEX evaluations for individuals
                  who have applied to Pathway 6 to meet the clinical and
                  communication skills requirements for ECFMG Certification.{" "}
                  <Link
                    href="https://www.ecfmg.org/certification-pathways/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Detailed information on the Pathways
                  </Link>{" "}
                  is available on the ECFMG website. If you have any questions
                  about this process or need assistance to complete a Mini-CEX
                  for an applicant, please contact us at{" "}
                  <Link
                    href="mailto:casemanager@ecfmg.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    casemanager@ecfmg.org
                  </Link>.
                </p>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => loginAction(LOGIN_TYPE.PhysicianLogin)}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    disabled={isPhysLoginButtonDisabled}
                  >
                    Physician Evaluator Login
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Verify Clinical Skills</title>
      </Helmet>
      <Layout className="layout">
        <UnauthHeader />

        <Layout>
          <Header style={{ width: "100%" }}></Header>
          <Content
            className="site-layout"
            style={{
              marginTop: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                flex: "1 auto",
                display: "flex",
                flexFlow: "column",
                borderRadius: "0 0 0.8rem 0.8rem",
              }}
            >
              <ImpendingMaintenance ShowImpendingMessageImmediately={true}>
                <Maintenance>{loginLayout()}</Maintenance>
              </ImpendingMaintenance>
            </div>
          </Content>

          <FooterLayout />
        </Layout>
      </Layout>
    </>
  );
};

export default Login;
