import { Spin, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const RequireAuth: any = (params: any) => {
  console.log(`params.isAuthenticated :${params.isAuthenticated}`);
  console.log(params);
  const navigate = useNavigate();
  if (typeof params.isAuthenticated === "undefined") {
    //still loading
    return (
      <div style={{ textAlign: "center", margin: "80px 40px" }}>
        <Spin size="large" />
        <Title level={2}>Loading...</Title>
      </div>
    );
  }
  return params.isAuthenticated === true ? params.children : navigate("/");
};
export default RequireAuth;
