import { FC, useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Radio,
  Space,
  Form,
  message,
  Typography,
  Descriptions,
  Grid,
} from "antd";
import PropTypes, { InferProps } from "prop-types";
import { GroupBox } from "../../common/group-box";
import {
  convertDate,
  getPhysicianName,
  MINICEX_REJECTION_REASONS,
  MINICEX_REJECTION_REASON_KEYS,
  PROFILE_STATUS,
} from "../../common/constants";

const { useBreakpoint } = Grid;

const { Text, Paragraph, Link } = Typography;

interface AttestationResponse {
  acceptMiniCEX: boolean;
  rejectionReason?: string;
  rejectionReasonComments?: string;
}

const ComponentPropTypes = {
  currentRecord: PropTypes.shape({
    encounterId: PropTypes.string.isRequired,
    physicianEmail: PropTypes.string.isRequired,
    usmleId: PropTypes.string.isRequired,
    applicantLastName: PropTypes.string.isRequired,
    applicantRestOfName: PropTypes.string.isRequired,
    physicianLastNameProvidedByApplicant: PropTypes.string.isRequired,
    physicianRestOfNameProvidedByApplicant: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    expiresAt: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    stateModel: PropTypes.arrayOf(
      PropTypes.shape({
        state: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
      })
    ),
    allowedActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  currentIndex: PropTypes.number.isRequired,
  closeAttestationModal: PropTypes.func.isRequired,
  onReviewResponse: PropTypes.func.isRequired,
  profileStatus: PropTypes.string,
  acceptMiniCEXLoading: PropTypes.bool,
  rejectMiniCEXLoading: PropTypes.bool,
};

export interface IProps {
  __typename?: "PhysicianAttestation";
  currentRecord: {
    encounterId: string;
    physicianEmail: string;
    usmleId: string;
    applicantLastName: string;
    applicantRestOfName: string;
    physicianLastNameProvidedByApplicant: string;
    physicianRestOfNameProvidedByApplicant: string;
    createdAt: string;
    expiresAt: string;
    state: string;
    stateModel?: {
      state: string;
      timestamp: string;
    }[];
    allowedActions: string[];
  };
  currentIndex: number;
  closeAttestationModal: () => void;
  onReviewResponse: (record: any, response: any, index: any) => void;
  profileStatus?: string;
  acceptMiniCEXLoading?: boolean;
  rejectMiniCEXLoading?: boolean;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const PhysicianAttestation: FC<ComponentProps> = ({
  currentRecord,
  currentIndex,
  closeAttestationModal,
  onReviewResponse,
  profileStatus,
  acceptMiniCEXLoading,
  rejectMiniCEXLoading,
}) => {
  const screens = useBreakpoint();
  const { TextArea } = Input;
  const [requestChoice, setRequestChoice] = useState<number>(0);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [otherTextAreaHidden, setOtherTextAreaHidden] = useState<boolean>(true);
  const [otherText, setOtherText] = useState<string>("");
  const [otherTextRequired, setOtherTextRequired] = useState<boolean>(false);
  const [acceptRequestVisible, setAcceptRequestVisible] =
    useState<boolean>(true);
  const [showNoSelectionError, setShowNoSelectionError] =
    useState<boolean>(false);

  useEffect(() => {
    if (profileStatus === PROFILE_STATUS.NOT_COMPLETED) {
      setAcceptRequestVisible(false);
    }
  }, [profileStatus]);

  useEffect(() => {
    setShowNoSelectionError(false); //Clear error message
    if (requestChoice === 1) {
      setRejectReason("");
    }
  }, [requestChoice]);

  useEffect(() => {
    if (rejectReason === MINICEX_REJECTION_REASON_KEYS.OTHER) {
      setOtherTextAreaHidden(false);
      setOtherTextRequired(true);
    } else {
      setOtherTextAreaHidden(true);
      setOtherTextRequired(false);
      setOtherText("");
    }
  }, [rejectReason]);

  const handleClose = () => {
    closeAttestationModal();
    clearAttestationFormData();
    setShowNoSelectionError(false); //Clear error message
  };

  const clearAttestationFormData = () => {
    setRequestChoice(0);
    setRejectReason("");
    setOtherText("");
  };

  const validateOtherTextbox = (rule: any, value: any) => {
    if (otherTextRequired) {
      var nonWhitespaceText = otherText.replace(/\s/g, "");
      if (nonWhitespaceText.length < 10) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  const onFinish = (values: any) => {
    setShowNoSelectionError(false); //Clear error message
    handleOnReviewResponse(currentRecord);
  };

  const handleOnReviewResponse = (record: any) => {
    var response: AttestationResponse;

    if (requestChoice > 0) {
      if (requestChoice === 1) {
        //Accept mini-CEX
        response = {
          acceptMiniCEX: true,
        };
        onReviewResponse(record, response, currentIndex);
        clearAttestationFormData();
      } else {
        //Reject mini-CEX
        if (rejectReason !== "") {
          if (rejectReason === "OTHER") {
            //If OTHER, capture text
            response = {
              acceptMiniCEX: false,
              rejectionReason: rejectReason,
              rejectionReasonComments: otherText,
            };
          } else {
            response = {
              acceptMiniCEX: false,
              rejectionReason: rejectReason,
            };
          }
          onReviewResponse(record, response, currentIndex);
          clearAttestationFormData();
        } else {
          message.error("Please select a reason");
        }
      }
    } else {
      setShowNoSelectionError(true);
    }
  };

  return (
    <div>
      <GroupBox title="Applicant Information">
        {screens.lg ? (
          <Descriptions
            className="ecfmg-small-descriptions"
            size="small"
            layout="vertical"
            column={5}
            bordered={false}
            style={{ padding: "10px" }}
          >
            <Descriptions.Item label="Applicant">
              {currentRecord?.applicantLastName +
                ", " +
                currentRecord?.applicantRestOfName}
            </Descriptions.Item>
            <Descriptions.Item label="USMLE/ECFMG ID">
              {currentRecord?.usmleId}
            </Descriptions.Item>
            <Descriptions.Item label="Encounter ID">
              {currentRecord?.encounterId}
            </Descriptions.Item>
            <Descriptions.Item label="Date Requested">
              {convertDate(currentRecord?.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Physician Name from Applicant">
              {getPhysicianName(
                currentRecord?.physicianLastNameProvidedByApplicant,
                currentRecord?.physicianRestOfNameProvidedByApplicant
              )}
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <Descriptions
            className="ecfmg-small-descriptions"
            size="small"
            layout="vertical"
            column={2}
            bordered={false}
            style={{
              padding: "20px",
            }}
          >
            <Descriptions.Item label="Applicant">
              {currentRecord?.applicantLastName +
                ", " +
                currentRecord?.applicantRestOfName}
            </Descriptions.Item>
            <Descriptions.Item label="USMLE/ECFMG ID">
              {currentRecord?.usmleId}
            </Descriptions.Item>
            <Descriptions.Item label="Encounter ID">
              {currentRecord?.encounterId}
            </Descriptions.Item>
            <Descriptions.Item label="Date Requested">
              {convertDate(currentRecord?.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Physician Name from Applicant">
              {getPhysicianName(
                currentRecord?.physicianLastNameProvidedByApplicant,
                currentRecord?.physicianRestOfNameProvidedByApplicant
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </GroupBox>
      <br />
      <Paragraph>
        You have been asked to complete an ECFMG Mini-Clinical Evaluation
        Exercise (Mini-CEX) for Pathway 6 as part of the above-named
        individual’s application to meet the clinical and communication skills
        requirements for ECFMG Certification. Please review the{" "}
        <Link
          href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#physician-evals"
          target="_blank"
          rel="noreferrer"
        >
          Criteria for Acceptable Physician Evaluators
        </Link>{" "}
        and the{" "}
        <Link
          href="https://www.ecfmg.org/certification-pathways/info-p6-evaluators.html#clinical-encounters"
          target="_blank"
          rel="noreferrer"
        >
          Criteria for Acceptable Clinical Encounters
        </Link>{" "}
        on the ECFMG website before deciding whether to accept this request.
      </Paragraph>
      {showNoSelectionError && (
        <Paragraph type="danger">
          Please select one of the options below
        </Paragraph>
      )}
      <Form onFinish={onFinish} layout="vertical">
        <Radio.Group
          onChange={(e) => setRequestChoice(e.target.value)}
          value={requestChoice}
        >
          <Space direction="vertical">
            {acceptRequestVisible && (
              <Radio value={1}>
                <Paragraph
                  style={{
                    whiteSpace: "normal",
                    paddingLeft: "25px",
                    marginTop: "-20px",
                  }}
                >
                  I accept this request to complete the Mini-CEX evaluation for
                  this applicant. I confirm that I meet the Criteria for
                  Acceptable Physician Evaluators as described on the ECFMG
                  website. I confirm that I will work with the applicant to
                  identify a clinical encounter that meets the Criteria for
                  Acceptable Clinical Encounters as described on the ECFMG
                  website and will observe and evaluate the applicant’s clinical
                  skills as demonstrated during an encounter that meets these
                  criteria. I further confirm that a clinical encounter has not
                  yet taken place for this request.
                </Paragraph>
              </Radio>
            )}
            <Radio value={2}>
              <Paragraph
                style={{
                  whiteSpace: "normal",
                  paddingLeft: "25px",
                  marginTop: "-20px",
                }}
              >
                I do not accept this request to complete the Mini-CEX for this
                applicant.
              </Paragraph>
            </Radio>
          </Space>
        </Radio.Group>
        {requestChoice === 2 && (
          <div style={{ paddingLeft: "25px" }}>
            <Paragraph style={{ color: "#333333" }} strong>
              Reason for Rejection:
            </Paragraph>
            <Radio.Group
              onChange={(e) => setRejectReason(e.target.value)}
              value={rejectReason}
            >
              {MINICEX_REJECTION_REASONS.map((reason) => (
                <Row key={reason.key}>
                  <Col span={24}>
                    <Radio value={reason.key}>
                      <div
                        style={{
                          whiteSpace: "normal",
                          paddingLeft: "25px",
                          marginTop: "-20px",
                        }}
                      >
                        {reason.value}
                      </div>
                    </Radio>
                  </Col>
                </Row>
              ))}
            </Radio.Group>
            <Form.Item
              hidden={otherTextAreaHidden}
              name="otherTextbox"
              label="Explanation:"
              rules={[
                {
                  validator: validateOtherTextbox,
                  message:
                    "Please enter a minimum of 10 characters in the Explanation field to proceed.",
                },
              ]}
            >
              <TextArea
                style={{ marginBottom: "3px" }}
                onChange={(e) => setOtherText(e.target.value)}
                value={otherText}
              ></TextArea>
            </Form.Item>
          </div>
        )}
        <Row justify="end">
          <Col>
            <Space>
              <Button
                size="large"
                style={{ marginRight: "3px" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                loading={acceptMiniCEXLoading || rejectMiniCEXLoading}
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
