import { FC } from "react";
import { Col, Row, Typography, Layout, Grid, Space } from "antd";
import ECFMGLogo from "./logos/ECFMG_Horz_Full_Color_Division.svg";
const { Title } = Typography;
const { Header } = Layout;
const { useBreakpoint } = Grid;

const UnauthHeader: FC<any> = (props) => {
  const screens = useBreakpoint();

  return (
    <>
      {screens.xs ? (
        <Header
          className="header-logo"
          style={{ alignContent: "center", height: "100%" }}
        >
          <Row style={{ height: "100%", paddingTop: "20px" }}>
            <Col span={24}>
              <Space
                direction={"vertical"}
                size={"small"}
                style={{ alignItems: "center", height: "100%" }}
              >
                <img
                  src={ECFMGLogo}
                  style={{
                    flex: 1,
                    height: "75%",
                    width: "100%",
                    resize: "block",
                    paddingLeft: 0,
                    float: "left",
                    minHeight: "100px",
                    maxWidth: "350px",
                  }}
                />
                <Title
                  level={1}
                  style={{
                    textAlign: "center",
                    paddingLeft: "0",
                  }}
                >
                  2025 Pathways for ECFMG® Certification
                </Title>
              </Space>
            </Col>
          </Row>
        </Header>
      ) : (
        <Header
          className="header-logo"
          style={{ alignContent: "center", height: "100%" }}
        >
          <Row style={{ height: "100%", paddingTop: "10px" }}>
            <Col span={24}>
              <Space
                direction={"horizontal"}
                size={50}
                style={{ alignItems: "center", height: "100%" }}
              >
                <img
                  src={ECFMGLogo}
                  style={{
                    flex: 1,
                    height: "75%",
                    width: "100%",
                    resize: "block",
                    paddingLeft: "25px",
                    float: "left",
                    minHeight: "100px",
                    minWidth: "300px",
                    maxWidth: "350px",
                  }}
                />
                <Title
                  level={1}
                  style={{
                    textAlign: "right",
                    paddingLeft: "20px",
                  }}
                >
                  2025 Pathways for ECFMG® Certification
                </Title>
              </Space>
            </Col>
          </Row>
        </Header>
      )}
    </>
  );
};

export default UnauthHeader;
