import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Input,
  Radio,
  Space,
  message,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useMutation, useQuery } from "@apollo/client";
import { OPT_OUT_REASON, OPT_OUT_REASON_MAPPING } from "../common/constants";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  PhysicianOptOutDataVersionDocument,
  PhysicianOptOutOptOutDocument,
} from "../generated";
const { Title, Paragraph, Text } = Typography;

const PhysicianOptOut: FC<any> = (props) => {
  const navigate = useNavigate();
  const [rejectReason, setRejectReason] = useState<string>("");
  const [otherTextAreaHidden, setOtherTextAreaHidden] = useState<boolean>(true);
  const [otherText, setOtherText] = useState<string>("");
  const { data } = useQuery(PhysicianOptOutDataVersionDocument);
  const [gqlOptOut] = useMutation(PhysicianOptOutOptOutDocument);
  const [dataVersion, setDataVersion] = useState<number>(1);
  const [email, setEmail] = useState<string>("");

  const screens = useBreakpoint();
  useEffect(() => {
    if (data) {
      if (data.physicianPortal?.physician?.dataVersion) {
        setDataVersion(data?.physicianPortal?.physician?.dataVersion);
      } else {
        setDataVersion(1);
      }
      setEmail("");
    }
  }, [data]);

  useEffect(() => {
    if (rejectReason === OPT_OUT_REASON.OTHER) {
      setOtherTextAreaHidden(false);
    } else {
      setOtherTextAreaHidden(true);
    }
  }, [rejectReason]);

  const onContinueClick = async () => {
    if (email !== data?.physicianPortal?.physician?.email) {
      message.error("Email doesn't match");
      return;
    }

    await gqlOptOut({
      variables: {
        dataVersion: dataVersion,
        email: email,
        reason: rejectReason,
        reasonComments: otherText,
      },
    })
      .then(() => {
        navigate({
          pathname: "/physician/opted-out",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onGoBackClick = () => {
    navigate({
      pathname: "/physician/profile-management",
    });
  };

  const optOut = (
    <div>
      <div>
        <Title level={3}>Opt Out</Title>

        <div>
          <Paragraph>
            Please enter your e-mail address, and click Confirm to opt out of the
            ECFMG Mini-CEX evaluation process for Pathway 6 and to be removed
            from ECFMG’s Clinical Skills Evaluation and Attestation Portal. Once
            removed, you will no longer receive requests from applicants to
            complete the ECFMG Mini-CEX for Pathway 6, and you will not have
            access to any pending or completed requests.
          </Paragraph>
        </div>
      </div>

      <div>
        <div>
          <Row style={{ marginBottom: 8 }}>
            <Col flex="110px" style={{ color: "#333333" }}>
              <Text style={{ color: "#333333" }} strong>
                E-mail Address:
              </Text>
            </Col>
          </Row>
          <Row>
            <Col flex={screens.xs ? "auto" : "40%"}>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </Col>
          </Row>
        </div>
        <br></br>
        <Row>
          <Col>
            <Paragraph style={{ color: "#333333" }} strong>
              Please select a reason:{" "}
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col offset={1}>
            <Space direction="vertical" size="large">
              <Radio.Group
                onChange={(e) => setRejectReason(e.target.value)}
                value={rejectReason}
              >
                <Space direction="vertical" size="large">
                  {OPT_OUT_REASON_MAPPING.map((reason) => (
                    <div style={{ marginBottom: "-18px" }} key={reason.key}>
                      <Radio value={reason.key}>
                        <div
                          style={{
                            whiteSpace: "normal",
                            paddingLeft: "25px",
                            marginTop: "-20px",
                          }}
                        >
                          {reason.value}
                        </div>
                      </Radio>
                    </div>
                  ))}
                </Space>
              </Radio.Group>
              <TextArea
                hidden={otherTextAreaHidden}
                onChange={(e) => setOtherText(e.target.value)}
                value={otherText}
              ></TextArea>
            </Space>
          </Col>
        </Row>

        <br />

        <Row justify="end">
          <Col>
            <Space direction="horizontal">
              <Button
                size="large"
                onClick={(e) => {
                  onGoBackClick();
                }}
              >
                Go Back
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={async (e) => {
                  await onContinueClick();
                }}
              >
                Confirm
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );

  return <div className="site-layout-content">{optOut}</div>;
};

export default PhysicianOptOut;
