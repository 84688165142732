import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Row, Col, Radio, Form } from 'antd';

const ComponentPropTypes = {
  questionName: PropTypes.string.isRequired,
  score: PropTypes.number,
  setScore: PropTypes.func.isRequired,
};

export interface IProps {
  __typename?: 'MiniCEXRadioMobile';
  questionName: string;
  score?: number;
  setScore: (value: any) => void;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

export const MiniCEXRadioMobile: FC<ComponentProps> = ({ questionName, score, setScore }) => {
  const onRadioChange = (value: any) => {
    setScore(value);
  };

  return (
    <div>
      <Form.Item name={questionName} initialValue={score}>
        <Row
          style={{
            background: 'lightgray',
          }}
        >
          <Col span={24}>
            <Row>
              <Col style={{ borderRight: 'dotted', marginLeft: '10px' }} flex="40px">
                <div>
                  <Radio
                    value={1}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 1}
                  >
                    1
                  </Radio>
                </div>
                <div>
                  <Radio
                    value={2}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 2}
                  >
                    2
                  </Radio>
                </div>
                <div>
                  <Radio
                    value={3}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 3}
                  >
                    3
                  </Radio>
                </div>
              </Col>
              <Col
                span={16}
                offset={1}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                Performs at the level of a medical school student beginning clinical experiences
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ background: '#b3afaf' }}>
          <Col span={24}>
            <Row>
              <Col style={{ borderRight: 'dotted', marginLeft: '10px' }} flex="40px">
                <Row>
                  <Radio
                    value={4}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 4}
                  >
                    4
                  </Radio>
                </Row>
                <Row>
                  <Radio
                    value={5}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 5}
                  >
                    5
                  </Radio>
                </Row>
                <Row>
                  <Radio
                    value={6}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 6}
                  >
                    6
                  </Radio>
                </Row>
              </Col>
              <Col
                span={16}
                offset={1}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                Performs at the level of a medical school graduate
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ background: '#969595', width: '100%' }}>
          <Col span={24}>
            <Row>
              <Col style={{ borderRight: 'dotted', marginLeft: '10px' }} flex="40px">
                <Row>
                  <Radio
                    value={7}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 7}
                  >
                    7
                  </Radio>
                </Row>
                <Row>
                  <Radio
                    value={8}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 8}
                  >
                    8
                  </Radio>
                </Row>
                <Row>
                  <Radio
                    value={9}
                    onChange={(e) => onRadioChange(e.target.value)}
                    checked={score === 9}
                  >
                    9
                  </Radio>
                </Row>
              </Col>
              <Col
                span={16}
                offset={1}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                Performs at the level of an experienced doctor / practicing physician
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};
