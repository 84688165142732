import { FC, useState, useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { Layout, Spin, Typography } from "antd";
import PhysicianInstructions from "./physician-instructions";
import Maintenance from "../components/maintenance/maintenance";
import ImpendingMaintenance from "../components/maintenance/impendingMaintenance";
import ProfileManagement from "./physician-profile-management";
import PhysicianOptOut from "./physician-optout";
import { ACCOUNT_STATUS } from "../common/constants";
import PhysicianDashboardLayout from "./physician-dashboard/physician-dashboard-layout";
import EvaluationLayout from "./evaluation/evaluation-layout";
import MiniCEXSurvey from "./Physician-Mini-CEX-survey";
import PhysicianOptedOut from "./physician-opted-out";
import { useQuery } from "@apollo/client";
import PhysicianGateway from "./physician-gateway";
import HeaderContent from "./physician-header-content";
import { PhysicianLayoutPhysicianPortalDocument } from "../generated";
import FooterLayout from "../footer-layout";

const { Link } = Typography;

const { Content, Footer } = Layout;

const PhysicianLayout: FC<any> = (props) => {
  let { path } = useParams();
  const [isPhysicianOptedOut, setIsPhysicianOptedOut] =
    useState<boolean | undefined>(undefined);
  const {
    data: getOptedOutFlagData,
    loading: getOptedOutFlagLoading,
    error: getOptedOutFlagError,
  } = useQuery(PhysicianLayoutPhysicianPortalDocument);

  useEffect(() => {
    if (getOptedOutFlagData) {
      setIsPhysicianOptedOut(
        getOptedOutFlagData?.physicianPortal?.physician?.minicex
          ?.accountStatus === ACCOUNT_STATUS.OPTED_OUT
      );
    }
  }, [getOptedOutFlagData]);

  if (getOptedOutFlagLoading) {
    return <Spin></Spin>;
  }

  return (
    <Layout className="layout">
      <HeaderContent isPhysicianOptedOut={isPhysicianOptedOut} />
      <Content
        className="site-layout"
        style={{ marginTop: "0px", display: "flex", flexDirection: "column" }}
      >
        <div
          className="site-layout-background"
          style={{
            flex: "1 auto",
            display: "flex",
            flexFlow: "column",
            borderRadius: "0 0 0.8rem 0.8rem",
            marginTop: '30px'
          }}
        >
          <ImpendingMaintenance ShowImpendingMessageImmediately={false}>
            <Maintenance>
              <Routes>
                {!isPhysicianOptedOut ? (
                  <>
                    <Route
                      path={`/instructions`}
                      element={<PhysicianInstructions {...props} />}
                    />
                    <Route
                      path={`/profile-management`}
                      element={<ProfileManagement {...props} />}
                    />
                    <Route
                      path={`/opt-out`}
                      element={<PhysicianOptOut {...props} />}
                    />
                    <Route
                      path={`/dashboard`}
                      element={<PhysicianDashboardLayout {...props} />}
                    />

                    <Route
                      path={`/dashboard/minicex/:encounterId`}
                      element={<EvaluationLayout {...props} />}
                    />
                    <Route
                      path={`/minicex-survey`}
                      element={<MiniCEXSurvey {...props} />}
                    />
                    <Route
                      path={`/gateway`}
                      element={<PhysicianGateway {...props} />}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <Route
                      path={`/opted-out`}
                      element={<PhysicianOptedOut {...props} />}
                    />
                  </>
                )}
              </Routes>
            </Maintenance>
          </ImpendingMaintenance>
        </div>
      </Content>
      <FooterLayout />
    </Layout>
  );
};

export default PhysicianLayout;
