import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Typography } from "antd";
import ImpendingMaintenance from "../components/maintenance/impendingMaintenance";
import Maintenance from "../components/maintenance/maintenance";
import UnauthHeader from "../unauth-header";
import FooterLayout from "../footer-layout";
import { Helmet } from "react-helmet";
const { Title, Link } = Typography;
const { Content, Header } = Layout;

const PhysicianOptedOut: FC<any> = (props) => {
  const optedOut = (
    <div>
      <Title level={3}>Opted Out</Title>
      <div>
        You have opted out of using the Clinical Skills Evaluation and
        Attestation Portal and participating in the Mini-CEX evaluation process
        for Pathway 6 as a physician evaluator. If you would like to participate
        in the future, please contact ECFMG at{" "}
        <Link href="mailto:casemanager@ecfmg.org">casemanager@ecfmg.org</Link>
      </div>
    </div>
  );
  return (
    <>
      <Helmet>
        <title>Opted Out</title>
      </Helmet>
      <Layout className="layout">
        <UnauthHeader />

        <Layout>
          <Header style={{ width: "100%" }}></Header>
          <Content
            className="site-layout"
            style={{
              marginTop: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                flex: "1 auto",
                display: "flex",
                flexFlow: "column",
                borderRadius: "0 0 0.8rem 0.8rem",
              }}
            >
              <ImpendingMaintenance ShowImpendingMessageImmediately={true}>
                <Maintenance>
                  <div className="site-layout-content">{optedOut}</div>
                </Maintenance>
              </ImpendingMaintenance>
            </div>
          </Content>

          <FooterLayout />
        </Layout>
      </Layout>
    </>
  );
};

export default PhysicianOptedOut;
