import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes, { InferProps } from "prop-types";
import { ACCOUNT_STATUS, MENU_KEYS } from "../common/constants";
import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import { PhysicianGatewayPhysicianPortalDocument } from "../generated";

const PhysicianGatewayPropTypes = {
  loginType: PropTypes.string.isRequired,
};

type PhysicianGatewayProps = InferProps<typeof PhysicianGatewayPropTypes>;

const PhysicianGateway: FC<PhysicianGatewayProps> = (props) => {
  const navigate = useNavigate();
  const { data: getOptedOutFlagData } = useQuery(
    PhysicianGatewayPhysicianPortalDocument
  );

  useEffect(() => {
    if (getOptedOutFlagData) {
      let pathName = "";
      if (
        getOptedOutFlagData?.physicianPortal?.physician?.minicex
          ?.accountStatus === ACCOUNT_STATUS.OPTED_OUT
      ) {
        navigate({
          pathname: "/physician/opted-out",
        });
        return;
      }

      pathName = "/physician/instructions";

      navigate(pathName, {
        state: { menuItemSelected: MENU_KEYS.INSTRUCTIONS },
      });
    }
  }, [getOptedOutFlagData]);

  return (
    <div style={{ width: "100%" }} className="site-layout-background ">
      <div>
        <Spin></Spin> Please wait...
      </div>
    </div>
  );
};

export default PhysicianGateway;
