import { FC } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import {
  convertDate,
  convertDateFromTZTime,
  EncounterSettings,
  EvaluationCategory,
  getPhysicianName,
} from "../common/constants";
import { MiniCexResponseType } from "../generated";
// Reference fonts:
// 'Courier',
// 'Courier-Bold',
// 'Courier-Oblique',
// 'Courier-BoldOblique',
// 'Helvetica',
// 'Helvetica-Bold',
// 'Helvetica-Oblique',
// 'Helvetica-BoldOblique',
// 'Times-Roman',
// 'Times-Bold',
// 'Times-Italic',
// 'Times-BoldItalic',
// 'Symbol',
// 'ZapfDingbats',

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 15,
    margin: 2,
  },

  sectionTitle: { fontFamily: "Helvetica-Bold", fontSize: 12 },

  sectionSubTitle: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,

    marginBottom: 5,
  },

  sectionSubTitleMiniCEX: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    marginBottom: 5,
    marginTop: 5,
  },

  label: {
    fontFamily: "Times-Roman",
    fontSize: 8,
  },

  content: {
    fontSize: 12,
  },

  horizontalLine: {
    borderTop: 1,
    borderColor: "lightgrey",
    height: 1,
    marginBottom: 5,
    marginTop: 2,
  },

  table: {
    display: "flex",
    width: "100%",
    marginBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },

  tableRow: {
    margin: "auto",
    flexDirection: "row",
    paddingLeft: 10,
    marginBottom: 10,
  },

  tableCell: {
    fontSize: 10,
  },
});

const Section: FC<any> = (props) => {
  return (
    <>
      <Text style={styles.sectionTitle}>{props.title}</Text>
      <View style={styles.horizontalLine}></View>
    </>
  );
};

const InfoGroup: FC<any> = (props) => {
  return (
    <>
      <Text style={styles.label}>{props.label}</Text>
      <Text style={styles.content}>{props.content}</Text>
    </>
  );
};

export interface IProps {
  minicexSummary: MiniCexResponseType;
}

export type MiniCEXPDFProps = IProps;

const MiniCEXPDF: FC<MiniCEXPDFProps> = ({ minicexSummary }) => {

  const GetScoreCategory = (score: number) => {
    if (score <= 3) {
      return "Medical school student beginning clinical training";
    }
    if (score <= 6) {
      return "Medical school graduate";
    }
    return "Experienced doctor/ practicing physician";
  };
  
  const getAdditionalFeedbackArea = () => {
    if (minicexSummary?.form?.evaluation?.additionalInfoText) {
      return (
        <>
          <Text style={styles.sectionSubTitleMiniCEX}>
            Additional Feedback:
          </Text>
          <View style={styles.tableCell}>
            <Text style={[styles.label, { paddingLeft: 5 }]}>
              Is there additional information you would like to provide to ECFMG
              to assist in the evaluation of this applicant?
            </Text>
          </View>
          <View style={styles.tableRow}>
            <View style={{ width: "100%" }}>
              <View style={styles.tableCell}>
                <Text style={styles.label}>
                  {minicexSummary.form.evaluation.additionalInfoText}
                </Text>
              </View>
            </View>
          </View>
        </>
      );
    }
    return null;
  };
  return (
    <>
      {minicexSummary ? (
        <Document>
          <Page size="A4" style={styles.body}>
            {/* Applicant details */}
            <Section title={"Applicant Details"} />
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ width: "60%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Applicant:"}
                      content={`${minicexSummary?.summary?.applicantLastName}, ${minicexSummary?.summary?.applicantRestOfName}`}
                    />
                  </View>
                </View>
                <View style={{ width: "40%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Encounter ID:"}
                      content={minicexSummary?.summary?.encounterId}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={{ width: "60%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"USMLEID:"}
                      content={minicexSummary?.summary?.usmleId}
                    />
                  </View>
                </View>
                <View style={{ width: "40%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Date Requested:"}
                      content={convertDate(minicexSummary?.summary?.createdAt)}
                    />
                  </View>
                </View>
              </View>
            </View>
            {/* Physician Evaluator Details */}
            <Section title={"Physician Evaluator Details"} />
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ width: "100%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Name:"}
                      content={getPhysicianName(
                        minicexSummary?.summary
                          ?.physicianLastNameProvidedByPhysician,
                        minicexSummary?.summary
                          ?.physicianRestOfNameProvidedByPhysician
                      )}
                    />
                  </View>
                </View>
              </View>

              {/* Licenses are not on PDF => Commented out just in case need it in future */}
              {/* <Text style={styles.sectionSubTitle}>Licensure Information:</Text>
          {minicexSummary.summary.physicianLicensureInfo.map((li) => {
            return (
              <View style={styles.tableRow}>
                <View style={{ width: '50%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup label={'Authority:'} content={li.orgName} />
                  </View>
                </View>
                <View style={{ width: '50%' }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={'License / Registration Number:'}
                      content={li.licenseNumber}
                    />
                  </View>
                </View>
              </View>
            );
          })} */}
            </View>
            {/* Encounter */}
            <Section title={"Encounter"} />
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ width: "60%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Date of Encounter:"}
                      content={convertDateFromTZTime(
                        minicexSummary?.form?.encounter?.encounterDate as string
                      )}
                    />
                  </View>
                </View>
                <View style={{ width: "40%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Clinical Setting:"}
                      content={
                        EncounterSettings.find(
                          (o) =>
                            o.key ===
                            minicexSummary?.form?.encounter?.encounterSetting
                        )?.value
                      }
                    />
                  </View>
                </View>
              </View>

              <Text style={styles.sectionSubTitle}>
                Facility / Hospital at which encounter took place:
              </Text>
              <View style={styles.tableRow}>
                <View style={{ width: "60%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Name:"}
                      content={minicexSummary?.form?.encounter?.facility?.name}
                    />
                  </View>
                </View>
                <View style={{ width: "40%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"State/Province:"}
                      content={minicexSummary?.form?.encounter?.facility?.state}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ width: "60%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Country:"}
                      content={
                        minicexSummary?.form?.encounter?.facility?.country?.name
                      }
                    />
                  </View>
                </View>
                <View style={{ width: "40%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"City:"}
                      content={minicexSummary?.form?.encounter?.facility?.city}
                    />
                  </View>
                </View>
              </View>

              <Text style={styles.sectionSubTitle}>Patient:</Text>
              <View style={styles.tableRow}>
                <View style={{ width: "60%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Age:"}
                      content={minicexSummary?.form?.encounter?.patient?.ageYear}
                    />
                  </View>
                </View>
                {/* <View style={{ width: "50%" }}>
                  <View style={styles.tableCell}>
                    <Text style={{ fontSize: 11 }}> </Text>
                    <InfoGroup
                      label={"Months:"}
                      content={
                        minicexSummary?.form?.encounter?.patient?.ageMonth
                          ? formatMonths(
                              minicexSummary?.form?.encounter?.patient?.ageMonth
                            )
                          : "N/A"
                      }
                    />
                  </View>
                </View> */}
                <View style={{ width: "40%" }}>
                  <View style={styles.tableCell}>
                    <InfoGroup
                      label={"Gender:"}
                      content={minicexSummary?.form?.encounter?.patient?.gender}
                    />
                  </View>
                </View>
              </View>
            </View>

            {/* Mini-CEX Responses */}
            <Section title={"Mini-CEX Responses"} />
            <View style={styles.table}>
              {minicexSummary?.form?.evaluation?.categories?.map((cate) => {
                return (
                  <View key={cate?.name}>
                    <Text style={styles.sectionSubTitleMiniCEX}>
                      {EvaluationCategory[cate?.name!]}:
                    </Text>
                    <View style={styles.tableRow}>
                      <View style={{ width: "10%" }}>
                        <View style={styles.tableCell}>
                          <InfoGroup label={"Score:"} content={cate?.score} />
                        </View>
                      </View>
                      <View style={{ width: "90%" }}>
                        <View style={styles.tableCell}>
                          <InfoGroup
                            label={"Level:"}
                            content={GetScoreCategory(cate?.score as number)}
                          />
                        </View>
                      </View>
                    </View>

                    {cate?.reasonText ? (
                      <>
                        <View style={styles.tableCell}>
                          <Text style={[styles.label, { paddingLeft: 5 }]}>
                            What did you observe during the clinical encounter
                            that led to this score? Do not include any
                            personally identifiable information about yourself,
                            the applicant, or the patient.
                          </Text>
                        </View>
                        <View style={styles.tableRow}>
                          <View style={{ width: "100%" }}>
                            <View style={styles.tableCell}>
                              <Text style={styles.label}>
                                {cate.reasonText}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </>
                    ) : null}
                  </View>
                );
              })}

              {getAdditionalFeedbackArea()}
            </View>
            <Section title="" />
            {minicexSummary?.summary?.acceptedAt ? (
              <View style={{ marginTop: 10, marginBottom: 5, marginLeft: 20 }}>
                <InfoGroup
                  label={"Date Accepted:"}
                  content={convertDate(minicexSummary.summary.acceptedAt)}
                />
              </View>
            ) : (
              <></>
            )}
            {minicexSummary?.summary?.completedAt ? (
              <View style={{ marginTop: 10, marginLeft: 20 }}>
                <InfoGroup
                  label={"Date Completed:"}
                  content={convertDate(minicexSummary.summary.completedAt)}
                />
              </View>
            ) : (
              <></>
            )}
          </Page>
        </Document>
      ) : null}
    </>
  );
};

export default MiniCEXPDF;
